import React, { useEffect, useState } from 'react';
import ReactPDF, {
  usePDF
} from '@react-pdf/renderer';
import InvoiceTemplate from './InvoiceTemplate';
import { Lesson } from 'src/models/lesson';
import { Student } from 'src/models/student';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import TimerSharpIcon from '@mui/icons-material/TimerSharp';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import SendIcon from '@mui/icons-material/Send';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import { formatDateDayMonth } from 'src/tools/dateTools';
import { useDispatch } from 'react-redux';
import { LessonSubmitTypes, SetLessonsInvoiced, SetLessonsPaid } from 'src/actions/LessonActions';
import { Navigate } from 'react-router';

type invoiceProps = {
  lessons: Lesson[];
  student: Student;
};

type pdfGenerated = "no" | "yes" | "sent"

export const DownloadInvoiceButton: React.FC<invoiceProps> = ({
  lessons,
  student
}) => {
  const [generated, setGenerated] = useState<pdfGenerated>("no");
  const [lessonsSubmitData, setLessonsSubmitData] = useState<LessonSubmitTypes>();

  const buttonClick = () => {
    setGenerated("yes");
  };

  const dispatch = useDispatch();

  const sendInvoiceButtonClick = () => {
    const arrayOfLessonIds = lessons.map((lesson) => lesson._id);
    dispatch<any>(SetLessonsInvoiced(arrayOfLessonIds,new Date(), student._id, setLessonsSubmitData)); 

    const email = student.parents?.length>0&&student.parents[0].email?student.parents[0].email:student.email;
    const name = student.parents?.length>0&&student.parents[0].first_name?student.parents[0].first_name:student.first_name;
    const subject = ("Aptetut Tutoring Invoice").replaceAll(" ","%20");

    const url = 'https://mail.google.com/mail/u/0/?tf=cm&fs=1&to=' + email + '&hl=en' + '&subject=' + subject + '&su=Tutoring%20Invoice' + 
    '&body=Hello%20' + name + ',%0A%0APlease%20find%20attached%20your%20latest%20tutoring%20invoice.%0AWhen%20making%20payments%20ensure%20that%20you%20use%20the%20payment%20reference:%0A%0A' + student.user_id + '%20-%20' + student.first_name + '%0A%0AKind%20Regards,%0A%0ABen.';
    window.open(url, '_blank');
  }

  useEffect(() => {
    if (lessonsSubmitData?.type === 'Success') {
      setGenerated("sent");
    }
  }, [lessonsSubmitData]);

  const theme = useTheme();

  return (
    <>
      {(generated == "no") ? (
        <>
          <Tooltip title={"Download Invoice"} arrow>
            <IconButton
              sx={{
                '&:hover': {
                  background: theme.colors.primary.lighter
                },
                color: '#f3ac3c',
              }}
              color="inherit"
              size="small"
              onClick={buttonClick}
            >
              <DescriptionSharpIcon fontSize="small" />
            </IconButton>

          </Tooltip>
        </>
      ) : (
        <>
          <TestInvoice lessons={lessons.sort((a, b) => a.date < b.date ? -1 : 1)} student={student} />
          <Tooltip title={"Send Invoice"} arrow>
            <IconButton
              sx={{
                '&:hover': {
                  background: theme.colors.primary.lighter
                },
                color: generated == 'yes'?'#f3ac3c':'grey',
              }}
              color="inherit"
              size="small"
              onClick={sendInvoiceButtonClick}
            >
              <MarkunreadMailboxIcon fontSize="small" />
            </IconButton>

          </Tooltip>
        </>

      )}
    </>
  );
};

const TestInvoice: React.FC<invoiceProps> = ({ lessons, student }) => {
  let invoiceTest: ReactPDF.UsePDFInstance;

  //let instance;
  const [instance, updateInstance] = usePDF({
    document: InvoiceTemplate({ lessons, student })
    //document: NewInvoiceTemplate()
  });

  const buttonClick = () => {
    if (instance) {
      downloadInvoice();
    }
  };

  const downloadInvoice = () => {
    const link = document.createElement('a');
    link.download = student.user_id + " " + student.first_name + " " + student.last_name + " Invoice " + formatDateDayMonth(new Date()) + ".pdf";
    link.href = instance.url;
    link.click();
  };

  const theme = useTheme();

  return (
    <>
      {instance.error ? (
        'Error'
      ) : (
        instance.loading ?
          <Tooltip title={"Loading"} arrow>
            <IconButton
              sx={{
                '&:hover': {
                  background: theme.colors.primary.lighter
                },
                color: '#f3ac3c',
              }}
              color="inherit"
              size="small"
            >
              <TimerSharpIcon fontSize="small" />
            </IconButton>

          </Tooltip>
          :
          <Tooltip title={"Download Invoice"} arrow>
            <IconButton
              sx={{
                '&:hover': {
                  background: theme.colors.primary.lighter
                },
                color: '#f3ac3c',
              }}
              color="inherit"
              size="small"
              onClick={buttonClick}
            >
              <DownloadSharpIcon fontSize="small" />
            </IconButton>

          </Tooltip>
      )}
    </>
  );
};
