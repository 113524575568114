import {
  Box,
  Button,
  Typography,
  Card,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  styled,
  tableCellClasses,
  CardContent,
  IconButton,
  useTheme,
  Link
} from '@mui/material';

import { ArrowForwardTwoTone } from '@mui/icons-material';
import { LessonsGetTypes } from '@/src/actions/LessonActions';
import { FC, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { formatDateDayMonthYear, formatDateDayMonth } from 'src/tools/dateTools';
import { Lesson } from 'src/models/lesson';
import { EditLessonDialogue } from '../Receivables/EditLessonDialogue';
import { capitalizeWords } from 'src/tools/stringTools';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
) {
  return { name, calories, fat, carbs, protein };
}




const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {

  },
  '&:nth-of-type(even)': {
    backgroundColor: '#3b4e7a',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export type StudentLessonProps = {
  lessons: LessonsGetTypes;
};

const TutorLessonsTable: FC<StudentLessonProps> = ({ lessons: lessons }) => {

  const [openEditLessonDialogue, setOpenEditLessonDialogue] = useState(false);
  const [editLessonLesson, setEditLessonLesson] = useState<Lesson>();

  const selectLesson = (lesson: Lesson) => {
      setOpenEditLessonDialogue(true);
      setEditLessonLesson(lesson);
  }

  const filteredLessons = lessons?.type == "Success"?lessons.payload.filter(item => item.status == "completed"):[];

  const theme = useTheme();

  return (
    <>
    <Card>
      <CardHeader title="Recent completed lessons" />
      <Divider />
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12} sm={12}>
            <Card>
              <Box sx={{ minHeight: { xs: 0, md: 242 } }}>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Date</StyledTableCell>
                        <StyledTableCell align="left">Student</StyledTableCell>
                        <StyledTableCell align="right">Duration</StyledTableCell>
                        <StyledTableCell align="right"></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredLessons.sort((a, b) => a.date < b.date ? 1 : -1).slice(0, 5).map((lesson, index) => (
                        <StyledTableRow key={index} >
                          <StyledTableCell component="th" scope="row">
                            {formatDateDayMonth(lesson.date)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Link color='white' href={'/management/student?user_id=' + lesson.student._id}>
                            {lesson.student.user_id} - {lesson.student.first_name} {lesson.student.last_name} </Link></StyledTableCell>

                          <StyledTableCell align="right">{lesson.duration}</StyledTableCell>
                          <StyledTableCell align="right">
                            <IconButton
                              sx={{
                                '&:hover': {
                                  background: theme.colors.primary.lighter
                                },
                                color: 'grey',
                              }}
                              color="inherit"
                              size="small"
                              onClick={() => selectLesson(lesson)}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>

                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </Card>

<EditLessonDialogue isOpen={openEditLessonDialogue} lesson={editLessonLesson} onClose={setOpenEditLessonDialogue} isAdmin={true} />
</>
  );
}

export default TutorLessonsTable;
