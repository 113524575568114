import axios from 'axios';

import {
  Dispatcher,
  FAILED,
  SUCCESS,
  LOADING,
  Loading,
  Fail,
  Success
} from './LoadingActions';
import { Tutor } from 'src/models/tutor';
import { Student } from 'src/models/student';
import { LoggedInUser } from 'src/models/loggedInUser';
import { API_URL } from '../settings';

// const API_URL = 'api/users/';
//const API_URL = process.env.API_SERVER;
//const API_URL = "http://localhost:5001/";

export type AuthDispatchTypes = Loading | Fail | Success;

export type AuthType = {
  email: string;
  password: string;
};

//Get the logged in user account id
export const GetLocalUser = (): LoggedInUser => {
  let userDetails = localStorage.getItem('user');

  if (!userDetails || userDetails === '') {
    return null;
  }

  let account = JSON.parse(userDetails);

  return account;
};

//Get the logged in user account id
export const GetLocalUserName = (): String => {
  let userDetails = localStorage.getItem('user');

  if (!userDetails || userDetails === '') {
    return null;
  }

  let account = JSON.parse(userDetails);

  return account.first_name + " " + account.last_name;
};

//Get the logged in user account id
export function GetAccountId(): string {
  let userDetails = localStorage.getItem('user');

  if (!userDetails || userDetails === '') {
    return '';
  }

  let account = JSON.parse(userDetails);

  return account._id;
}

//Get the logged in user account type
export function GetAccountType(): string {
  let userDetails = localStorage.getItem('user');

  if (!userDetails || userDetails === '') {
    return '';
  }

  let account = JSON.parse(userDetails);

  return account.account_type;
}

export function HasAccountPermissions(allowedAccountTypes: string[]): boolean {
  let userDetails = localStorage.getItem('user');

  if (userDetails == '') {
    return false;
  }

  let account = JSON.parse(userDetails);

  return hasOverlap(allowedAccountTypes,account.permissions);
}

function hasOverlap(array1, array2) {
  for (const element of array1) {
    if (array2.includes(element)) {
      return true;
    }
  }
  return false;
}

//Log in
export const GetAuths =
  (data: AuthType, setData: Dispatcher<AuthDispatchTypes>) => async () => {
    try {
      setData({
        type: LOADING
      });

      const res = await axios.post(API_URL + 'api/users/login', data);

      localStorage.setItem('user', JSON.stringify(res.data));

      setData({
        type: SUCCESS
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: error.response.data
      });
    }
  };

