import { FC, ChangeEvent, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  Button,
  Link,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@mui/material';

import Label from 'src/components/Label';
import { StudentAccountStatus } from 'src/models/student';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ArrowUpwardIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDropDown';
import RuleRoundedIcon from '@mui/icons-material/RuleRounded';
import {
  daysSinceDate,
  formatDateDayMonthYear,
  formatDateDayMonth,
  monthsSinceDate
} from 'src/tools/dateTools';
import AddLeadButton from './AddLeadButton';
import {
  Lead,
  interestedInValues,
  lastContactedThresholds
} from 'src/models/lead';
import { StyledTableRow } from 'src/theme/stylings';
import AddStudentButton from '../AllStudents/AddStudentButton';
import { useDispatch } from 'react-redux';
import {
  LeadSubmitTypes,
  UnsubscribeFromLeedsEmail as UnsubscribeFromLeadsEmail
} from 'src/actions/LeadActions';
import { EmailTemplatesGetTypes } from 'src/actions/EmailActions';
import HtmlPreviewDialog from '../EmailEditor/EmailPreview';
import { EmailTemplate } from '@/src/models/emailTemplate';

interface TableProps {
  className?: string;
  leads: Lead[];
  emailTemplates: EmailTemplatesGetTypes;
}

interface Filters {
  status?: StudentAccountStatus;
}

interface SelectedProps {
  interestedIn: string[];
  lastContacted: string;
}

const openInNewTab = (url) => {
  window.open(url, '_blank', 'noreferrer');
};

const applyFilters = (
  leads: Lead[],
  filters: Filters,
  search: string
): Lead[] => {
  return leads.filter((lead) => {
    let matches = true;

    if (search) {
      return (
        lead.first_name.toLowerCase().includes(search.toLowerCase()) ||
        lead.last_name.toLowerCase().includes(search.toLowerCase()) ||
        lead.email.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (filters.status && lead.status !== filters.status) {
      matches = false;
    }

    return matches;
  });
};

const applyPagination = (lead: Lead[], page: number, limit: number): Lead[] => {
  return lead.slice(page * limit, page * limit + limit);
};

const AllLeadsTable: FC<TableProps> = ({
  leads: leads,
  emailTemplates: emailTemplates
}) => {
  const [saveLeadData, setSaveLeadData] = useState<LeadSubmitTypes>();

  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<String>();
  const [selectedLastContacted, setSelectedLastContacted] =
    useState<string>('-1');
  const [displayedLeads, setDisplayedLeads] = useState<Lead[]>(leads);
  const [massInterest, setMassInterest] = useState<string[]>([]);
  const [selectedProps, setSelectedProps] = useState<SelectedProps>({
    interestedIn: [],
    lastContacted: '0'
  });
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(15);
  const [userSearch, setUserSearch] = useState<string>();
  const [orderBy, setOrderBy] = useState<string>('date');
  const [order, setOrder] = useState<string>('desc');
  const [selected, setSelected] = useState<Lead[]>([]);
  const [selectedLead, setSelectedLead] = useState<Lead>();
  const [openLeadDialog, setOpenLeadDialog] = useState(false);
  const [openSelectedLeadDialog, setOpenSelectedLeadDialog] = useState(false);
  const [previewHtmlOpen, setPreviewHtmlOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters>({
    status: 'active'
  });

  const theme = useTheme();
  const dispatch = useDispatch();

  console.log(emailTemplates);

  const getDaysSinceEmailed = (date) => {
    if (!date) {
      return <Typography color="grey"> - </Typography>;
    }

    const daysPast: number = daysSinceDate(date);

    if (daysPast < 90) {
      return <Typography color="error"> {daysPast} Days</Typography>;
    }

    return (
      <Typography color={daysPast < 90 ? 'error' : 'primary'}>
        {daysPast} Days{' '}
      </Typography>
    );
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const selectLead = (lead: Lead) => {
    if (selected.includes(lead)) {
      const newSelected = selected.filter((item) => item !== lead);
      setSelected(newSelected);
    } else {
      setSelected([...selected, lead]);
    }
  };

  const selectInterest = (id: string) => {
    if (massInterest.includes(id)) {
      const newSelected = massInterest.filter((item) => item !== id);
      setMassInterest(newSelected);
    } else {
      setMassInterest([...massInterest, id]);
    }
  };

  const setOrderBuy = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filteredLeads = applyFilters(
    displayedLeads.filter((item) => item.status == 'active'),
    filters,
    userSearch
  ).sort((a, b) => {
    if (orderBy == 'date') {
      return a.createdAt < b.createdAt
        ? order == 'asc'
          ? -1
          : 1
        : order == 'asc'
        ? 1
        : -1;
    } else if (orderBy == 'name') {
      return a.first_name > b.first_name
        ? order == 'asc'
          ? -1
          : 1
        : order == 'asc'
        ? 1
        : -1;
    } else if (orderBy == 'origin') {
      return a.origin > b.origin
        ? order == 'asc'
          ? -1
          : 1
        : order == 'asc'
        ? 1
        : -1;
    } else if (orderBy == 'interestedIn') {
      return a.interested_in > b.interested_in
        ? order == 'asc'
          ? -1
          : 1
        : order == 'asc'
        ? 1
        : -1;
    } else if (orderBy == 'selected') {
      return selected.includes(a) > selected.includes(b)
        ? order == 'asc'
          ? -1
          : 1
        : order == 'asc'
        ? 1
        : -1;
    }
  });

  const paginatedLeads = applyPagination(filteredLeads, page, limit);

  const handleIDSearch = (event) => {
    if (event.target.value.length > 0) {
      setPage(0);
    }

    setUserSearch(event.target.value);
  };

  const handleCloseLeadDialog = () => {
    setOpenLeadDialog(false);
    setSelectedLead(null);
  };

  const handleSetInactiveButtonPress = () => {
    dispatch<any>(
      UnsubscribeFromLeadsEmail(selectedLead.email, setSaveLeadData)
    );

    setOpenLeadDialog(false);
  };

  useEffect(() => {
    if (saveLeadData?.type == 'Success') {
      setOpenLeadDialog(false);
      const filteredLeads = displayedLeads.filter(
        (lead) => lead.email !== selectedLead.email
      );
      setSelectedLead(null);
      setDisplayedLeads(filteredLeads);
    }
  }, [saveLeadData]);

  const handleOpenLeadDialog = (lead: Lead) => {
    setSelectedLead(lead);
    setOpenLeadDialog(true);
  };

  const handleOpenSelectLeadDialog = () => {
    setMassInterest([]);
    setSelectedLastContacted('never');
    setOpenSelectedLeadDialog(true);
  };

  const handleSelectLeadDialogClose = () => {
    setOpenSelectedLeadDialog(false);
  };

  const handleSelectLeadDialogSelect = () => {
    setSelectedProps({
      interestedIn: massInterest,
      lastContacted: selectedLastContacted
    });
    setOpenSelectedLeadDialog(false);
    setOrder('asc');
  };

  const onClickPreviewTemplate = () => {
    setPreviewHtmlOpen(true);
  };

  useEffect(() => {
    if (selectedProps) {
      const leadsWithSelectedInterest = displayedLeads
        .filter((lead) =>
          selectedProps?.interestedIn.includes(lead.interested_in)
        )
        .filter((lead) => {
          if (selectedLastContacted == 'all') {
            return true;
          }

          if (lead.lastContacted == null) {
            return true;
          }
          const monthsPast = monthsSinceDate(lead.lastContacted);
          const monthsPriorToToday =
            monthsPast < 0 ? monthsPast * -1 : monthsPast;
          return monthsPriorToToday <= parseInt(selectedLastContacted);
        });
      setSelected(leadsWithSelectedInterest);
    }
  }, [selectedProps]);

  const handleLastContactedChange = (event) => {
    setSelectedLastContacted(event.target.value);
  };

  // const handleEmailTemplateChange = (event) => {
  //   console.log('test');
  //   // setSelectedEmailTemplate(event.target.value);
  // };

  return (
    <Card>
      <HtmlPreviewDialog
        emailTemplate={emailTemplates[3]}
        isOpen={true}
        setData={setPreviewHtmlOpen}
        leads={selected}
      />

      <CardHeader
        action={
          <Box sx={{ display: 'flex' }}>
            <Box marginRight={2}>
              <AddLeadButton />
            </Box>
            <Box width={150} marginRight={2}>
              <TextField
                id="outlined-basic"
                label="Search"
                variant="outlined"
                onChange={handleIDSearch}
              />
            </Box>
          </Box>
        }
        title="All Leads"
      />

      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color={orderBy == 'date' ? 'primary' : 'white'}
                  gutterBottom
                  noWrap
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOrderBuy('date')}
                >
                  Added Date{' '}
                  {orderBy == 'date' &&
                    (order == 'asc' ? (
                      <ArrowUpwardIcon />
                    ) : (
                      <ArrowDownwardIcon />
                    ))}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color={orderBy == 'name' ? 'primary' : 'white'}
                  gutterBottom
                  noWrap
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOrderBuy('name')}
                >
                  Lead Name
                  {orderBy == 'name' &&
                    (order == 'asc' ? (
                      <ArrowUpwardIcon />
                    ) : (
                      <ArrowDownwardIcon />
                    ))}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  EMAIL
                </Typography>
              </TableCell>
              {/* <TableCell>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  Phone
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color= {orderBy == 'origin' ? 'primary' : 'white' }
                  gutterBottom
                  noWrap
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOrderBuy('origin')}
                >
                  ORIGIN
                </Typography>
              </TableCell> */}
              <TableCell>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color={orderBy == 'interestedIn' ? 'primary' : 'white'}
                  gutterBottom
                  noWrap
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOrderBuy('interestedIn')}
                >
                  Interested In{' '}
                  {orderBy == 'interestedIn' &&
                    (order == 'asc' ? (
                      <ArrowUpwardIcon />
                    ) : (
                      <ArrowDownwardIcon />
                    ))}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  LAST CONTACTED
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color={orderBy == 'selected' ? 'primary' : 'white'}
                  gutterBottom
                  noWrap
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOrderBuy('selected')}
                >
                  Select{' '}
                  {orderBy == 'selected' &&
                    (order == 'asc' ? (
                      <ArrowUpwardIcon />
                    ) : (
                      <ArrowDownwardIcon />
                    ))}
                  <IconButton
                    sx={{
                      '&:hover': {
                        background: theme.colors.primary.lighter
                      },
                      color: 'white',
                      marginRight: '8px'
                    }}
                    color="inherit"
                    size="small"
                    onClick={handleOpenSelectLeadDialog}
                  >
                    <RuleRoundedIcon fontSize="small" color="primary" />
                  </IconButton>
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedLeads.map((lead, index) => {
              return (
                <StyledTableRow key={index}>
                  <TableCell></TableCell>
                  <TableCell>{formatDateDayMonth(lead.createdAt)}</TableCell>
                  <TableCell>
                    {lead.first_name + ' ' + lead.last_name}
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      <Link
                        style={{
                          textDecoration: 'none',
                          cursor: 'pointer',
                          alignItems: 'center',
                          display: 'flex'
                        }}
                        onClick={() =>
                          openInNewTab(
                            'https://mail.google.com/mail/u/0/?tf=cm&fs=1&to=' +
                              lead.email +
                              '&hl=en'
                          )
                        }
                      >
                        {' '}
                        {lead.email}
                      </Link>
                    </Typography>
                  </TableCell>
                  {/* <TableCell>{lead.phone}</TableCell>
                  <TableCell>{lead.origin}</TableCell> */}
                  <TableCell>{lead.interested_in}</TableCell>
                  <TableCell align="center">
                    {getDaysSinceEmailed(lead.lastContacted)}
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={selected.includes(lead)}
                      onClick={() => selectLead(lead)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell style={{ paddingRight: '16px' }}>
                    <IconButton
                      sx={{
                        '&:hover': {
                          background: theme.colors.primary.lighter
                        },
                        color: 'white',
                        marginRight: '8px'
                      }}
                      color="inherit"
                      size="small"
                      onClick={() => handleOpenLeadDialog(lead)}
                    >
                      <PersonSearchIcon
                        fontSize="small"
                        sx={{ color: 'grey.700' }}
                      />
                    </IconButton>
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={2}
      >
        {selected?.length > 0 ? (
          <Box>
            <Typography fontSize={20}>
              Selected:&nbsp;
              {selected.length}
              <FormControl sx={{ ml: 2, minWidth: '120px' }}>
                <InputLabel id="email-template-label">
                  Email Template
                </InputLabel>
                <Select
                  labelId="email-template-label"
                  id="email-template-select"
                  value={selectedEmailTemplate}
                  label="Email Template"

                >
                  {emailTemplates?.type== 'Success' && emailTemplates.payload.map((template) => (
                    <MenuItem key={template._id} value={template._id}>
                      {template.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                sx={{ ml: 2 }}
                onClick={onClickPreviewTemplate}
              >
                Send Email
              </Button>
            </Typography>
          </Box>
        ) : (
          <Box></Box>
        )}
        {leads.length >= 5 && (
          <TablePagination
            component="div"
            count={filteredLeads.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 15, 20, 30]}
          />
        )}
      </Box>

      {/* Add Lead Dialogue */}
      <Dialog open={openLeadDialog} onClose={handleCloseLeadDialog}>
        <DialogTitle>
          {selectedLead?.first_name} {selectedLead?.last_name}
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Added</TableCell>
                <TableCell>
                  {formatDateDayMonthYear(selectedLead?.createdAt)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Origin</TableCell>
                <TableCell>{selectedLead?.origin}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Interested In</TableCell>
                <TableCell>{selectedLead?.interested_in}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>
                  <Link
                    href={'mailto:' + selectedLead?.email}
                    style={{ textDecoration: 'none' }}
                  >
                    {' '}
                    {selectedLead?.email}
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Phone</TableCell>
                <TableCell>{selectedLead?.phone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>TimeZone</TableCell>
                <TableCell>{selectedLead?.timezone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>{selectedLead?.status}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Last Contacted</TableCell>
                <TableCell>
                  {selectedLead?.lastContacted
                    ? formatDateDayMonthYear(selectedLead.lastContacted)
                    : 'No contact yet'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Notes</TableCell>
                {selectedLead?.notes.map((note, index) => {
                  return (
                    <TableCell key={index}>
                      {formatDateDayMonthYear(new Date(note.date))} -{note.note}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSetInactiveButtonPress}>Set Inactive</Button>
          <AddStudentButton buttonName="Parent" parentLead={selectedLead} />
          <AddStudentButton buttonName="Student" lead={selectedLead} />
          <Button
            variant="contained"
            color="error"
            onClick={handleCloseLeadDialog}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Select Leads Dialogue */}
      <Dialog
        open={openSelectedLeadDialog}
        onClose={handleSelectLeadDialogClose}
      >
        <DialogTitle>Select Leads</DialogTitle>
        <DialogContent>
          {interestedInValues.map((interestedIn, index) => {
            return (
              <Grid container key={index} alignItems="center">
                <Grid item xs={9}>
                  <Typography>{interestedIn}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Checkbox
                    checked={massInterest.includes(interestedIn)}
                    onClick={() => selectInterest(interestedIn)}
                    color="primary"
                  />
                </Grid>
              </Grid>
            );
          })}
          <Box width={150} marginRight={2} marginTop={2}>
            <FormControl variant="outlined" fullWidth={true}>
              <InputLabel id="email-template-select-label">
                Last Contacted
              </InputLabel>
              <Select
                sx={{ width: '100%' }}
                labelId="email-template-select-label"
                id="email-template-select"
                value={selectedLastContacted}
                label="Email template"
                onChange={handleLastContactedChange}
                renderValue={(value) => {
                  const selectedTemplate = lastContactedThresholds.find(
                    (template) => template.key === value
                  );
                  return selectedTemplate?.value || 'Last Contacted';
                }}
              >
                {lastContactedThresholds.map((template) => (
                  <MenuItem key={template.key} value={template.key}>
                    {template.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSelectLeadDialogClose}>Close</Button>
          <Button variant="contained" onClick={handleSelectLeadDialogSelect}>
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default AllLeadsTable;
