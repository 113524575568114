//Landing
export const LandingPrivacyPolicy = 'Privacy Policy';
export const LandingCredits = 'Credits';
export const LandingSlogan =
  'Personalised programming, STEM and video game development tutoring';

export const LandingMenuHome = 'Home';
export const LandingMenuAbout = 'About';
export const LandingMenuFAQ = 'FAQ';
export const LandingMenuContact = 'Contact Us';

export const LandingMenuAccount = 'Account';
export const LandingMenuDashboard = 'Dashboard';
export const LandingMenuPayments = 'Payments';
export const LandingMenuLogout = 'Log out';
export const LandingMenuLogin = 'Log in';

//Home

export const HomeHeading = 'Aptetut Tutoring';
export const HomeSubHeading =
  "Welcome to Australia's best choice for personalised programming, STEM, and video game development tutoring.";
export const HomeCopy =
  "Discover a diverse selection of courses and lessons taught by locally-based, industry professionals. Whether you're a novice seeking to grasp the fundamentals or are looking to elevate your abilities, our offerings cater to all skill levels. Unleash your potential with us and embark on an exhilarating journey.";

export const HomeContactButton = 'Get in touch';
export const HomeMoreInfoButton = 'More Info';
export const EnquireButton = 'Enquire';

export const HomeSoloSummary =
  'Learn the skills and techniques to create, design, and develop your own games independently, from beginning to end';

//Forms
export const FormErrorEmailRequired = 'Email Address is required';
export const FormErrorEmail = 'Email Address is not Valid';
export const FormErrorPasswordRequired = 'Password is required';
export const FormErrorPassword =
  'Password needs to be at least 5 characters long';
export const FormErrorTextField = 'Entry is required';
export const FormErrorTextFieldLength =
  'Entry needs to be longer than 2 characters long';
export const FormErrorPhone = 'Phone number is required';
export const FormErrorPhoneLength =
  'Phone needs to be longer than 7 characters long';
export const FormErrorName = 'Name Number is not Valid';
export const FormErrorAge = 'Age Needs to be between 5 and 99';

//Log in Page
export const LogInPageSubheading = 'Welcome Back';
export const LogInFormEmail = 'Email Address';
export const LogInFormPassword = 'Password';
export const LogInFormSend = 'Log in';

//Unsubscribe page
export const UnsubscribeTitle = 'Aptetut Unsubscribe';
export const Unsubscribing = 'Unsubscribing!';
export const Unsubscribed = 'Unsubscribed!';
export const UnsubscribeBlurb= 'You have been successfully unsubscribed from our emails';
export const UnsubscribeButtonText = 'Return to Home';

//Contact page
export const ContactPageTitle = 'Contact Us';
export const ContactPageSummary =
  'If you are interested in any of the courses that we offer, want to learn a specific thing, or you would like to just chat about what we can do for you, get in touch below.';

export const ContactPageForChild = 'I am enquiring about lessons for my child';
export const ContactPageFormName = 'Your Name';
export const ContactPageFormChildName = "Child's Name";
export const ContactPageFormAge = "Child's Age";
export const ContactPageFormEmail = 'Contact Email';
export const ContactPageFormPhone = 'Phone Number';
export const ContactPageFormEnquire = 'What you are enquiring about?';
export const ContactPageFormDetails =
  'Is there any other information you would like us to know?';
export const ContactPageEnquiryOptions = [
  'Lessons for Younger Kids',
  'Lessons for Older Kids',
  'Lessons for Adults',
  'Help with Projects/Assignments',
  'General Enquiry',
  'Other'
];
export const ContactPageFormReferred = 'How did you hear about us?';
export const ContactPageReferredOptions = [
  'Facebook',
  'Google',
  'Referred by an existing Student/Parent',
  'Other'
];
export const ContactPageFormSubmit = 'Submit';
export const ContactPageFormHome = 'Home';

export const ContactReceivedPageSummary = '';

export const ContactPageSubmittedForm =
  'Thank you for submitting an enquiry, expect a reply within 24 hours';
export const ContactPageSubmittedFormFailed =
  "I'm sorry, Something went wrong. While we are looking into the problem, please send us an email at info@aptetut.com";

//Faq
export const FAQTitle = 'FAQ';

export const FAQSummary = 'Below are the most common questions that we are asked, if your query is not covered here, please reach to us using the "Get in touch" button below.';

export const FAQContactButton = 'Ask as another question';

export const FAQandA = [
  {
    question:
      'Do you tutor in person or online?',
    answer: 'Our tutors work almost exclusively online, but depending on where you are located and what your needs are might be able to make something work. Contacts us and we can see what we can do.'
  },
  {
    question:
      'Do you tutor students outside of Australia?',
    answer: "Yes we do, however there is the challenge with being in different time zones. When contacting us let us know what time zone you are in and we will work with you to find a tutor who can work with you."
  },
  {
    question:
      'What is the youngest age students you will accept?',
    answer: "Every child is different and has different abilities. Generally aged 7 and older, but this can be assessed on an individual basis. "
  },
  {
    question:
      'Do you have any experience with students with learning difficulties or disabilities?',
    answer:'Yes, we have several tutors that specialises in working with students with different learning needs. Please let us know what specificity you need from your lessons and we will ensure that we provide the best tutoring experience.'
  },
  {
    question:
      'What happens if I am not happy with the tutor who is working with me?',
    answer: 'We want to ensure that both our students and our tutors are as comfortable as possible. If you think that a different tutor or teaching style would suit you better, we can work with you to accommodate that.'
  },
  {
    question:
      'Are you LGBTIQA+ friendly?',
    answer: "Yes. We are welcoming of people of all ages, backgrounds, genders, and sexual identities. "
  },
  {
    question:
      'How does payment for lessons work?',
    answer: "Normally you will be invoiced after the lesson, and you can pay though a online payment portal. But some people prefer to purchase lessons in sets. Either way we can work with you to make a process that suits you."
  },
  {
    question:
      'Do you support NDIS invoicing?',
    answer: "We have multiple students that are on the Australian NDIS system. While we are not connected with NDIS it directly, we can ensure that our invoices are correctly setup to be sent through your NDIS caseworker. Feel free to contact us for more information."
  },
];

//ContactReceived
export const ContactReceivedPageTitle = 'Thank you';

export const ContactReceivedPagesummary = '';

//Programming
const ProgrammingStreamHeading = 'Programming';
const ProgrammingImageSmall = './landing/stream-programming-small.png';
const ProgrammingImageLarge = './landing/stream-programming-big.png';
const ProgrammingColour = '#190b6c';
const ProgrammingUrl = '/programming';
const ProgramminghomeSummary =
  'Learn game programming to create your own interactive worlds';
const ProgrammingHeading = 'Programming';
const ProgrammingSubheading =
  'Learn real 2D and 3D video game programming to create you own interactive worlds';
const ProgrammingContent1 = [
  "Are you wanting to start on the journey to programming your own video games? With us you'll learn everything you need to know to start creating games from the ground up.",

  'Our experienced tutors will guide you through each lesson, providing hands-on examples and practical exercises to help you master the material.',

  'The development tools used in the course (such as Unity, Godot, Blender and Visual Studio) are the same ones used in the professional industry, and all are free to ensure there are no additional costs.',

  "In addition to learning the technical skills needed, you'll also have the opportunity to work on a variety of projects, from simple 2D games to complex 3D worlds. This will not only help you build a strong portfolio, but it will also give you the chance to put your new skills into practice and see your ideas come to life. ",

  "If you're ready to start your learning journey, we can't wait to work with you. Contact us and we can match you with a qualified tutor and tailor a personalised course to your individual needs and skill level."
];

export const ProgrammingDetails = {
  homeHeading: ProgrammingStreamHeading,
  homeSummary: ProgramminghomeSummary,
  heading: ProgrammingHeading,
  subHeading: ProgrammingSubheading,
  imageSmall: ProgrammingImageSmall,
  imageLarge: ProgrammingImageLarge,
  text: ProgrammingContent1,
  url: ProgrammingUrl,
  backgroundColour: ProgrammingColour
};

//Art

const ArtStreamHeading = '2D and 3D Art';
const ArtImageSmall = './landing/stream-art-small.png';
const ArtImageLarge = './landing/stream-art-big.png';
const ArtColour = '#6c0938';
const ArtUrl = '/art';

const ArtHeading = '2D and 3D Art';
const ArthomeSummary =
  'Draw, model, and animate assets to bring your ideas to life';
const ArtSubheading =
  'Learn professional techniques and tools to create 2D and 3D art assets';
const ArtContent = [
  'Unlock the artistic potential within you and breathe life into ideas with 2D and 3D art creation techniques and tools. As a artist, you possess the ability to transform your visions into amazing pieces of art, and we are here to equip you with the necessary skills to make it a reality.',

  'Our team of tutors will guide you through each lesson, providing hands-on examples and practical exercises that ensure a thorough grasp of the material.',

  // "In addition to mastering the technical skills, you will have the invaluable opportunity to engage in a diverse array of projects. From crafting simple 2D games to constructing complex 3D worlds, these hands-on endeavors will not only bolster your portfolio but also enable you to unleash your newfound skills and witness your ideas materialise before your eyes. The possibilities are boundless, and the sky's the limit.",

  "If you're ready to start making amazing art for your game, we can't wait to work with you. Contact us and we can match you with qualified tutor and tailor personalised a course to your individual needs and skill level."
];

export const ArtDetails = {
  homeHeading: ArtStreamHeading,
  homeSummary: ArthomeSummary,
  heading: ArtHeading,
  subHeading: ArtSubheading,
  imageSmall: ArtImageSmall,
  imageLarge: ArtImageLarge,
  text: ArtContent,
  url: ArtUrl,
  backgroundColour: ArtColour
};

//Design

const DesignImageSmall = './landing/stream-design-small.png';
const DesignImageLarge = './landing/stream-design-big.png';
const DesignColour = '#bc0931';
const DesignUrl = '/design';
const DesignhomeSummary =
  'Master the techniques and tools to plan and design the game of your dreams';
const DesignHeading = 'Design';
const DesignStreamHeading = 'Design';
const DesignSubheading =
  'Game designers have the unique opportunity to shape the player experience, create immersive interactive worlds, and tell amazing stories through game play.';

const DesignContent = [
  'Game Design is the art of making a game fun and engaging for players.',

  "We cover various game design aspects, such as narrative design, where you'll learn how to weave compelling stories; level design used to determine the flow and pacing of the game; and the testing lifecycle, used to bring out the best in your games.",

  'In addition we will teach you all the tools a game designer uses to make games a reality, and how to work effectively within a team to collaboratively build a successful game',

  "If you're ready to take on the challenge of designing games, we can't wait to work with you. Contact us and we can match you with a qualified tutor and tailor a personalised course for your individual needs and skill level."
];

export const DesignDetails = {
  homeHeading: DesignStreamHeading,
  homeSummary: DesignhomeSummary,
  heading: DesignHeading,
  subHeading: DesignSubheading,
  imageSmall: DesignImageSmall,
  imageLarge: DesignImageLarge,
  text: DesignContent,
  url: DesignUrl,
  backgroundColour: DesignColour
};

//Solo Developer

const SoloImageSmall = './landing/stream-solo-small.png';
const SoloImageLarge = './landing/stream-solo-big.png';
const SoloColour = '#2f1f81';
const SoloHeading = 'Solo Developer';
const SoloStreamHeading = 'Solo Developer';
const SoloUrl = '/solo';
const SolohomeSummary =
  'Manage all aspects of the game development pipeline from beginning to end';

const SoloSubheading =
  'Game designers have the unique opportunity to shape the player experience, create immersive, interactive worlds and tell amazing stories.';

const SoloContent = [
  "We understand that developing games as a solo developer can be a challenging but rewarding experience. That's why we've created this course specifically for individuals who are looking to take on the task of creating games on their own.",

  'We cover topics such as Game Design, Programming, Art, and Audio, providing step-by-step guidance on how to build your game.',

  'Our courses are designed to be flexible and adaptable to your specific needs and goals. Whether you are just starting out in game development or have been working in the industry for years, we have something for you.',

  "If you're ready to take on the challenge of creating games as a solo developer, we can't wait to work with you. Contact us and we can match you with a qualified tutor and tailor a personalised course for your individual needs and skill level."
];

export const SoloDetails = {
  homeHeading: SoloStreamHeading,
  homeSummary: SolohomeSummary,
  heading: SoloHeading,
  subHeading: SoloSubheading,
  imageSmall: SoloImageSmall,
  imageLarge: SoloImageLarge,
  text: SoloContent,
  url: SoloUrl,
  backgroundColour: SoloColour
};


// STEM
const STEMStreamHeading = 'STEM';
const STEMImageSmall = './landing/stream-stem-small.png';
const STEMImageLarge = './landing/stream-stem-big.png';
const STEMColour = '#2f1f81';
const STEMUrl = '/stem';
const STEMhomeSummary =
  'Dive into the world of STEM to unlock the power of science and technology';
const STEMHeading = 'STEM';
const STEMSubheading =
  'Explore Science, Technology, Engineering, and Mathematics to become a problem-solver of tomorrow';
const STEMContent = [
  'Are you curious about how the world works and eager to solve its mysteries through science and technology? Our STEM tutoring program is designed to ignite your passion for discovery and innovation.",.',

  'Our experienced tutors will guide you through each lesson, providing clear explanations, examples and practical exercises to help you master the material.',

  "If you're ready to embark on a journey of discovery and innovation, we're here to support you every step of the way. Contact us to find a dedicated tutor who will craft a personalized learning plan tailored to your interests and proficiency level.",
];

export const STEMDetails = {
  homeHeading: STEMStreamHeading,
  homeSummary: STEMhomeSummary,
  heading: STEMHeading,
  subHeading: STEMSubheading,
  imageSmall: STEMImageSmall,
  imageLarge: STEMImageLarge,
  text: STEMContent,
  url: STEMUrl,
  backgroundColour: STEMColour
};

//-----------------------------------------------------------------------------------------------------------------//

const FirstLessonFree =
  'We guarantee that all students that we offer the first lesson free for any  ';

//Younger Kids

const Home14UnderHeading = 'Younger Kids';
const Home14Undersummary =
  'One-on-one tutoring, holiday programs, encouraging creativity, programming basics, beginner certifications';
const Home14UnderImage = '/landing/less-than-15.png';
const Home14UnderColour = '#b58604';
const Home14UnderContent = [
  "Empower your child's creativity and curiosity with lessons taught by qualified tutors. We offer a variety of engaging activities, one-on-one tutoring sessions, and exciting holiday programs focused on introducing children to the world of video game development and design.",

  'Our courses are thoughtfully designed to be age-appropriate and enjoyable, ensuring that learning is a fun and immersive experience for young minds. We understand that each child is unique, so we ensure that they are paired with a tutor who can best understand their learning needs and individual strengths. This includes children with learning challenges or disabilities.',

  "Our primary goal is to foster a positive and inclusive learning environment, making education accessible and enjoyable for every child. We believe that learning should be an exciting adventure, and we are dedicated to nurturing your child's passion for video game development.",

  'We also offer our first lesson free to ensure that they are comfortable with their tutor, the material, and the learning environment.',

  'Contact us today for an obligation-free discussion, and together we can determine the best course of action to provide your child with an enriching and rewarding learning experience.'
];
const Home14UnderOriginalCost = 55;
const Home14UnderCost = 55;
const Home14UnderCurrency = 'AUD';

//Older Kids
const Home18UnderHeading = 'Older Kids';
const Home18Undersummary =
  'Learn programming, tertiary education preparation, design and world building, 3D modeling';
const Home18UnderImage = '/landing/teen.png';
const Home18UnderColour = '#6751e0';
const Home18UnderContent = [
  'We offer a range of courses and activities that are designed specifically for older Kids. We cover a wide range of topics, including Game Design, Programming, Art, and Audio Design. Providing step-by-step guidance on how to create your own games from the ground up.',

  'Whether they are just starting out or already have some experience under their belt, our courses are designed to be flexible and adaptable to their specific needs and goals.',

  'We also specialise in helping students build a portfolio in preparation for application to higher education. We have ties to various tertiary institution across Australia, and can ensure that you have the best chance possible when applying',

  'We also offer our first lesson free to ensure that they are comfortable with their tutor, the material, and the learning environment.',

  'Contact us today for an obligation-free discussion, and together we can determine the best course of action for your child.'
];
const Home18UnderOriginalCost = 65;
const Home18UnderCost = 57;
const Home18UnderCurrency = 'AUD';

//Adults
const HomeAdultsHeading = 'Adults';
const HomeAdultssummary =
  'Assignment help, game development, programming support';
const HomeAdultsImage = '/landing/adult.png';
const HomeAdultsColour = '#475a3a';
const HomeAdultsContent = [
  "Whether you're a complete beginner to game development, or just need help with your personal project or university assignment, our experienced tutors can help ",

  'Get in touch with us and let us know what you need and we will match you with a tutor who can help you',

  'Whether you are just starting out in game development or have some experience under your belt, our program is designed to be flexible and adaptable to your specific needs and goals.',

  'We also offer our first lesson free to ensure that you are comfortable with you tutor, the material, and the learning environment.',

  "We can't wait to work with you. Join us and start your journey as a game developer today!"
];
const HomeAdultsOriginalCost = 80;
const HomeAdultsCost = 60;
const HomeAdultsCurrency = 'AUD';



//Age Cards


export const AgeCards = [
  {
    heading: Home14UnderHeading,
    summary: Home14Undersummary,
    image: Home14UnderImage,
    alt: Home14UnderHeading,
    backgroundColour: Home14UnderColour,
    text: Home14UnderContent,
    cost: Home14UnderCost,
    originalCost: Home14UnderOriginalCost,
    currency: Home14UnderCurrency
  },
  {
    heading: Home18UnderHeading,
    summary: Home18Undersummary,
    image: Home18UnderImage,
    alt: Home18UnderHeading,
    backgroundColour: Home18UnderColour,
    text: Home18UnderContent,
    cost: Home18UnderCost,
    originalCost: Home18UnderOriginalCost,
    currency: Home18UnderCurrency
  },
  {
    heading: HomeAdultsHeading,
    summary: HomeAdultssummary,
    image: HomeAdultsImage,
    alt: HomeAdultsHeading,
    backgroundColour: HomeAdultsColour,
    text: HomeAdultsContent,
    cost: HomeAdultsCost,
    originalCost: HomeAdultsOriginalCost,
    currency: HomeAdultsCurrency
  }
];

//Schools

const SchoolsTitle = 'School Visits';

const SchoolsCopy = [ "Aptetut is a dedicated educational platform with a mission to empower students in the fields of Information Technology (IT) and Game Development. Our primary objective is to equip students with the knowledge, resources, and support necessary for success in these dynamic industries.",

"Our founder, Ben Naulls, shares a profound passion for education and game development. Through regular presentations and workshops, he imparts valuable insights and guidance, unveiling diverse pathways into the world of game development.",

"For younger students, our talks aim to ignite creativity and inspire them to delve deeper into game development beyond merely playing games. We encourage them to explore their imagination, fostering an understanding of the possibilities within this exciting field.",

"For older students, our focus shifts towards aiding their transition from high school to higher education in game development. We provide essential information about available opportunities and guide them on how to pursue their interests effectively.",

"Furthermore, for students already in higher education, our talks concentrate on the importance of continuous self-learning, practical strategies for job applications, and the significance of building a professional network. These insights empower them to thrive in their academic journey and prepare them for a successful entry into the industry.",

"With connections to various universities, TAFEs, and private institutions across Australia, Aptetut stays well-informed about the ever-evolving industry requirements. This knowledge enables us to offer students the best possible chances of succeeding in their applications and aspirations.",

"If you wish to have Aptetut speak at your school, institution, or group, please reach out to us via email at info@aptetut.com.",

"We are committed to supporting students' aspirations and helping them discover their true potential in the dynamic and fulfilling worlds of IT and game development.",

];

export const SchoolsPageCopy = {
  title: SchoolsTitle,
  copy: SchoolsCopy,
};

//About
const AboutTitle = 'About Us';

const AboutSubtitle =
  "We firmly believe we are Australia's best personalised video game tutoring business.";

const AboutBen1 =
  'Aptetut was founded by Ben Naulls, a game designer and programmer, with years of experience in teaching and in industry.';

const AboutBen2 =
  'After several years teaching in a tertiary institution, Ben sought out more industry experience and began working with Melbourne game studios in programming and design roles. With a passion for teaching that he could not leave behind, Ben continued to tutor a small number of students. During the COVID-19 pandemic there was greater demand for on-line tutoring as more and more students moved to on-line learning. Ben made the decision to leave his job as a programmer in a Melbourne game studio to focus on building a community to help educate the next generation of game developers.';

const AboutCopy = [
  'First and foremost we believe that learning should be fun and engaging. Our lessons are designed to get you creating as soon as possible, to foster imagination and build confidence.',

  'All of our tutors are handpicked for their skills and experience making games. And with each new student, we spend time to ensure that they are paired with a tutor that matches their needs and learning styles.',

  'Tutors will design a personalised learning plan for each new student to ensure that they get the leaning experience that best suits them.',

  'If you are seeking on-going tutoring, we offer our first lesson free as a risk-free way to ensure that all students are happy with their tutor and the material covered.',

  "We believe that everyone has the potential to create great games, and our goal is to help you bring your ideas to life. So if you're ready to start your game development journey, or take your current game development skills to the next level, we can't wait to work with you."
];

const AboutCTA = 'Let us know what we can do for you.';

export const AboutPageCopy = {
  title: AboutTitle,
  subtitle: AboutSubtitle,
  copy: AboutCopy,
  cta: AboutCTA,
  ben1: AboutBen1,
  ben2: AboutBen2
};

//Quotes

export const Quotes = [
  {
    quote:
      'Ben is such a great tutor. He is extremely knowledgeable and his lessons are engaging and interesting.',
    by: 'Sandy'
  },
  {
    quote:
      'Great Tutor, in-depth knowledge of C# and Unity. Great for advanced learning',
    by: 'Eoghann'
  },
  {
    quote:
      'A fantastic tutor! Great communication, subject knowledge and teaching style! Thank you Benjamin',
    by: 'Karen'
  },
  {
    quote:
      'Awesome teacher for my son. Very patient and generous, knows how to teach the way the student can understand. I would highly recommend Benjamin to anyone interested in learning how to program.',
    by: 'Anoma'
  },
  {
    quote:
      'My son is absolutely enjoying his lessons, very engaging and fun. Great tutor!',
    by: 'Julia'
  },
  {
    quote: 'An excellent, patient and very thorough tutor. Highly recommended.',
    by: 'Shish'
  },
  {
    quote:
      'Ben is fantastic! Great to talk to, conveys things clearly and effectively, and is able to adapt to my needs as a student.',
    by: 'James'
  }
];
