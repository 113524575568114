import { Payment } from 'src/models/payment';
import { FC } from 'react';
import { Lesson } from 'src/models/lesson';
import { Tutor } from 'src/models/tutor';
import Chart from 'react-apexcharts';
import { Box, Card, CardContent, Container, useTheme } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import React from 'react';
import { getWeekNumber } from 'src/tools/dateTools';
import { it } from 'date-fns/locale';
//import { LineChart } from '@mui/x-charts/LineChart';

interface TableProps {
  payments: Payment[];
}

// export interface paymentsStatData {
//   title: string,
//   dataLabel: string,
//   labels: string[],
//   income: number[],
//   outgoings: number[],
//   tutorPay: number[],
// }

const WeeklyPayments: FC<TableProps> = ({ payments: payments }) => {
  const theme = useTheme();

  function generateWeekNumbers(X: number): string[] {
    const result: string[] = [];
    for (let i = 1; i <= X; i++) {
      result.push(i.toString());
    }
    return result;
  }

  const chartOptions: ApexOptions = {
    chart: {
      background: 'transparent',
      foreColor: '#fff',
      toolbar: {
        show: false
      },

      zoom: {
        enabled: false
      }
    },
    fill: {
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.1,
        inverseColors: false,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [0, 100]
      }
    },
    colors: [theme.colors.primary.main, 'white', 'blue','green'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      colors: [theme.colors.primary.main, 'white', 'blue','green'],
      width: 3
    },
    legend: {
      show: true,
      onItemHover: {
        highlightDataSeries: true
      },
    },
    labels: generateWeekNumbers(getWeekNumber(new Date())),

    xaxis: {
      labels: {
        show: true,
        style: {
          colors: '#ffffff',
        },
      },

      axisBorder: {
        show: true
      },
      axisTicks: {
        show: true
      }
    },
    yaxis: {
      min: 0,
      labels: {
        style: {
          colors: '#ffffff',
        },
        formatter: function (value) {
          return "$" + value;
        }
      },
      show: true,
      tickAmount: 5
    },
    tooltip: {
      theme: 'dark',
      marker: {
        show: true,
      },
    },

  };

  function initializeDictionary(maxKey: number): Record<number, number> {
    const dictionary: Record<number, number> = {};

    for (let i = 1; i <= maxKey; i++) {
      dictionary[i] = 0;
    }

    return dictionary;
  }


  /**
   * Given a list of payments, returns a list of weekly income data.
   * Each element is a tuple of [week number, income], where income is the sum of payments made during that week.
   */
  const GetWeeklyIncome = (filteredPayments: Payment[]): [number, number][] => {
    const currentWeek = getWeekNumber(new Date());

    const weekDict: Record<number, number> = initializeDictionary(currentWeek);

    filteredPayments.forEach((payment) => {
      const paymentDate = new Date(payment.payment_date);
      const weekNumber = getWeekNumber(paymentDate);

      if (weekNumber in weekDict) {
        weekDict[weekNumber] += payment.payment_amount;
      }
    });

    // Convert to an array and sort by week number
    const sortedWeekArray = Object.entries(weekDict).map(([weekNumber, amount]) => [
      parseInt(weekNumber, 10),
      amount,
    ]).sort((a, b) => a[0] - b[0]) as [number, number][];

    return sortedWeekArray;
  };

  const GetNet = (filteredPayments: Payment[]): [number, number][] => {
    const income = GetWeeklyIncome(payments.filter(item => item.category == "credit_payment" || item.category == "lesson_payment"))
    const outgoings = GetWeeklyIncome(payments.filter(item => item.category == "refund" || item.category == "expense" || item.category == "tutor_paid"))

    return income.map((item, index) => {
      const net = item[1] - outgoings[index][1]
      return [item[0], net]
    })
  }

  const chartData = [
    {
      name: "Income",
      data: GetWeeklyIncome(payments.filter(item => item.category == "credit_payment" || item.category == "lesson_payment"))
    },
    {
      name: "Tutor Pay",
      data: GetWeeklyIncome(payments.filter(item => item.category == "tutor_paid"))
    },
    {
      name: "Outgoings",
      data: GetWeeklyIncome(payments.filter(item => item.category == "refund" || item.category == "expense"))
    },
    {
      name: "NET",
      data: GetNet(payments)
    },
  ];

  return (
    <>
      <Card>
        <CardContent >
          <Box >
            <h2>Income / Outgoing</h2>
            {payments &&
              <Chart
                options={chartOptions}
                series={chartData}
                type="area"
                height={300}
              />
            }
          </Box>

        </CardContent>
      </Card>
    </>
  );
};


export default WeeklyPayments;
