import { styled } from '@mui/system';

//With a given string 
export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const ColoredCircle = styled('div')<{ color?: string }>(
  ({ color = '#f3ac3c' }) => ({
    width: '25px', // Adjust the size of the circle as needed
    height: '25px', // Adjust the size of the circle as needed
    backgroundColor: color,
    borderRadius: '50%', // Makes it a circle
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto', // Center the circle horizontally
  })
);