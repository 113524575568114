import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  Autocomplete,
  Box,
  CircularProgress
} from '@mui/material';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tutor } from 'src/models/tutor';
import { RegisterTutor, TutorSubmitTypes } from '../../../actions/TutorActions';
import { timezones } from 'src/tools/dateTools';

interface errorInterface {
  isError: boolean;
  message: string;
}

type ButtonProps = {
  title: string;
};

const AddTutorButton = ({ title }: ButtonProps) => {
  const [saveTutorData, setSaveTutorData] = useState<TutorSubmitTypes>();

  const [errors, setErrors] = useState<{
    email: string;
    firstName: string;
    lastName: string;
    hourlyRate: string;
    phone: string;
    parentFirstName: string;
    parentLastName: string;
    parentEmail: string;
    parentPhone: string;
  }>({
    email: '',
    firstName: '',
    lastName: '',
    hourlyRate: '',
    phone: '',
    parentFirstName: '',
    parentLastName: '',
    parentEmail: '',
    parentPhone: '',
  });

  const updateErrorState = (field, message) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: message
    }));
  };

  const [open, setOpen] = useState(false);

  //Form Data
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    hourly_rate: 30,
    phone: '',
  });

  const dispatch = useDispatch();

  //On Submit of the form
  const onSubmit = () => {
    const tutorsData: Tutor = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      hourly_rate: 30,
      email: formData.email,
      updatePassword: true,
      phone: formData.phone,
      timezone: selectedTimezone.value,
      permissions: ['tutor'],
      students: [],
      status: 'active'
    };

    formData.email = formData.email.toLowerCase();

    if (!handleValidation()) {
      return;
    }

    dispatch<any>(RegisterTutor(tutorsData, setSaveTutorData));
  };

  //Validate form
  const handleValidation = () => {
    let formIsValid = true;

    //First Name
    if (formData.first_name === '') {
      formIsValid = false;
      updateErrorState('firstName', "Field can't be empty");
    } else if (!formData.first_name.match(/^[a-zA-Z]+$/)) {
      formIsValid = false;
      updateErrorState('firstName', 'Only letters');
    } else {
      updateErrorState('firstName', '');
    }

    //Last Name
    if (formData.last_name === '') {
      formIsValid = false;
      updateErrorState('lastName', "Field can't be empty");
    } else if (!formData.last_name.match(/^[a-zA-Z]+$/)) {
      formIsValid = false;
      updateErrorState('lastName', 'Only letters');
    } else {
      updateErrorState('lastName', '');
    }

    //Phone
    if (formData.phone === '') {
      formIsValid = false;
      updateErrorState('phone', "Field can't be empty");
    } else {
      updateErrorState('phone', '');
    }

    //Email
    if (formData.email === '') {
      formIsValid = false;
      updateErrorState('email', "Field can't be empty");
    } else if (formData.email) {
      let lastAtPos = formData.email.lastIndexOf('@');
      let lastDotPos = formData.email.lastIndexOf('.');

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          formData.email.indexOf('@@') == -1 &&
          lastDotPos > 2 &&
          formData.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        updateErrorState('email', 'Email is not valid');
      }
    } else {
      updateErrorState('email', '');
    }

    //Hourly Rate
    if (!formData.hourly_rate || formData.hourly_rate <= 0) {
      formIsValid = false;
      updateErrorState('hourlyRate', 'Hourly rate must be greater than ');
    } else {
      updateErrorState('hourlyRate', '');
    }

    return formIsValid;
  };

  useEffect(() => {
    if (saveTutorData?.type === 'Success') {
      window.location.reload();
    } else if (saveTutorData?.type === 'Failed') {
    }
  }, [saveTutorData]);

  // This function is called when the input changes
  const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  useEffect(() => {
    setFormData({
      first_name: '',
      last_name: '',
      email: '',
      hourly_rate: 30,
      phone: '',
    });
    setSelectedTimezone(null);
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
    updateErrorState('email', '');
    updateErrorState('phone', '');
    updateErrorState('firstName', '');
    updateErrorState('lastName', '');
    updateErrorState('hourlyRate', '');
  };

  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const handleTimezoneChange = (event, newValue) => {
    setSelectedTimezone(newValue);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="first_name"
            label="First Name"
            variant="outlined"
            name="first_name"
            required={true}
            error={errors.firstName.length > 0}
            helperText={errors.firstName.length > 0 ? errors.firstName : ''}
            value={formData.first_name}
            onChange={onChanged}
          />{' '}
          <TextField
            margin="dense"
            id="last_name"
            label="Last Name"
            variant="outlined"
            name="last_name"
            required={true}
            error={errors.lastName.length > 0}
            helperText={errors.lastName.length > 0 ? errors.lastName : ''}
            value={formData.last_name}
            onChange={onChanged}
          />
          <br />
          <TextField
            margin="dense"
            id="email"
            label="Email"
            variant="outlined"
            type="email"
            fullWidth={true}
            name="email"
            required={true}
            error={errors.email.length > 0}
            helperText={errors.email.length > 0 ? errors.email : ''}
            value={formData.email}
            onChange={onChanged}
          />
          <br />
          <TextField
            margin="dense"
            id="phone"
            label="Phone number"
            variant="outlined"
            type="phone"
            name="phone"
            required={true}
            error={errors.phone.length > 0}
            helperText={errors.phone.length > 0 ? errors.phone : ''}
            value={formData.phone}
            onChange={onChanged}
          />
          <br />
          <Autocomplete
            id="timezone"
            options={timezones}
            getOptionLabel={(option) => option.label}
            value={selectedTimezone}
            onChange={handleTimezoneChange}
            renderInput={(params) => (
              <TextField {...params} required={true} label="Timezone" />
            )}
          />
          <br />
          <TextField
            margin="dense"
            id="hourly_rate"
            label="Hourly Rate"
            error={errors.hourlyRate.length > 0}
            helperText={errors.hourlyRate.length > 0 ? errors.hourlyRate : ''}
            variant="outlined"
            type="number"
            name="hourly_rate"
            required={true}
            value={formData.hourly_rate}
            onChange={onChanged}
          />
          <br />
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            color="red"
            sx={{
              display:
                saveTutorData && saveTutorData.type === 'Failed'
                  ? 'block'
                  : 'none'
            }}
          >
            {saveTutorData && saveTutorData.type === 'Failed'
              ? saveTutorData.message
              : ''}
          </Typography>
        </DialogContent>

        {saveTutorData?.type == 'Submitting' ? (
          <Box
            sx={{
              position: 'fixed',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%'
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={64} disableShrink thickness={3} />
          </Box>
        ) : (
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={onSubmit}>Add</Button>
          </DialogActions>
        )}
      </Dialog>

      <Button
        sx={{ height: 50, mt: { xs: 2, md: 0 } }}
        variant="contained"
        size="small"
        startIcon={<AddTwoToneIcon fontSize="small" />}
        onClick={handleClickOpen}
      >
        {title}
      </Button>
    </>
  );
};

export default AddTutorButton;
