import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import {
  createTheme,
  ThemeProvider,
  styled,
  createStyles,
  Theme
} from '@mui/material/styles';
import { FC, ReactNode, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { orange } from '@mui/material/colors';
import {
  Avatar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  colors
} from '@mui/material';
import {
  LandingMenuHome,
  LandingMenuAbout,
  LandingSlogan,
  LandingPrivacyPolicy,
  LandingCredits,
  LandingMenuContact,
  LandingMenuDashboard,
  LandingMenuAccount,
  LandingMenuLogout,
  LandingMenuLogin,
  LandingMenuFAQ
} from 'src/content/copy/FrontendCopy';
import { GetLocalUser } from 'src/actions/AuthActions';
import { Student } from 'src/models/student';
import { Tutor } from 'src/models/tutor';
import { LoggedInUser } from 'src/models/loggedInUser';
import { isLocalhost } from 'src/serviceWorker';
//import { LoggedInTutor } from 'src/models/localUser';

const styles = {
  // this group of buttons will be aligned to the right side
  toolbarButtons: {
    marginLeft: 'right'
  }
};

function Copyright() {
  return (
    <Typography display="inline" variant="body2" color="#fcfcfc" align="left">
      {'Copyright © '}
      <Link color="inherit" href="https://aptetut.com/">
        Aptetut
      </Link>{' '}
      {new Date().getFullYear()}
      {'. All Rights Reserved'}
    </Typography>
  );
}

const settingsTutor = [
  {
    heading: LandingMenuDashboard,
    link: 'dashboards'
  },
  // {
  //   heading: LandingMenuAccount,
  //   link: '/management/profile/settings/'
  // },
  {
    heading: LandingMenuLogout,
    link:  '/logout?logout=true'
  }
];

const settingsStudent = [
  {
    heading: LandingMenuDashboard,
    link: 'dashboards'
  },
  // {
  //   heading: LandingMenuAccount,
  //   link: '/management/profile/settings/'
  // },
  {
    heading: LandingMenuLogout,
    link: '/logout?logout=true'
  }
];

const settings = [
  {
    heading: LandingMenuDashboard,
    link: '/dashboards'
  },
  // {
  //   heading: LandingMenuAccount,
  //   link: 'https://source.unsplash.com/random'
  // },
  {
    heading: LandingMenuLogout,
    link: '/logout?logout=true'
  }
];

const pages = [
  {
    heading: LandingMenuHome,
    link: '/'
  },
  {
    heading: LandingMenuAbout,
    link: '/about'
  },
  {
    heading: LandingMenuFAQ,
    link: '/faq'
  },
  // {
  //   heading: 'Game Dev Resources',
  //   link: '/resources'
  // },
  {
    heading: LandingMenuContact,
    link: 'contact'
  }
  // {
  //   heading: 'Hiring',
  //   link: 'hiring'
  // }
];

// declare module '@mui/material/styles' {
//   // allow configuration using `createTheme`
//   interface ThemeOptions {
//     status?: {
//       danger?: string;
//     };
//   }
// }

// Non-dependent styles
const theme = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column'
  }
});

interface LandingLayoutProps {
  children?: ReactNode;
}

const Landing: FC<LandingLayoutProps> = () => {
  let navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (link: string) => {
    setAnchorElNav(null);
    navigate(link);
  };

  const handleCloseUserMenu = (link: string) => {
    setAnchorElUser(null);
    navigate(link);
  };

  const styles = {
    paperContainer: {
      backgroundImage: '/landing/landing-background.png',
      bgcolor: '#050510'
    }
  };

  const LogIn = () => {
    navigate('/login');
  };

  const GetUser = () => {
    let tutor: LoggedInUser = GetLocalUser();
    return tutor;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <AppBar
        position="static"
        sx={{
          mb: -10
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="info"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' }
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.heading}
                    onClick={() => handleCloseNavMenu(page.link)}
                  >
                    <Typography textAlign="center">{page.heading}</Typography>
                  </MenuItem>
                ))}
              </Menu>

              <Link href="/">
                <Box
                  component="img"
                  sx={{
                    mt: -2,
                    pl: 2,
                    height: 85,
                    mb: 2.5,
                    display: { xs: 'flex', md: 'none' }
                  }}
                  alt="Aptetut logo"
                  src="/landing/logo_header.png"
                />
              </Link>
            </Box>

            <Link href="/">
              <Box
                component="img"
                sx={{
                  mt: -2,
                  pl: 10,
                  height: 80,
                  display: { xs: 'none', md: 'flex' }
                }}
                alt="Aptetut logo"
                src="/landing/logo_header.png"
              />
            </Link>

            <Box
              sx={{
                pl: 10,
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' }
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page.heading}
                  onClick={() => handleCloseNavMenu(page.link)}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.heading}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <>
                {GetUser()? (
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={GetUser().first_name + " " + GetUser().last_name}
                      src="/static/images/avatar/2.jpg"
                      sx={{ width: 40, height: 40, bgcolor: "#c2892e" }}
                    />
                  </IconButton>
                ) : (
                  <Button
                    onClick={LogIn}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    {LandingMenuLogin}
                  </Button>
                )}
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting.heading}
                      onClick={() => handleCloseUserMenu(setting.link)}
                    >
                      <Typography textAlign="center">
                        {setting.heading}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Box display="block">
        <Outlet />
      </Box>

      {/* Footer */}
      <Box
        sx={{
          bgcolor: '#0b0624',
          px: { xs: 4, sm: 4, md: 12 },
          pt: 2,
          pb: 2
        }}
        component="footer"
      >
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12} md={12}>
            <Stack direction="row" spacing={3}>
              <Link color="inherit" href="/">
                <Box
                  component="img"
                  sx={{
                    height: 45
                  }}
                  alt="Aptetut logo"
                  src="/logo-white.png"
                />
              </Link>
              <Divider orientation="vertical" flexItem />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Typography variant="subtitle1">{LandingSlogan}</Typography>
              </Box>
            </Stack>
          </Grid>

          <Box
            sx={{
              borderTop: 1,
              borderColor: '#8c8c8c',
              mt: '10px',
              height: '10px'
            }}
          />
          <Grid item sm={12} xs={12} md={12}>
            <Stack direction="row" spacing={3}>
              {pages.map((page) => (
                <Typography display="block" variant="body2" key={page.heading}>
                  <Link color="inherit" href={page.link}>
                    {page.heading}
                  </Link>
                </Typography>
              ))}
              <Typography display="block" variant="body2" key="facebook">
                <Link color="inherit" href="https://www.facebook.com/aptetut">
                  facebook
                </Link>
              </Typography>
              <Typography display="block" variant="body2" key="twitter">
                <Link color="inherit" href="https://twitter.com/aptetut">
                  Twitter
                </Link>
              </Typography>
              <Typography display="block" variant="body2" key="linkedin">
                <Link color="inherit" href="https://www.linkedin.com/company/92638910/">
                  Linkedin
                </Link>
              </Typography>
            </Stack>
            <Stack marginTop={1} marginBottom={0.5}  direction="row" spacing={3}>
              <Typography display="block" variant="body2" key="email">
                <Link color="inherit" href="mailto:info@aptetut.com">
                  info@aptetut.com
                </Link>
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Box
          sx={{
            borderTop: 1,
            borderColor: '#8c8c8c',
            mt: '10px',
            height: '10px'
          }}
        />
        <Grid item sm={12} xs={12} md={12}>
          <Stack direction="row" spacing={3}>
            <Typography display="inline" variant="body2">
              <Link color="inherit" href="privacy-policy">
                {LandingPrivacyPolicy}
              </Link>
            </Typography>
            <Typography display="inline" variant="body2">
              <Link color="inherit" href="credits">
                {LandingCredits}
              </Link>
            </Typography>
            <Copyright />
          </Stack>
        </Grid>
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
};

export default Landing;
