import axios from 'axios';
import {
  Dispatcher,
  FAILED,
  SUBMITTING,
  SUCCESS,
  LOADING,
  GetToken,
  Submitting,
  Fail,
  Loading,
  Success
} from './LoadingActions';
import { Payment } from 'src/models/payment';

import { API_URL } from '../settings';

//const API_URL = 'http://localhost:5001/api/payments/';
const API_URL_FULL = API_URL+'api/payments/';


export type PaymentsGetTypes = Loading | Fail | GetPaymentsSuccess;
export type PaymentGetTypes = Loading | Fail | GetPaymentSuccess;
export type PaymentSubmitTypes = Submitting | Fail | Success;

export interface GetPaymentsSuccess {
  type: typeof SUCCESS;
  payload: Payment[];
}

export interface GetPaymentSuccess {
  type: typeof SUCCESS;
  payload: Payment;
}

// -------------------------
// Get all Payments
// -------------------------
//
export const GetAllPayments =
  (
    startingDate: Date,
    daySpan: number,
    setData: Dispatcher<PaymentsGetTypes>
  ) =>
  async () => {
    try {
      // Get posting token
      const token = GetToken();

      // Get positing data
      const postData = {
        date: startingDate,
        day_span: daySpan
      };

      // Create posting config
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      // Set the data of loading
      setData({
        type: LOADING
      });

      // Post data
      const res = await axios.post(
        API_URL_FULL + 'get-all-payments',
        postData,
        config
      );

      // Set data as success
      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Output error
        if (error.response) {
          console.log(error.response.data);
        }

        // Set data as failed
        setData({
          type: FAILED,
          message: error.message
        });

        return;
      }

      // Set data as failed
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

// -------------------------
// Get all tutor payments
// -------------------------
//
export const GetTutorPayments =
  (tutor_id: string, setData: Dispatcher<PaymentsGetTypes>) => async () => {
    try {
      // Get posting token
      const token = GetToken();

      // Create posting config
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      // Set the data to loading
      setData({
        type: LOADING
      });

      // Post data
      const postData = {
        tutor_id: tutor_id
      };

      // Post data
      const res = await axios.post(
        API_URL_FULL + 'get-tutor-payments',
        postData,
        config
      );

      // Set the data status to success
      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      // Set the data status to error
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

// -------------------------
// Get a tutor payment
// -------------------------
//
export const GetTutorPayment =
  (
    tutor_id: string,
    payment_id: string,
    setData: Dispatcher<PaymentGetTypes>
  ) =>
  async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      // Set the data status to loading
      setData({
        type: LOADING
      });

      const postData = {
        tutor_id: tutor_id,
        payment_id: payment_id
      };

      const res = await axios.post(
        API_URL_FULL + 'get-tutor-payment',
        postData,
        config
      );

      // Set the data status to success
      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      // Set the data status to failed
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

// -------------------------
// Get all student payments
// -------------------------
//
export const GetStudentPayments =
  (student_id: string, setData: Dispatcher<PaymentsGetTypes>) => async () => {
    try {
      // Get posting token
      const token = GetToken();

      // Create posting config
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      // Set the data status to loading
      setData({
        type: LOADING
      });

      // Post data
      const postData = {
        student_id: student_id
      };

      const res = await axios.post(
        API_URL_FULL + 'get-student-payments',
        postData,
        config
      );

      // Set the data status to success
      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      // Set the data status to failed
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

// -------------------------
// Make a payment for student credits
// -------------------------
//
export const PaymentForCredits =
  (
    student_id: string,
    actioned_by: string,
    date: Date,
    credits: Number,
    payment: number,
    receipt: string,
    notes: string,
    setData: Dispatcher<PaymentSubmitTypes>
  ) =>
  async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: SUBMITTING
      });

      const postData = {
        student_id: student_id,
        date: date,
        actioned_by: actioned_by,
        credits: credits,
        payment: payment,
        receipt: receipt,
        notes: notes
      };

      await axios.post(API_URL_FULL + 'pay-for-credits', postData, config);

      setData({
        type: SUCCESS
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log(error.response.data);
        }

        setData({
          type: FAILED,
          message: error.message
        });
        return;
      }
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

// -------------------------
// Make a payment for refunding a student
// -------------------------
//
export const CreateRefund =
  (
    student_id: string,
    actioned_by: string,
    date: Date,
    credits: Number,
    payment: number,
    receipt: string,
    notes: string,
    setData: Dispatcher<PaymentSubmitTypes>
  ) =>
  async () => {
    try {
      // Get posting token
      const token = GetToken();

      // Create posting config
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      // Set the data status to submitting
      setData({
        type: SUBMITTING
      });

      // Create posting data
      const postData = {
        student_id: student_id,
        date: date,
        actioned_by: actioned_by,
        credits: credits,
        payment: payment,
        receipt: receipt,
        notes: notes
      };

      // Post data
      await axios.post(API_URL_FULL + 'add-refund', postData, config);

      // Set the data status to success
      setData({
        type: SUCCESS
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log(error.response.data);
        }

        // Set the data status to failed
        setData({
          type: FAILED,
          message: error.message
        });
        return;
      }
      // Set the data status to failed
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

// -------------------------
// Create an expense
// -------------------------
//
export const CreateExpense =
(
  actioned_by: string,
  date: Date,
  amount: Number,
  receipt: string,
  notes: string,
  expense_type: string,
  setData: Dispatcher<PaymentSubmitTypes>
) =>
async () => {
  try {
    // Get posting token
    const token = GetToken();

    // Create posting config
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    // Set the data status to submitting
    setData({
      type: SUBMITTING
    });

    // Create posting data
    const postData = {
      date: date,
      actioned_by: actioned_by,
      amount: amount,
      receipt: receipt,
      expense_type: expense_type,
      notes: notes
    };

    // Post data
    await axios.post(API_URL_FULL + 'add-expense', postData, config);

    // Set the data status to success
    setData({
      type: SUCCESS
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        console.log(error.response.data);
      }

      // Set the data status to failed
      setData({
        type: FAILED,
        message: error.message
      });
      return;
    }
    // Set the data status to failed
    setData({
      type: FAILED,
      message: 'Error'
    });
  }
};
