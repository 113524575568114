import axios from 'axios';

import {
  Dispatcher,
  FAILED,
  SUBMITTING,
  SUCCESS,
  LOADING,
  GetToken,
  Submitting,
  Default,
  Loading,
  Fail,
  Success
} from './LoadingActions';

import { Student } from '../models/student';
import { Lead } from '../models/lead';
import { API_URL } from '../settings';
import { GetLocalUser } from './AuthActions';
import { createDispatchHook } from 'react-redux';

//const API_URL = process.env.API_SERVER + "api/users/";
const API_URL_FULL = API_URL + 'api/leads/';

export type LeadsGetTypes = Loading | Fail | GetLeadsSuccess;
export type LeadGetTypes = Loading | Fail | GetLeadSuccess;
export type LeadSubmitTypes = Default | Submitting | Fail | GetLeadSuccess;

export interface GetLeadsSuccess {
  type: typeof SUCCESS;
  payload: Lead[];
}

export interface GetLeadSuccess {
  type: typeof SUCCESS;
  payload: Lead;
}

// Get all Leads
export const GetAllLeads = (setData: Dispatcher<LeadsGetTypes>) => async () => {
  try {
    const token = GetToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    setData({
      type: LOADING
    });

    const res = await axios.post(API_URL_FULL + 'get-all', {}, config);
    
    setData({
      type: SUCCESS,
      payload: res.data
    });
  } catch (error) {
    console.log(error);
    setData({
      type: FAILED,
      message: 'Error'
    });
  }
};

//Get all Leads
export const GetLeads =
  (tutor_id: string, setData: Dispatcher<LeadsGetTypes>) => async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      const postData = {
        id: GetLocalUser()._id,
      };

      setData({
        type: LOADING
      });

      const res = await axios.post(API_URL_FULL + 'get-all', {postData}, config);

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

// Get Lead
export const GetLead =
  (studentID: string, setData: Dispatcher<LeadGetTypes>) => async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: LOADING
      });

      const res = await axios.post(
        API_URL_FULL + 'student',
        { id: studentID },
        config
      );

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

  // Unsubscribe from leads
  export const UnsubscribeFromLeedsEmail =
  (email: string, setData: Dispatcher<LeadSubmitTypes>) =>
  async () => {
    try {
      setData({
        type: SUBMITTING
      });

      const res = await axios.post(API_URL + 'api/leads/unsubscribe', {
        email: email
      });

      setData({
        type: SUCCESS,
        payload: res.data
      });

    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log(error.response.data);
        }

        setData({
          type: FAILED,
          message: error.message
        });
        return;
      }
    }
  }


// Add a new lead
export const RegisterLead =
  (leadData: Lead, note: String, setData: Dispatcher<LeadSubmitTypes>) => async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      // Create posting data
      const postData = {
        id: GetLocalUser()._id,
        date: new Date(),
        first_name: leadData.first_name,
        last_name: leadData.last_name,
        interested_in: leadData.interested_in,
        email: leadData.email,
        phone: leadData.phone,
        region: "aus",
        timezone: leadData.timezone,
        origin: leadData.origin,
        createdAt: new Date(),
        createdBy: GetLocalUser()._id,
        note: note
      };

      setData({
        type: SUBMITTING
      });

      const res = await axios.post(API_URL_FULL + 'add', postData, config);

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log(error.response.data);
        }

        setData({
          type: FAILED,
          message: error.message
        });
        return;
      }
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

//Add Note to lead
export const AddNote =
  (
    student_id: string,
    actioned_by: string,
    note: string,
    setData: Dispatcher<LeadSubmitTypes>
  ) =>
  async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      const postData = {
        student_id: student_id,
        actioned_by: actioned_by,
        note: note
      };

      setData({
        type: SUBMITTING
      });
      const res = await axios.post(API_URL_FULL + 'add-note', postData, config);

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };
