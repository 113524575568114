import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField
} from '@mui/material';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { GetLocalUser } from 'src/actions/AuthActions';
import {
  LessonsGetTypes,
  LessonInterface,
  GetTutorLessons
} from 'src/actions/LessonActions';
import Footer from 'src/components/Footer';
import { GetAllStudents, StudentsGetTypes } from 'src/actions/StudentActions';
import UnpaidLessons from './UnPaidLessonsTable';
import BankPaymentsTable from './BankPaymentsTable';
import { DatePicker } from '@mui/lab';
import { Lesson } from '@/src/models/lesson';

const DashboardReceivables = () => {
  const [lessonData, setLessonData] = useState<LessonsGetTypes>();
  //const [studentsData, setStudentData] = useState<StudentsGetTypes>();

  const dispatch = useDispatch();

  let user = GetLocalUser();

  //Initial Load
  useEffect(() => {
    //If there is a user id in the url params
    if (user != null) {
      //dispatch<any>(GetAllStudents(setStudentData));
      dispatch<any>(GetTutorLessons(setLessonData, null, 'unpaid'));
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Payroll</title>
      </Helmet>
      <Box pt={2} />

      <Container maxWidth="lg">
        <h1>Receivables</h1>

        {/* <BankPaymentsTable /> */}

        {lessonData && lessonData.type == 'Success' ? (
          <UnpaidLessons allLessons={lessonData.payload} />
        ) : (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default DashboardReceivables;
