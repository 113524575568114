import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';

import { Grid, Container, Box, Tab, LinearProgress } from '@mui/material';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { GetLocalUser, HasAccountPermissions } from 'src/actions/AuthActions';
import { LessonsGetTypes, GetTutorLessons } from 'src/actions/LessonActions';

import { TutorGetTypes, GetTutor } from 'src/actions/TutorActions';
import TutorInfoCard from './TutorInfoCard';
import TutorLessonsTable from './TutorLessons';
import TutorPayments from './TutorPayments';
import { GetTutorPayments, PaymentsGetTypes } from 'src/actions/PaymentActions';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AvailabilityCalendar from './AvailabilityCalendar';
import DashboardMyStudents from '../MyStudent/MyStudents';
import StudentsTable from '../AllStudents/StudentsTable';
import WeeklyTutorHours from '../Reports/WeeklyTutorHours';
import WeeklyLessonHours from '../Reports/WeeklyLessonHours';
import WeeklyPayments from '../Reports/WeeklyPayments';

const TutorProfile = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  let userID: string = searchParams.get('user_id');

  const [paymentData, setPaymentData] = useState<PaymentsGetTypes>();
  const [lessonsData, setLessonsData] = useState<LessonsGetTypes>();
  const [tutorData, setTutorData] = useState<TutorGetTypes>();
  const [tabValue, setTabValue] = useState('1');

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Initial Load
  useEffect(() => {
    //If there is a user id in the url params
    if (userID != null) {
      var localUser = GetLocalUser();

      //Check if viewer is allowed to view
      if (
        HasAccountPermissions(['admin', 'manager']) ||
        localUser._id == userID
      ) {

      } else {
        navigate('/dashboards/main/');
      }


    }else{
      userID = GetLocalUser()._id;
    }

      //Get the student data
      dispatch<any>(GetTutor(userID, setTutorData));
      dispatch<any>(GetTutorLessons(setLessonsData, [userID]));
      dispatch<any>(GetTutorPayments(userID, setPaymentData));

  }, []);

  return (
    <>
      <Helmet>
        <title>
          {tutorData?.type === 'Success'
            ? tutorData.payload.first_name + ' ' + tutorData.payload.last_name
            : ''}
        </title>
      </Helmet>

      <Container sx={{ mt: 1 }} maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="stretch"
          spacing={3}
        >
          {lessonsData?.type != 'Success' && (
            <Grid item xs={12} md={12}>
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            </Grid>
          )}

          <Grid item xs={12} md={4}>
            {tutorData && tutorData.type == 'Success' && (
              <TutorInfoCard tutor={tutorData.payload} />
            )}

            {paymentData && paymentData.type == 'Success' && (
              <TutorPayments payments={paymentData.payload} tutor_id={userID} />
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleTabChange}>
                    <Tab label="Lessons" value="1" />
                    <Tab label="Students" value="2" />
                    <Tab label="Stats" value="3" />
                    <Tab label="Availability" value="4" />
                  </TabList>
                </Box>

                <TabPanel value="1">
                  <TutorLessonsTable lessons={lessonsData} />
                </TabPanel>
                <TabPanel value="4">
                  {tutorData?.type == 'Success' && (
                    <AvailabilityCalendar tutor={tutorData.payload} />
                  )}
                </TabPanel>
                <TabPanel value="2">
                  {tutorData?.type == 'Success' && (

                    <StudentsTable
                      referenceUser={tutorData.payload}
                      studentGroups={tutorData.payload.students}
                    />
                  )}
                  
                </TabPanel>
                <TabPanel value="3">
                  {lessonsData?.type == 'Success' && (
                    <>
                      <WeeklyLessonHours lessons={lessonsData.payload} />
                    </>
                  )}
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default TutorProfile;
