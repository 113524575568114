import { Payment } from 'src/models/payment';
import { FC } from 'react';
import { Lesson } from 'src/models/lesson';
import { Tutor } from 'src/models/tutor';
import Chart from 'react-apexcharts';
import { Box, Card, CardContent, Container, useTheme } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import React from 'react';
import { getWeekNumber } from 'src/tools/dateTools';
import { it } from 'date-fns/locale';
//import { LineChart } from '@mui/x-charts/LineChart';

interface TableProps {
  lessons: Lesson[];
}

// export interface paymentsStatData {
//   title: string,
//   dataLabel: string,
//   labels: string[],
//   income: number[],
//   outgoings: number[],
//   tutorPay: number[],
// }

const WeeklyLessonHours: FC<TableProps> = ({ lessons: lessons }) => {
  const theme = useTheme();

  function generateWeekNumbers(X: number): string[] {
    const result: string[] = [];
    for (let i = 1; i <= X; i++) {
      result.push(i.toString());
    }
    return result;
  }

  const chartOptions: ApexOptions = {
    chart: {
      background: 'transparent',
      foreColor: '#fff',
      toolbar: {
        show: false
      },

      zoom: {
        enabled: false
      }
    },
    fill: {
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.1,
        inverseColors: false,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [0, 100]
      }
    },
    colors: [ '#777777',theme.colors.primary.main],
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      colors: ['#777777',theme.colors.primary.main],
      width: 3
    },
    legend: {
      show: true,
      onItemHover: {
        highlightDataSeries: true
      },
    },
    labels: generateWeekNumbers(getWeekNumber(new Date())),

    xaxis: {
      labels: {
        show: true,
        style: {
          colors: '#ffffff',
        },
      },

      axisBorder: {
        show: true
      },
      axisTicks: {
        show: true
      }
    },
    yaxis: {
      min: 0,
      labels: {
        style: {
          colors: '#ffffff',
        },
      },
      show: true,
      tickAmount: 5
    },
    tooltip: {
      theme: 'dark',
      marker: {
        show: true,
      },
    },

  };

  function initializeDictionary(maxKey: number): Record<number, number> {
    const dictionary: Record<number, number> = {};

    for (let i = 1; i <= maxKey; i++) {
      dictionary[i] = 0;
    }

    return dictionary;
  }


  const GetWeeklyIncome = (filteredLessons: Lesson[], average: boolean = false): [number, number][] => {
    const currentWeek = getWeekNumber(new Date());

    const weekDict: Record<number, number> = initializeDictionary(currentWeek);

    filteredLessons.forEach((lesson) => {
      const paymentDate = new Date(lesson.date);
      const weekNumber = getWeekNumber(paymentDate);

      if (weekNumber in weekDict) {
        weekDict[weekNumber] += lesson.duration;
      }
    });
    
    // Convert to an array and sort by week number
    const sortedWeekArray = Object.entries(weekDict).map(([weekNumber, amount]) => [
      parseInt(weekNumber, 10),
      amount,
    ]).sort((a, b) => a[0] - b[0]) as [number, number][];
    return sortedWeekArray;
  };

const AverageResults = (data: [number, number][]) => {
  let averagedData: [number, number][] = [];

  data.forEach((array, index) => {

    const spread = 2;
    let sum = 0;

    const startIndex = Math.max(index - spread,0);
    const endIndex=  Math.min(index + spread, data.length-1);
    const valuesGot =  endIndex - startIndex;

    for (let i = startIndex; i < endIndex; i++) {
      sum += data[i][1];
    }

    let ave = Math.round(sum / valuesGot);

    averagedData.push([array[0] , ave]);
  });

  return averagedData;
}

  const chartData = [
    {
      name: "Hours Averaged",
      data: AverageResults(GetWeeklyIncome(lessons.filter(item => item.status == 'completed' )))
    },
    {
      name: "Hours",
      data: GetWeeklyIncome(lessons.filter(item => item.status == 'completed' ))
    }
  ];

  return (
    <>
      <Card>
        <CardContent >
          <Box >
            <h2>Weekly Hours</h2>

              <Chart
                options={chartOptions}
                series={chartData}
                type="area"
                height={300}
              />
          </Box>

        </CardContent>
      </Card>
    </>
  );
};


export default WeeklyLessonHours;
