import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import moment from 'moment-timezone';

export const monthsSinceDate = (date: Date) => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  return Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
};

export const isDateToday =(date: Date) =>{
  const otherDate = new Date(date);
  const todayDate = new Date();

  if (
    otherDate.getDate() === todayDate.getDate() &&
    otherDate.getMonth() === todayDate.getMonth() &&
    otherDate.getFullYear() === todayDate.getFullYear()
  ) {
    return true;
  } else {
    return false;
  }
}

export const isDateTomorrow = (date : Date) =>{
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  if (tomorrow.toDateString === date.toDateString) {
    return true;
  }

  return false;
}

export const isDateYesterday = (date : Date) =>{
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  if (yesterday.toDateString === date.toDateString) {
    return true;
  }

  return false;
}

export function areDatesOnSameDay(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // Compare year, month, and day parts
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}


export const isDateLessThanOrEqualToday =(date: Date) =>{

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  date =  new Date(date);
  return date < tomorrow;
}

export const todayTomorrowDate = (date: Date) : string =>{
  if(isDateToday(date)){
    return "Today";
  }
  if(isDateYesterday(date)){
    return "Yesterday";
  }
  if(isDateTomorrow(date)){
    return "Tomorrow";
  }

  return formatDateDayMonthYear(date);
}

export const  formatDateDayMonth = (string : Date) =>{
  return new Date(string).toLocaleDateString('en-AU', {
    month: '2-digit',
    day: '2-digit'
  });
}

// dd/mm/yyyy
export const  formatDateDayMonthYear = (string : Date) =>{
  return new Date(string).toLocaleDateString('en-AU', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
}

export function formatTime(date) {

  const convertedDate = new Date(date); 
  const showTime = convertedDate.getHours()
  + ':' + String(convertedDate.getMinutes()).padStart(2, "0");

  return showTime
}

// weekday day month
export const  formatDateWeekdayDayMonth = (string : Date) =>{
  return new Date(string).toLocaleDateString('en-AU', {
    month: 'short',
    day: '2-digit',
    weekday: 'long'
  });
}

// Weekday : time
export const formatDay = (string : Date) =>{
  return new Date(string).toLocaleDateString('en-AU', {
    hour: '2-digit',
    minute: '2-digit',
    weekday: 'long'
  });
}

export const getDaysPastDisplay = (date : Date, pretext: string = '') => {
  if (!date) {
    return <Typography color="grey">{pretext} - </Typography>;
  }

  const daysPast: number = daysSinceDate(date);

  let outputString = daysPast + ' Days';

  if (daysPast > 7) {
    outputString = '> Week';
  } else if (daysPast > 31) {
    outputString = '> Month';
  }

  if (daysPast > 5) {
    return <Typography color="primary">{pretext} {outputString} </Typography>;
  } else {
    return <Typography color="grey">{pretext} {outputString} </Typography>;
  }
};

export const IsLeapYear = (year : number) => {
  // Leap years are divisible by 4, but not by 100, except if divisible by 400.
  return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
}

export const GetDaysInYear = (year: number = GetFirstDayOfFinancialYear().getFullYear()) => {
  return IsLeapYear(year) ? 366 : 365;
}

export const GetFirstDayOfFinancialYear =(inputDate: Date = new Date()) => {
  // Define your financial year start month and day (e.g., April 1st).
  const financialYearStartMonth = 6; // 0-indexed (April)
  const financialYearStartDay = 1;

  // Get the year from the input date.
  const year = inputDate.getFullYear();

  // Calculate the financial year start date.
  if (inputDate.getMonth() < financialYearStartMonth || (inputDate.getMonth() === financialYearStartMonth && inputDate.getDate() < financialYearStartDay)) {
    // If the input date is before the financial year start, subtract 1 from the year.
    return new Date(year - 1, financialYearStartMonth, financialYearStartDay);
  } else {
    // Otherwise, use the current year.
    return new Date(year, financialYearStartMonth, financialYearStartDay);
  }
}

export const DateTimeFormatted = ({ date, timezone }) => {
  // Convert the date to the specified timezone
  const zonedDate = utcToZonedTime(date, timezone);

  // Format the date in the specified timezone
  const formattedDate = format(zonedDate, 'd MMM  h:mm aa');

  return <div>{formattedDate}</div>;
};

// Function to calculate week number
export const getWeekNumber = (date) => {
  const sofyDate = getStartOfFinancialYear();
  const timeDifference = date.getTime() - sofyDate.getTime();
  const daysSinceFirstJanuary = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
  const weekNumber = Math.ceil((daysSinceFirstJanuary + sofyDate.getDay() + 1) / 7);
  return weekNumber;
};

function getStartOfFinancialYear(): Date {
  // Get the current date
  const currentDate = new Date();

  // Determine the start month of the financial year (July)
  const financialYearStartMonth = 6;

  // If the current month is before the start month, consider the previous year
  const year = currentDate.getMonth() < financialYearStartMonth ? currentDate.getFullYear() - 1 : currentDate.getFullYear();

  // Create a new date object for the start of the financial year
  return new Date(year, financialYearStartMonth, 1);
}

export const daysSinceDate = (date: Date)=>{
  
      if(!date){
        return 0;
      }

      var Difference_In_Time = new Date().getTime() - new Date(date).getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      return Math.floor(Difference_In_Days);

}

export const checkTimezoneChanges = (timezone1, timezone2): boolean =>  {
  const timezone1Info = moment.tz.zone(timezone1);
  const timezone2Info = moment.tz.zone(timezone2);

  let willChangeSoon = false;
  let willChangeAtSameTime = false;

  if (timezone1Info && timezone2Info) {
    const now = moment();
    const twoWeeksFromNow = now.clone().add(2, 'weeks');

    if (timezone1Info.untils.some(change => moment.tz(change, timezone1).isBetween(now, twoWeeksFromNow))) {
      willChangeSoon = true;
    }
    if (timezone2Info.untils.some(change => moment.tz(change, timezone2).isBetween(now, twoWeeksFromNow))) {
      willChangeSoon = true;
    }

    const timezone1Changes = timezone1Info.untils.map(change => moment.tz(change, timezone1).format('YYYY-MM-DD'));
    const timezone2Changes = timezone2Info.untils.map(change => moment.tz(change, timezone2).format('YYYY-MM-DD'));

    willChangeAtSameTime = timezone1Changes.some(change => timezone2Changes.includes(change));
  }

  return willChangeSoon && !willChangeAtSameTime;
}


export const getTimezoneHourDifference = (timezone1, timezone2): number => {
  const now = moment();
  const offset1 = moment.tz(now, timezone1).utcOffset() / 60;
  const offset2 = moment.tz(now, timezone2).utcOffset() / 60;

  return offset1 - offset2;
}

export const getTimezoneFromValue = (value: string) => {
  return timezones[timezones.findIndex((timezone) => timezone.value === value)];
};

export const timezones = [
  { value: 'Australia/Sydney', label: 'Sydney (GMT+10:00)'},
  { value: 'Australia/Melbourne', label: 'Melbourne (GMT+10:00)' },
  { value: 'Australia/Brisbane', label: 'Brisbane (GMT+10:00)' },
  { value: 'Australia/Perth', label: 'Perth (GMT+08:00)' },
  { value: 'Australia/Adelaide', label: 'Adelaide (GMT+09:30)' },
  { value: 'Australia/Darwin', label: 'Darwin (GMT+09:30)' },
  { value: 'Australia/Hobart', label: 'Hobart (GMT+10:00)' },
  { value: 'Australia/Canberra', label: 'Canberra (GMT+10:00)' },
  { value: 'America/New_York', label: 'New York (GMT-04:00)' },
  { value: 'America/Los_Angeles', label: 'Los Angeles (GMT-07:00)' },
  { value: 'America/Chicago', label: 'Chicago (GMT-05:00)' },
  { value: 'America/Denver', label: 'Denver (GMT-06:00)' },
  { value: 'America/Phoenix', label: 'Phoenix (GMT-07:00)' },
  { value: 'America/Anchorage', label: 'Anchorage (GMT-08:00)' },
  { value: 'America/Honolulu', label: 'Honolulu (GMT-10:00)' },
  { value: 'America/Toronto', label: 'Toronto (GMT-04:00)' },
  { value: 'America/Montreal', label: 'Montreal (GMT-04:00)' },
  { value: 'America/Vancouver', label: 'Vancouver (GMT-07:00)' },
  { value: 'America/Edmonton', label: 'Edmonton (GMT-06:00)' },
  { value: 'Pacific/Auckland', label: 'Auckland (GMT+12:00)' },
  { value: 'Europe/London', label: 'London (GMT+01:00)' },
  { value: 'Europe/Dublin', label: 'Dublin (GMT+01:00)' },
  { value: 'Europe/Amsterdam', label: 'Amsterdam (GMT+02:00)' },
  { value: 'Europe/Paris', label: 'Paris (GMT+02:00)' },
  { value: 'Europe/Berlin', label: 'Berlin (GMT+02:00)' },
  { value: 'Europe/Rome', label: 'Rome (GMT+02:00)' },
  { value: 'Europe/Madrid', label: 'Madrid (GMT+02:00)' },
];

