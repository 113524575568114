import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Button,
  Divider,
  Card,
  CardContent,
  CardHeader,
  Box,
  useTheme,
  CircularProgress
} from '@mui/material';

import LockOpenIcon from '@mui/icons-material/LockOpen';
import SaveIcon from '@mui/icons-material/Save';
import LockIcon from '@mui/icons-material/Lock';
import { Tutor } from 'src/models/tutor';
import { useDispatch } from 'react-redux';
import {
  TutorSubmitTypes,
  UpdateTutorAvailability
} from 'src/actions/TutorActions';

const useStyles = makeStyles((theme) => ({
  calendarContainer: {
    padding: 2
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dayCell: {
    height: '80px',
    border: '1px solid #ccc',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 1
  },
  selectedDay: {
    backgroundColor: 'primary',
    color: 'secondary'
  },
  daysOfWeek: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  dayOfWeek: {
    flex: 1,
    textAlign: 'center',
    fontWeight: 'bold'
  }
}));

type AvailabilityCode = 'free' | 'maybe' | 'unavailable';

interface TimeSlot {
  availabilityCode: AvailabilityCode;
  notes: string;
}

interface CalProps {
  tutor: Tutor;
}
export type Dispatcher<S> = Dispatch<SetStateAction<S | undefined>>;

const AvailabilityCalendar: FC<CalProps> = ({ tutor: tutor }) => {
  const classes = useStyles();
  const [tutorSubmit, setTutorSubmit] = useState<TutorSubmitTypes>();
  const [calendarLocked, setCalendarLocked] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);
  const [currentState, setCurrentState] = useState('free');
  const [dayAvailability, setDayAvailability] = useState(
    tutor?.availability && tutor.availability.length > 0
      ? tutor.availability
      : [
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free',
          'free'
        ]
  );

  const theme = useTheme();
  const dispatch = useDispatch();

  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const GetBackgroundColor = (available: string) => {
    switch (available) {
      case 'free':
        return '#375550';
      case 'maybe':
        return '#5d663a';
      default:
        return '#3a3b66';
    }
  };

  const GetTextColor = (available: string) => {
    switch (available) {
      case 'free':
        return 'white';
      case 'maybe':
        return 'grey';
      default:
        return 'black';
    }
  };

  // Work out the style of the button
  const buttonStyle = (availability: string) => {
    return {
      backgroundColor: GetBackgroundColor(availability),
      color: GetTextColor(availability),
      height: 25,
      marginBottom: 3
    };
  };

  // Function to change a value at a specific index
  const ChangeValueAtIndex = (index) => {
    if (calendarLocked || tutorSubmit?.type == 'Submitting') {
      return;
    }

    setHasChanged(true);

    // Make a copy of the array
    const newArray = [...dayAvailability];

    // Modify the value at the specified index
    newArray[index] = currentState;

    // Update the state with the modified array
    setDayAvailability(newArray);
  };

  // Generate a time based on the index of the button
  const timeFromIndex = (index: number) => {
    return index + 7 + ':00';
  };

  // Unlock the calendar
  const unlockEvent = () => {
    setCalendarLocked(!calendarLocked);
  };

  const saveChanges = () => {
    dispatch<any>(
      UpdateTutorAvailability(tutor._id, dayAvailability, setTutorSubmit)
    );
  };

  useEffect(() => {
    if (tutorSubmit?.type == "Success") {
      setCalendarLocked(true);
      setHasChanged(false);
    }
  }, [tutorSubmit]);

  // Change the state of the calender
  const changeSate = (stateName) => {
    setCurrentState(stateName);
  };

  return (
    <Card>
      <CardHeader
        title="Availability"
        action={
          <>
            {!calendarLocked && tutorSubmit?.type != 'Submitting' && (
              <>
                <Button
                  variant={currentState == 'free' ? 'outlined' : 'contained'}
                  sx={{ backgroundColor: GetBackgroundColor('free') }}
                  name="free"
                  onClick={() => changeSate('free')}
                  style={{ height: 25, marginRight: 3, marginBottom: 3 }}
                >
                  Available
                </Button>
                <Button
                  variant={currentState == 'maybe' ? 'outlined' : 'contained'}
                  sx={{ backgroundColor: GetBackgroundColor('maybe') }}
                  name="maybe"
                  onClick={() => changeSate('maybe')}
                  style={{ height: 25, marginRight: 3, marginBottom: 3 }}
                >
                  Maybe
                </Button>
                <Button
                  variant={
                    currentState == 'unavailable' ? 'outlined' : 'contained'
                  }
                  sx={{ backgroundColor: GetBackgroundColor('unavailable') }}
                  name="unavailable"
                  onClick={() => changeSate('unavailable')}
                  style={{ height: 25, marginRight: 10, marginBottom: 3 }}
                >
                  Unavailable
                </Button>
              </>
            )}

            {calendarLocked ? (
              <Button
                onClick={unlockEvent}
                size="small"
                color="primary"
                startIcon={<LockIcon />}
              >
                Locked
              </Button>
            ) : (
              <>
                {hasChanged ? (
                  <>
                    {tutorSubmit?.type == 'Submitting' ? (
                      <CircularProgress size="1rem" />
                    ) : (
                      <Button
                        onClick={saveChanges}
                        size="small"
                        color="primary"
                        startIcon={<SaveIcon />}
                      >
                        Save Changes
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    onClick={unlockEvent}
                    size="small"
                    color="primary"
                    startIcon={<LockOpenIcon />}
                  >
                    UnLocked
                  </Button>
                )}
              </>
            )}
          </>
        }
      />
      <Divider />
      <CardContent>
        <div style={{ marginBottom: 20 }} className={classes.daysOfWeek}>
          {daysOfWeek.map((day, index) => (
            <div key={index} className={classes.dayOfWeek}>
              {day}
            </div>
          ))}
        </div>
        <Grid container spacing={2} paddingLeft={2}>
          {daysOfWeek.map((day, index) => (
            <Grid item xs={12 / 7} key={index}>
              <Grid container spacing={1}>
                {dayAvailability
                  .slice(16 * index, 16 * index + 16)
                  .map((slot, slotIndex) => (
                    <Button
                      variant="contained"
                      fullWidth
                      key={slotIndex}
                      onClick={() => ChangeValueAtIndex(16 * index + slotIndex)}
                      style={buttonStyle(slot)}
                    >
                      {timeFromIndex(slotIndex)}
                    </Button>
                  ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AvailabilityCalendar;
