import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { EmailTemplate } from '@/src/models/emailTemplate';
import { useDispatch } from 'react-redux';
import { Lead } from 'src/models/lead';



export type Dispatcher<S> = Dispatch<SetStateAction<S | undefined>>;

interface TableProps {
  emailTemplate: EmailTemplate;
  isOpen: boolean;
  setData: Dispatcher<boolean>;
  leads?: Lead[];
}

const HtmlPreviewDialog: FC<TableProps> = ({
  emailTemplate,
  isOpen,
  setData,
  leads
}) => {
  const [viewedLeadNumber, setViewedLeadNumber] = useState<number>(0);
  const handleClose = () => setData(false);

  const dispatch = useDispatch();

  console.log(emailTemplate);

  return (
    <div>
      {emailTemplate && (
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle>{emailTemplate.name}</DialogTitle>
          <DialogContent>
            <div
              dangerouslySetInnerHTML={{
                __html: emailTemplate.html
              }}
            />
          </DialogContent>
          <DialogActions>
            {/* <Box sx={{ textAlign: 'center' }}>
              <Button
                variant="outlined"
                disabled={viewedTemplateNumber === 0}
                onClick={() =>
                  setViewedTemplateNumber(viewedTemplateNumber - 1)
                }
              >
                Previous
              </Button>
              <Button
                sx={{ ml: 2 }}
                variant="outlined"
                disabled={
                  viewedTemplateNumber ===
                  emailTemplates[viewedTemplateNumber].html?.length
                }
                onClick={() =>
                  setViewedTemplateNumber(viewedTemplateNumber + 1)
                }
              >
                Next
              </Button>
            </Box>
            <Button onClick={handleClose}>Close</Button> */}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default HtmlPreviewDialog;
