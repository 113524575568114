import { FC, useEffect, useState } from 'react';
import {
  Tooltip,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  useTheme,
  Paper,
  tableCellClasses,
  Box,
  Collapse,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Checkbox,
  ListItemButton,
  ListItemText,
  Grid,
  CardContent,
  CardHeader,
  Divider,
  TableFooter,
  CircularProgress,
  CardActionArea
} from '@mui/material';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import PaidSharpIcon from '@mui/icons-material/PaidSharp';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Lesson } from 'src/models/lesson';
import {
  formatDateDayMonthYear,
  formatDateWeekdayDayMonth,
  formatDateDayMonth,
  formatDay
} from 'src/tools/dateTools';
import React from 'react';
import { DownloadInvoiceButton } from '../../applications/Reports/StudentInvoiceButton';
import { Student } from 'src/models/student';
import { DatePicker, DesktopDatePicker } from '@mui/lab';
import { setDate } from 'date-fns';
import { GetLocalUser } from 'src/actions/AuthActions';
import { LessonSubmitTypes, PayTutor } from 'src/actions/LessonActions';
import { useDispatch } from 'react-redux';
import { Tutor } from 'src/models/tutor';
import { EditLessonDialogue } from '../Receivables/EditLessonDialogue';
import PurchaseCreditsButton from '../Receivables/PurchaseCreditsButton';
import { ColoredCircle } from 'src/tools/formatting';
import {
  getStudentFullName,
  getStudentIDAndFullName
} from 'src/tools/stringTools';
import ReactDOMServer from 'react-dom/server';
import { SendPayrollEmail } from 'src/actions/EmailActions';
import { StyledTableCell } from 'src/theme/stylings';

interface TableProps {
  className?: string;
  lessons: Lesson[];
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(4n+1)': {
    backgroundColor: '#182440'
  },
  '&:nth-of-type(4n+2)': {
    backgroundColor: '#4b4b4b'
  },
  '&:nth-of-type(4n+3)': {
    backgroundColor: '#202b45'
  },
  '&:nth-of-type(4n+4)': {
    backgroundColor: '#4b4b4b'
  },

  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const StyledTableRowSub = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#182440'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#263352'
  },

  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

interface LessonGroup {
  tutor: Tutor;
  name: string;
  total_hours: number;
  total_invoiced: number;
  lessonList: Lesson[];
  nonCompleteLessonList: Lesson[];
}

const UnpaidTutorsTable: FC<TableProps> = ({ lessons: lessons }) => {
  //Edit Lesson stuff
  const [openEditLessonDialogue, setOpenEditLessonDialogue] = useState(false);
  const [editLessonLesson, setEditLessonLesson] = useState<Lesson>();

  const selectLesson = (lesson: Lesson) => {
    setOpenEditLessonDialogue(true);
    setEditLessonLesson(lesson);
  };

  const [openRows, setOpenRows] = useState([]);
  const [saveLessonData, setSaveLessonData] = useState<LessonSubmitTypes>();
  const [selectedLessonGroup, setSelectedLessonGroup] = useState<LessonGroup>();
  const [openDialogue, setOpenDialogue] = useState(false);
  const [lessonsChecked, setLessonChecked] = useState([]);
  const [studentLessons, setStudentLessons] = useState('');

  const [paymentDate, setPaymentDate] = useState(new Date());
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const [paymentNote, setPaymentNote] = useState('');
  const [paymentReceiptNumber, setPaymentReceiptNumber] = useState('');
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<{
    date: string;
    note: string;
    receiptNumber: string;
    amountPaid: string;
  }>({
    date: '',
    note: '',
    receiptNumber: '',
    amountPaid: ''
  });

  const updateErrorState = (field, message) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: message
    }));
  };

  const navigate = useNavigate();
  const color = '#ffffff';
  const theme = useTheme();

  const onPaymentValueChange = (event) => {
    setTotalPaymentAmount(event.target.value);
  };

  const onNoteChange = (event) => {
    setPaymentNote(event.target.value);
  };

  const onReceiptNumberChange = (event) => {
    setPaymentReceiptNumber(event.target.value);
  };

  //Validate form
  const handleValidation = () => {
    let formIsValid = true;
    const totalPay = calculateTotalPaymentAmount();

    if (lessonsChecked.length == 0) {
      formIsValid = false;
      updateErrorState('amountPaid', 'There are no lessons checked');
    } else if (!paymentDate || paymentDate > new Date()) {
      formIsValid = false;
      updateErrorState('date', "Date can't be in the future");
    } else if (!paymentReceiptNumber || paymentReceiptNumber.length < 5) {
      formIsValid = false;
      updateErrorState('receiptNumber', 'Please enter a receipt number');
    } else if (totalPaymentAmount > totalPay) {
      formIsValid = false;
      updateErrorState(
        'amountPaid',
        "You can't pay more than what is invoiced"
      );
    } else if (totalPaymentAmount < totalPay && paymentNote.length < 5) {
      formIsValid = false;
      updateErrorState(
        'note',
        'Please enter a note why the payment is for less money than the invoiced amounts'
      );
    } else {
      updateErrorState('date', '');
      updateErrorState('receiptNumber', '');
      updateErrorState('amountPaid', '');
      updateErrorState('note', '');
    }

    return formIsValid;
  };

  // Toggle selecting lessons
  const handleToggleLesson = (value: string) => () => {
    const currentIndex = lessonsChecked.indexOf(value);
    const newChecked = [...lessonsChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setLessonChecked(newChecked);
  };

  const GenerateHtmlTable = () => {
    // Calculate total duration and total cost
    const totalDuration = selectedLessonGroup.lessonList
      .filter((obj) => lessonsChecked.includes(obj._id))
      .reduce((acc, lesson) => acc + lesson.duration, 0);

    const totalCost = selectedLessonGroup.lessonList
      .filter((obj) => lessonsChecked.includes(obj._id))
      .reduce(
        (acc, lesson) => acc + lesson.duration * lesson.tutor_hourly_rate,
        0
      );

    // Construct the HTML content
    const htmlContent = `
      <html>
        <head>
          <style>
            /* Define general styles here if needed */
          </style>
        </head>
        <body>
          <!-- Specific table with inline styles -->
          <table style="width: 100%; border-collapse: collapse; border: 1px solid #ddd;">
            <thead>
              <tr style="background-color: #f2f2f2; text-align: left;">
                <th style="padding: 8px;">Date</th>
                <th style="padding: 8px;">Student</th>
                <th style="padding: 8px;">Hourly Rate</th>
                <th style="padding: 8px;">Duration</th>
                <th style="padding: 8px;">Total</th>
              </tr>
            </thead>
            <tbody>
              ${selectedLessonGroup.lessonList
                .filter((obj) => lessonsChecked.includes(obj._id))
                .map(
                  (lesson) => `
                  <tr>
                    <td style="padding: 8px;">${formatDateDayMonth(
                      lesson.date
                    )}</td>
                    <td style="padding: 8px;">${getStudentFullName(
                      lesson.student
                    )}</td>
                    <td style="padding: 8px;">$${lesson.tutor_hourly_rate}</td>
                    <td style="padding: 8px;">${lesson.duration}</td>
                    <td style="padding: 8px;">$${
                      lesson.tutor_hourly_rate * lesson.duration
                    }</td>
                  </tr>
                `
                )
                .join('')}
            </tbody>
            <tfoot>
              <tr>
                <td colspan="3" style="text-align: right; font-weight: bold; padding: 8px;">Total:</td>
                <td style="font-weight: bold; padding: 8px;">${totalDuration}</td>
                <td style="font-weight: bold; padding: 8px;">$${totalCost}</td>
              </tr>
            </tfoot>
          </table>
        </body>
      </html>
    `;

    return htmlContent;
  };

  const handleSubmitDialogue = () => {
    if (!handleValidation()) {
      return;
    }

    const postData = {
      paid_date: paymentDate,
      payment_amount: totalPaymentAmount,
      receipt_number: paymentReceiptNumber,
      processed_by: GetLocalUser()._id,
      note: paymentNote,
      tutor: selectedLessonGroup.tutor,
      lessons: lessonsChecked
    };

    const htmlString = GenerateHtmlTable();

    const totalCost = selectedLessonGroup.lessonList
      .filter((obj) => lessonsChecked.includes(obj._id))
      .reduce((acc, lesson) => acc + lesson.duration * lesson.tutor_hourly_rate, 0);

    dispatch<any>(PayTutor(postData, setSaveLessonData));

    dispatch<any>(
      SendPayrollEmail(
        selectedLessonGroup.tutor,
        htmlString,
        formatDateDayMonthYear(new Date()),
        '$' + totalCost.toString()
      )
    );
  };

  useEffect(() => {
    if (saveLessonData?.type === 'Success') {
      window.location.reload();
    }
  }, [saveLessonData]);

  // On and close dialogue
  const handleOpenDialogue = (lessonGroup: LessonGroup) => {
    setSelectedLessonGroup(lessonGroup);

    setLessonChecked(generateArrayOfLessonIds(lessonGroup.tutor._id));
    setOpenDialogue(true);

    setStudentLessons(lessonGroup.tutor._id);
    updateErrorState('date', '');
    updateErrorState('receiptNumber', '');
    updateErrorState('amountPaid', '');
    updateErrorState('note', '');
    setPaymentReceiptNumber('');
    setPaymentNote('');
    setPaymentDate(new Date());
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  // Generate initial array of lesson ids
  function generateArrayOfLessonIds(tutor_id: string) {
    return lessons
      .filter(
        (lesson) =>
          !lesson.tutor_paid_date &&
          lesson.tutor._id == tutor_id 
      )
      .sort((a, b) => (a.date < b.date ? 1 : -1))
      .map((lesson) => lesson._id);
  }

  // Detect change of selected lessons
  useEffect(() => {
    setTotalPaymentAmount(calculateTotalPaymentAmount());
  }, [lessonsChecked]);

  // Calculate total hours from selected lessons
  function calculateTotalPaymentAmount() {
    return lessonsChecked.reduce((total, lessonId) => {
      const checkedLesson: Lesson = lessons.find(
        (lesson) => lesson._id === lessonId
      );
      return (
        total +
        (checkedLesson
          ? checkedLesson.duration * checkedLesson.tutor_hourly_rate
          : 0)
      );
    }, 0);
  }

  // Calculate total hours from selected lessons
  function calculateTotalHours() {
    return lessonsChecked.reduce((total, lessonId) => {
      const checkedLesson: Lesson = lessons.find(
        (lesson) => lesson._id === lessonId
      );
      return total + (checkedLesson ? checkedLesson.duration : 0);
    }, 0);
  }

  // Sort the lessons by date order
  const sortedLessonList = selectedLessonGroup
    ? selectedLessonGroup.lessonList.slice().sort((a, b) => {
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;
        return 0;
      })
    : [];

  // Filter only completed lessons that have not been paid
  const filteredLessons = lessons.filter(
    (lesson) => lesson.status == 'completed' && !lesson.tutor_paid_date
  );

  // On clicking a row to open it
  const openRowClick = (rowID: string) => {
    if (openRows.includes(rowID)) {
      setOpenRows((prevArray) => prevArray.filter((item) => item !== rowID));
      return;
    }
    setOpenRows((prevArray) => [...prevArray, rowID]);
  };

  //Each sub row
  const Row = (lessonGroup: LessonGroup) => {
    return (
      <React.Fragment>
        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
            <IconButton
              aria-label="expand row"
              size="small"
              color="primary"
              onClick={() => openRowClick(lessonGroup.tutor._id)}
            >
              {openRows.includes(lessonGroup.tutor._id) ? (
                <KeyboardArrowDownSharpIcon />
              ) : (
                <KeyboardArrowRightSharpIcon />
              )}
            </IconButton>
          </TableCell>
          <TableCell>
            <Link
              style={{ color: 'white', textDecoration: 'none' }}
              to={'/management/tutor?user_id=' + lessonGroup.tutor._id}
            >
              {lessonGroup.tutor.first_name} {lessonGroup.tutor.last_name}
            </Link>{' '}
          </TableCell>

          <TableCell align="right">
            {lessonGroup.nonCompleteLessonList.length >0 && (
              <ColoredCircle>
                {lessonGroup.nonCompleteLessonList.length}
              </ColoredCircle>
            )}
          </TableCell>
          <TableCell align="right">{lessonGroup.lessonList.length}</TableCell>
          <TableCell align="right">{lessonGroup.total_hours}</TableCell>
          <TableCell align="right">${lessonGroup.total_invoiced}</TableCell>
          <TableCell>
            <Tooltip title={'Pay Tutor'} arrow>
              <IconButton
                sx={{
                  '&:hover': {
                    background: theme.colors.primary.lighter
                  },
                  color: '#40ba50'
                }}
                color="inherit"
                size="small"
                onClick={() => handleOpenDialogue(lessonGroup)}
              >
                <LocalAtmIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </TableCell>
        </StyledTableRow>
        <StyledTableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse
              in={openRows.includes(lessonGroup.tutor._id)}
              timeout="auto"
              unmountOnExit
            >
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>Date</StyledTableCell>
                      <StyledTableCell>Student</StyledTableCell>
                      <StyledTableCell>Lesson Type</StyledTableCell>
                      <StyledTableCell align="right">Duration</StyledTableCell>
                      <StyledTableCell align="right">
                        Hourly Rate
                      </StyledTableCell>
                      <StyledTableCell align="right">Total</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lessonGroup.lessonList.map((lesson, index) => (
                      <StyledTableRowSub key={index}>
                        <StyledTableCell>
                          <Tooltip title={'id: ' + lesson._id} arrow>
                            <IconButton
                              sx={{
                                '&:hover': {
                                  background: theme.colors.primary.lighter
                                },
                                color: 'grey'
                              }}
                              color="inherit"
                              size="small"
                            >
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {formatDateDayMonthYear(lesson.date)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {getStudentIDAndFullName(lesson.student)}
                        </StyledTableCell>
                        <StyledTableCell>{lesson.type}</StyledTableCell>
                        <StyledTableCell align="right">
                          {lesson.duration}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          ${lesson.tutor_hourly_rate}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          ${lesson.duration * lesson.tutor_hourly_rate}
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            sx={{
                              '&:hover': {
                                background: theme.colors.primary.lighter
                              },
                              color: 'grey'
                            }}
                            color="inherit"
                            size="small"
                            onClick={() => selectLesson(lesson)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRowSub>
                    ))}
                    {lessonGroup.nonCompleteLessonList.length > 0 && (
                      <StyledTableRowSub>
                        <StyledTableCell>Non Complete lessons</StyledTableCell>{' '}
                      </StyledTableRowSub>
                    )}
                    {lessonGroup.nonCompleteLessonList.map((lesson, index) => (
                      <StyledTableRowSub key={index}>
                        <StyledTableCell>
                          <Tooltip title={'id: ' + lesson._id} arrow>
                            <IconButton
                              sx={{
                                '&:hover': {
                                  background: theme.colors.primary.lighter
                                },
                                color: 'grey'
                              }}
                              color="inherit"
                              size="small"
                            >
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {formatDateDayMonthYear(lesson.date)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {lesson.student.user_id} - {lesson.student.first_name}{' '}
                          {lesson.student.last_name}
                        </StyledTableCell>
                        <StyledTableCell colSpan={4}></StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            sx={{
                              '&:hover': {
                                background: theme.colors.primary.lighter
                              },
                              color: 'grey'
                            }}
                            color="inherit"
                            size="small"
                            onClick={() => selectLesson(lesson)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRowSub>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  };

  // Turn lesson list into lesson groups for the table
  const transformedData: LessonGroup[] = filteredLessons.reduce(
    (result: LessonGroup[], lesson: Lesson) => {
      const existingLessonGroup = result.find(
        (lessonGroup) => lessonGroup.tutor._id == lesson.tutor._id
      );

      if (existingLessonGroup) {
        if (lesson.status == 'booked' && lesson.date < new Date()) {
          existingLessonGroup.nonCompleteLessonList.push(lesson);
        } else if (lesson.status == 'completed') {
          existingLessonGroup.lessonList.push(lesson);
          existingLessonGroup.total_hours += lesson.duration;
          existingLessonGroup.total_invoiced +=
            lesson.duration * lesson.tutor_hourly_rate;
        }
      } else {
        result.push({
          tutor: lesson.tutor,
          name: lesson.student.first_name + ' ' + lesson.student.last_name,
          total_hours: lesson.status == 'booked' ? 0 : lesson.duration,
          total_invoiced:
            lesson.status == 'booked'
              ? 0
              : lesson.duration * lesson.tutor_hourly_rate,
          lessonList: lesson.status == 'booked' ? [] : [lesson],
          nonCompleteLessonList:
            lesson.status == 'booked' && lesson.date < new Date()
              ? [lesson]
              : []
        });
      }

      return result;
    },
    []
  );

  return (
    <>
      <Card sx={{ marginTop: '10px' }}>
        <CardHeader
          title="Unpaid Tutors"
          action={
            <a href="https://www.airwallex.com/app1/login?redirect=/app1/dashboard" target="_blank" rel="noreferrer">
              <Button variant="outlined" color="primary">
                Banking
              </Button>
            </a>
          }
        />

        <Divider />
        <CardContent>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell />
                  <StyledTableCell>Tutor</StyledTableCell>
                  <StyledTableCell align="right">
                    Outstanding Lessons
                  </StyledTableCell>
                  <StyledTableCell align="right">Lesson Count</StyledTableCell>
                  <StyledTableCell align="right">Total Hours</StyledTableCell>
                  <StyledTableCell align="right">Total</StyledTableCell>
                  <StyledTableCell />
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {transformedData.map((row, index) => (
                  <Row
                    key={index}
                    tutor={row.tutor}
                    name={row.name}
                    total_hours={row.total_hours}
                    total_invoiced={row.total_invoiced}
                    nonCompleteLessonList={row.nonCompleteLessonList}
                    lessonList={row.lessonList}
                  ></Row>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell align="right">Total:</StyledTableCell>
                  <StyledTableCell align="right">
                    {transformedData.reduce(
                      (a, b) => a + b.lessonList.length,
                      0
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {transformedData.reduce((a, b) => a + b.total_hours, 0)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    ${transformedData.reduce((a, b) => a + b.total_invoiced, 0)}
                  </StyledTableCell>
                  <StyledTableCell />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <Dialog fullWidth open={openDialogue} onClose={handleCloseDialogue}>
        <DialogTitle>
          {selectedLessonGroup
            ? 'Pay Tutor:' +
              selectedLessonGroup.tutor.first_name +
              ' ' +
              selectedLessonGroup.tutor.last_name
            : 'Loading'}
        </DialogTitle>
        <DialogContent>
          {selectedLessonGroup ? (
            <>
              <Grid container spacing={0}>
                <Grid item xs={8} container alignItems="center"></Grid>
                <Grid item xs={4}>
                  <DatePicker
                    label="Lesson Date"
                    onChange={setPaymentDate}
                    value={paymentDate}
                    PaperProps={{
                      sx: {
                        svg: { color: 'white' },
                        span: { color: 'white' }
                      }
                    }}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          style={{ marginRight: '10px' }}
                          sx={{
                            svg: { color }
                          }}
                          margin="dense"
                          id="date"
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <List>
                    {sortedLessonList.map((lesson, index) => (
                      <ListItem
                        key={index}
                        onClick={handleToggleLesson(lesson._id)}
                        secondaryAction={
                          <Checkbox
                            edge="end"
                            onChange={handleToggleLesson(lesson._id)}
                            checked={lessonsChecked.includes(lesson._id)}
                          />
                        }
                        disablePadding
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? '#3e4d70' : '#2b3857' // Alternate background colors
                        }}
                      >
                        <ListItemButton>
                          <ListItemText
                            id="0"
                            primary={
                              lesson.student.user_id +
                              ' - ' +
                              lesson.student.first_name +
                              ' ' +
                              lesson.student.last_name
                            }
                            secondary={
                              formatDateDayMonth(lesson.date) +
                              ' : ' +
                              lesson.duration +
                              ' hours - total: $' +
                              lesson.duration * lesson.tutor_hourly_rate
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item xs={4} container alignItems="center"></Grid>
                <Grid item xs={4} container alignItems="center">
                  <Typography variant="h3" align="right">
                    Total Hours: {calculateTotalHours()}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    margin="dense"
                    id="amount"
                    name="amount"
                    value={totalPaymentAmount}
                    error={errors.amountPaid.length > 0}
                    helperText={
                      errors.amountPaid.length > 0 ? errors.amountPaid : ''
                    }
                    onChange={onPaymentValueChange}
                    label="Payment Amount"
                    type="number"
                    fullWidth={true}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="receipt"
                    required={true}
                    name="receipt"
                    label="receipt Number"
                    type="text"
                    fullWidth={true}
                    onChange={onReceiptNumberChange}
                    value={paymentReceiptNumber}
                    error={errors.receiptNumber.length > 0}
                    helperText={
                      errors.receiptNumber.length > 0
                        ? errors.receiptNumber
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    id="notes"
                    label="Notes"
                    type="text"
                    name="notes"
                    multiline={true}
                    onChange={onNoteChange}
                    value={paymentNote}
                    error={errors.note.length > 0}
                    helperText={errors.note.length > 0 ? errors.note : ''}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          {saveLessonData?.type == 'Submitting' ? (
            <Box
              sx={{
                position: 'fixed',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%'
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress size={64} disableShrink thickness={3} />
            </Box>
          ) : (
            <>
              {' '}
              <Button onClick={handleCloseDialogue}>Cancel</Button>
              <Button onClick={handleSubmitDialogue}>Confirm</Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <EditLessonDialogue
        isOpen={openEditLessonDialogue}
        lesson={editLessonLesson}
        onClose={setOpenEditLessonDialogue}
        isAdmin={true}
      />
    </>
  );
};

export default UnpaidTutorsTable;
