import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Alert } from "@mui/material";
import { FC, useState } from "react";
import PasswordIcon from '@mui/icons-material/Password';
import { isValid } from "date-fns";
import { useDispatch } from "react-redux";
import { GetLocalUser } from "src/actions/AuthActions";
import { ResetPassword, TutorGetTypes } from "src/actions/TutorActions";


export type UpdatePasswordProps = {
    tutorId: string;
    canClose: boolean;
};

export const UpdatePassword: FC<UpdatePasswordProps> = ({ canClose: canClose, tutorId: tutorId }) => {
    const [open, setOpen] = useState(false);
    const [saveTutorType, setSaveTutorType] = useState<TutorGetTypes>();

    const dispatch = useDispatch();

    //Form Data
    const [formData, setFormData] = useState({
        old_password: '',
        new_password1: '',
        new_password2: '',
    });

    const [errors, setErrors] = useState<{
        old_password: string;
        new_password1: string;
        new_password2: string;
    }>({
        old_password: '',
        new_password1: '',
        new_password2: '',
    });

    // This function is called when the input changes
    const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    };


    const updateErrorState = (field, message) => {
        setErrors(prevErrors => ({
            ...prevErrors,
            [field]: message
        }));
    };

    const handleClickOpen = () => {
        setSaveTutorType(null);
        updateErrorState("new_password1", "");
        updateErrorState("new_password2", "");
        updateErrorState("old_password", "");
        setOpen(true);
    };

    const handleClose = () => {
        if (!canClose) {
            return;
        }

        setOpen(false);
    };


    const handleSubmit = () => {

        if (!validateForm()) {
            return;
        }

        dispatch<any>(ResetPassword(tutorId, formData.new_password1, formData.old_password, setSaveTutorType));
    };

    const validateForm = (): boolean => {
        let formIsValid = true;

        if (formData.old_password.length == 0) {
            formIsValid = false;
            updateErrorState("old_password", "Please enter your old password");
        } else {
            updateErrorState("old_password", "");
        }

        // Check if new password is valid
        if (formData.new_password1.length < 6) {
            formIsValid = false;
            updateErrorState("new_password1", "New password needs to be 7 or more chars long");
        } else if ((formData.new_password1.match(/[A-Z]/g) || []).length < 1) {
            formIsValid = false;
            updateErrorState("new_password1", "New password needs at lease one capital letter");
        } else if ((formData.new_password1.match(/[a-z]/g) || []).length < 1) {
            formIsValid = false;
            updateErrorState("new_password1", "New password needs at lease one lower case letter");
        } else if ((formData.new_password1.match(/\d/g) || []).length < 1) {
            formIsValid = false;
            updateErrorState("new_password1", "New password needs at lease one number");
        } else if (formData.new_password1 != formData.new_password2) {
            formIsValid = false;
            updateErrorState("new_password1", "New passwords need to match");
            updateErrorState("new_password2", "New passwords need to match");
        } else {
            updateErrorState("new_password1", "");
            updateErrorState("new_password2", "");
        }

        return formIsValid;
    }

    return (<>

        <Button onClick={handleClickOpen} color='info' size="small" startIcon={<PasswordIcon />} >Password</Button>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="old_password"
                    name="old_password"
                    label="Old Password"
                    type="password"
                    error={errors.old_password.length > 0}
                    helperText={errors.old_password.length > 0 ? errors.old_password : ''}
                    onChange={onChanged}
                    fullWidth={true}
                />
                <br />
                <TextField
                    margin="dense"
                    id="new_password1"
                    name="new_password1"
                    label="New Password"
                    type="password"
                    error={errors.new_password1.length > 0}
                    helperText={errors.new_password1.length > 0 ? errors.new_password1 : ''}
                    onChange={onChanged}
                    fullWidth={true}
                />
                <br />
                <TextField
                    margin="dense"
                    id="new_password2"
                    name="new_password2"
                    label="Repeat New Password"
                    type="password"
                    error={errors.new_password2.length > 0}
                    helperText={errors.new_password2.length > 0 ? errors.new_password2 : ''}
                    onChange={onChanged}
                    fullWidth={true}
                />
                {saveTutorType?.type == "Success" &&
                    <Alert variant="filled" severity="success">
                        Password Updated
                    </Alert>
                }

            </DialogContent>
            <DialogActions>

                {saveTutorType?.type != "Success" ?
                    <>
                        {canClose && <Button onClick={handleClose}>Cancel</Button>}
                        <Button onClick={handleSubmit}>Update Password</Button>
                    </>
                    :
                    <>
                        {canClose && <Button onClick={handleClose}>Close</Button>}
                    </>
                }
            </DialogActions>
        </Dialog>

    </>)
}