import { formatDateDayMonthYear } from 'src/tools/dateTools';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  Box,
  CircularProgress
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Dispatcher, SUBMITTING, SUCCESS } from 'src/actions/LoadingActions';
import {
  LessonSubmitTypes,
  SetLessonsInvoiced
} from 'src/actions/LessonActions';
import { useDispatch } from 'react-redux';
import { getStudentIDAndFullName } from 'src/tools/stringTools';
import { EmailSubmitTypes, SendInvoiceEmail, SendInvoiceEmails } from 'src/actions/EmailActions';
import { LessonGroup } from './UnPaidLessonsTable';
import { Student } from 'src/models/student';
import { isLocalhost } from 'src/serviceWorker';

export type SendInvoiceDialogueProps = {
  showDialogue: boolean;
  lessonGroups: LessonGroup[];
  onClose: Dispatcher<boolean>;
  onSendEmail: Dispatcher<string>;
};

export const SendInvoiceDialogue: FC<SendInvoiceDialogueProps> = ({
  showDialogue: showDialogue,
  lessonGroups: lessonGroups,
  onClose: onClose,
  onSendEmail: onSendEmail
}) => {
  const dispatch = useDispatch();

  // const [emailSentData, setEmailSentData] = useState<string>();
  const [submitEmailData, setSubmitEmailData] = useState<EmailSubmitTypes>();

  useEffect(() => {
    if(submitEmailData?.type == 'Success'){
      handleCloseDialogue();
    }
  }, [submitEmailData]);

  /**
   * Send invoice to the student
   * Dispatch the SendInvoiceEmail and SetLessonsInvoiced actions
   */
  const handleSendInvoice = () => {
    if (
      submitEmailData?.type === SUBMITTING
    ) {
      return;
    }

    const emailDataArray = lessonGroups.map((lessonGroup) => {
      const { email, email_name } = GetInvoiceEmail(lessonGroup.student);
      
      const htmlString = GenerateHtmlTable(lessonGroup);

      const data = {
        /** Email name */
        name: email_name,
        /** Email recipient */
        to: email,
        /** Student full name */
        studentName: getStudentIDAndFullName(lessonGroup.student),
        /** Student number */
        studentNumber: lessonGroup.student.user_id,
        /** Additional info to send in the email */
        additionalInfo: lessonGroup.student.additional_invoice_details ?? '',
        /** Current date */
        date: formatDateDayMonthYear(new Date()),
        /** Reference for the invoice */
        reference: `${lessonGroup.student.user_id} - ${lessonGroup.student.first_name}`,
        /** Html table of the lessons to be invoiced */
        table: htmlString,

        studentId: lessonGroup.student._id,

        lessonIds: lessonGroup.lessonList.map((lesson) => lesson._id),
      };
      return data;
    });
    
    dispatch<any>(SendInvoiceEmails(emailDataArray,setSubmitEmailData,onSendEmail));
  };

  /**
   * Closes the dialogue
   */
  function handleCloseDialogue(): void {
    setSubmitEmailData(null);
    onClose(false);
  }

  /**
   * Interface for returning the email and name to send an invoice to
   */
  interface EmailReturn {
    email: String;
    email_name: String;
  }

  /**
   * Get the correct email to send the invoice to
   * @param {Student} student - The student to get the email for
   * @returns {EmailReturn} Object containing the email and name to send to
   */
  const GetInvoiceEmail = (student: Student): EmailReturn => {

    // if (isLocalhost){
    //   return { email: "ben@aptetut.com", email_name: student.first_name };
    // }

    if (student.invoice_email) {
      // If the student has an invoice email set, use that
      return { email: student.invoice_email, email_name: '' };
    }

    if (student.parents && student.parents.length > 0) {
      // If the student has parents set, use the first parents email
      return {
        email: student.parents[0].email,
        email_name: student.parents[0].first_name
      };
    }

    // Otherwise use the student's email
    return { email: student.email, email_name: student.first_name };
  };

  // Generate the html table
  const GenerateHtmlTable = (lessonGroup: LessonGroup) => {
    // Helper function to format date
    const formatDate = (date) => {
      // Implement your date formatting logic here
      return new Date(date).toLocaleDateString(); // Example formatting
    };

    // Calculate total duration and total cost
    const totalDuration = (lessonGroup: LessonGroup) =>
      lessonGroup.lessonList.reduce((acc, lesson) => acc + lesson.duration, 0);

    const totalCost = (lessonGroup: LessonGroup) =>
      lessonGroup.lessonList.reduce(
        (acc, lesson) => acc + lesson.duration * lesson.hourly_rate,
        0
      );

    // Construct the HTML content
    const htmlContent = (lessonGroup: LessonGroup) => `
      <html>
        <head>
          <style>
            /* Define general styles here if needed */
          </style>
        </head>
        <body>
          <!-- Specific table with inline styles -->
          <table style="width: 100%; border-collapse: collapse; border: 1px solid #ddd;">
            <thead>
              <tr style="background-color: #f2f2f2; text-align: left;">
                <th style="padding: 8px;">Date</th>
                <th style="padding: 8px;">${lessonGroup.student.ndis_student?'Mentor':'Tutor'}</th>
                <th style="padding: 8px;">Rate</th>
                <th style="padding: 8px;">Hours</th>
                <th style="padding: 8px;">Total</th>
              </tr>
            </thead>
            <tbody>
              ${lessonGroup.lessonList
                .map(
                  (lesson) => `
                  <tr>
                    <td style="padding: 8px;">${formatDate(lesson.date)}</td>
                    <td style="padding: 8px;">${lesson.tutor.first_name} ${lesson.tutor.last_name[0]}</td>
                    <td style="padding: 8px;">$${lesson.hourly_rate} p/h</td>
                    <td style="padding: 8px;">${lesson.duration}</td>
                    <td style="padding: 8px;">$${
                      lesson.hourly_rate * lesson.duration
                    }</td>
                  </tr>
                `
                )
                .join('')}
            </tbody>
            <tfoot>
              <tr>
                <td colspan="3" style="text-align: right; font-weight: bold; padding: 8px;">Total:</td>
                <td style="font-weight: bold; padding: 8px;">${totalDuration(lessonGroup)}</td>
                <td style="font-weight: bold; padding: 8px;">$${totalCost(lessonGroup)}</td>
              </tr>
            </tfoot>
          </table>
        </body>
      </html>
    `;

    return htmlContent(lessonGroup);
  };

  return (
    <Dialog
      open={showDialogue} // The dialog is only open when a lessonGroup is selected
      onClose={handleCloseDialogue}
      aria-labelledby="send-invoice-dialog-title"
    >
      <DialogTitle id="send-invoice-dialog-title">
        Send Invoice Email:
      </DialogTitle>
      <DialogContent>
        {/* Display the student ID and full name for reference */}
        {lessonGroups?.length > 1 ? (
          <> {lessonGroups.length} students selected</>
        ) : (
          <>
            {lessonGroups[0] &&
              getStudentIDAndFullName(lessonGroups[0]?.student)}
          </>
        )}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box
              sx={{
                // Use material-ui's Box component to display a container with some spacing
                m: 1,
                position: 'relative'
              }}
            >
              <>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleSendInvoice}
                // Disable the button if the email is being sent
                disabled={submitEmailData?.type === SUBMITTING}
              >
                Send Invoice Email
              </Button>
              {/* If the email is being sent, display a loading spinner */}
              {submitEmailData?.type === SUBMITTING || submitEmailData?.type === SUCCESS && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: 'white',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px'
                  }}
                />
              )}
              </>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          color="error"
          variant="contained"
          onClick={handleCloseDialogue}
          // Disable the button if the email is being sent
          disabled={submitEmailData?.type === SUBMITTING}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
