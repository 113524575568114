import {
  Box,
  Button,
  Typography,
  Card,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  styled,
  tableCellClasses,
  CardContent,
  IconButton,
  useTheme,
  Tooltip,
  TableFooter,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Link
} from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import { ArrowForwardTwoTone } from '@mui/icons-material';
import { LessonsGetTypes } from '@/src/actions/LessonActions';
import { FC, useEffect, useState } from 'react';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import {
  formatDateDayMonthYear,
  formatDateWeekdayDayMonth,
  formatDateDayMonth,
  GetFirstDayOfFinancialYear
} from 'src/tools/dateTools';
import { Lesson } from 'src/models/lesson';
import { EditLessonDialogue } from '../Receivables/EditLessonDialogue';
import {
  GetTutorPayment,
  PaymentGetTypes,
  PaymentsGetTypes
} from 'src/actions/PaymentActions';
import { Payment } from 'src/models/payment';
import { useDispatch } from 'react-redux';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {},
  '&:nth-of-type(even)': {
    backgroundColor: '#3b4e7a'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));


export type StudentLessonProps = {
  payments: Payment[];
  tutor_id: string;
};

const TutorPayments: FC<StudentLessonProps> = ({
  payments: payments,
  tutor_id: tutor_id
}) => {
  const [paymentGetType, setPaymentGetType] = useState<PaymentGetTypes>();
  const [openDialogue, setOpenDialogue] = useState(false);
  const [displayAll, setDisplayAll] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<Date>(GetFirstDayOfFinancialYear());
  const [fyPayments, setFyPayments] = useState<Payment[]>([]);
  const dispatch = useDispatch();
  

  const showAllButtonClick = (event) => {
    setDisplayAll(true);
  };


  const setYear = (date: Date) => {
    setFromDate(date);
  };

  const selectLesson = (payment_id: string) => {
    console.log(payment_id);
    dispatch<any>(GetTutorPayment(tutor_id, payment_id, setPaymentGetType));
  };

  const handleClose = () => {
    setOpenDialogue(false);
  };

  useEffect(() => {
    filterDates();
  }, []);

  const filterDates = () => {
    
    const startOfYear = GetFirstDayOfFinancialYear(fromDate);
    const endOfYear = new Date(startOfYear.getFullYear() + 1, startOfYear.getMonth(), startOfYear.getDate())

    const filteredPayments = payments.filter((payment) => {
      const paymentDate = new Date(payment.payment_date);
      return paymentDate >= startOfYear && paymentDate < endOfYear;
    });

    setFyPayments(filteredPayments);
  };

  useEffect(() => {
    filterDates();
  }, [fromDate]);

  useEffect(() => {
    if (paymentGetType?.type == 'Success') {
      setOpenDialogue(true);
    }
  }, [paymentGetType]);

  const createCsv = () => {
    const rows = fyPayments
      .sort((a, b) => (a.payment_date < b.payment_date ? 1 : -1))
      .flatMap((p) =>
        p.lessons.map((l) => [
          p._id,
          new Date(l.date).toISOString(),
          l.student.user_id,
          l.student.first_name,
          l.student.last_name ?? `-`,
          l.duration,
          l.tutor_hourly_rate,
          l.duration * l.tutor_hourly_rate,
          l.tutor_paid_date
            ? new Date(l.tutor_paid_date).toISOString()
            : 'Not yet Paid'
        ])
      );

    // prepend a header row
    rows.unshift([
      'ID',
      'Lesson Date',
      'Student Number',
      'First Name',
      'Last Name',
      'Duration',
      'Hourly Rate',
      'Total Paid',
      'Payment Date'
    ]);

    const csvContent =
      'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'Tutor Payments.csv');
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  const theme = useTheme();

  return (
    <>
      <Card sx={{ marginTop: '10px' }}>
        <CardHeader
          action={
            <Tooltip title="Download CSV">
              <IconButton
                sx={{
                  '&:hover': {
                    background: theme.colors.primary.lighter
                  }
                }}
                onClick={createCsv}
                color="inherit"
                size="small"
              >
                <SummarizeIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          }
          title="Payslips"
        />
        <Divider />
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Link
              component="button"
              variant="body2"
              onClick={() => setYear(new Date(fromDate.getFullYear() - 1, fromDate.getMonth(), fromDate.getDate()))}
            >
              Previous
            </Link>

            <Typography
              pl={3}
              pr={3}
              component="span"
              variant="body2"
              sx={{
                display: 'inline-block',
                textAlign: 'center'
              }}
            >
              {fromDate.getFullYear() + ' - ' + (fromDate.getFullYear() + 1)}
            </Typography>

            <Link
              component="button"
              variant="body2"
              onClick={() => setYear(new Date(fromDate.getFullYear() + 1, fromDate.getMonth(), fromDate.getDate()))}
            >
              Next
            </Link>
          </Box>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12} sm={12}>
              <Box>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>Date</StyledTableCell>
                        <StyledTableCell align="right">Paid</StyledTableCell>
                        <StyledTableCell align="center">
                          Lessons
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fyPayments
                        .sort((a, b) =>
                          a.payment_date < b.payment_date ? 1 : -1
                        )
                        .slice(0, displayAll ? 200 : 3)
                        .map((payment, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell>
                              <Tooltip
                                title={'View Details: ' + payment._id}
                                arrow
                              >
                                <IconButton
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.primary.lighter
                                    },
                                    color: 'grey'
                                  }}
                                  onClick={() => selectLesson(payment._id)}
                                  color="inherit"
                                  size="small"
                                >
                                  <SummarizeIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {formatDateDayMonth(payment.payment_date)}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              ${payment.payment_amount.toFixed(2)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {payment.lessons.length}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                      <StyledTableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>Total:</StyledTableCell>
                        <StyledTableCell align="right">
                          $
                          {fyPayments
                            .reduce((a, b) => a + b.payment_amount, 0)
                            .toFixed(2)}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {fyPayments.reduce((a, b) => a + b.lessons.length, 0)}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
                <div
                  style={{
                    paddingTop: 10,
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  {!displayAll && fyPayments.length > 5 && (
                    <Button onClick={showAllButtonClick}>Show All</Button>
                  )}
                </div>
              </Box>
              {/* {paymentGetType?.type == 'Success' ??(<>{paymentGetType.type}</>)}
              <Box></Box> */}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Dialog fullWidth={true} open={openDialogue} onClose={handleClose}>
        {paymentGetType?.type == 'Success' && (
          <>
            <DialogTitle>
              Payment Details -{' '}
              {formatDateWeekdayDayMonth(paymentGetType.payload.payment_date)}
            </DialogTitle>
            <DialogContent>
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Date</StyledTableCell>
                      <StyledTableCell>Student</StyledTableCell>
                      <StyledTableCell>Hourly Rate</StyledTableCell>
                      <StyledTableCell>Duration</StyledTableCell>
                      <StyledTableCell>Total</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paymentGetType.payload.lessons
                      .sort((a, b) => (a.date < b.date ? 1 : -1))
                      .map((lesson, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell component="th" scope="row">
                            {formatDateDayMonth(lesson.date)}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {lesson.student.first_name +
                              ' ' +
                              lesson.student.last_name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            ${lesson.tutor_hourly_rate}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {lesson.duration}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            ${lesson.tutor_hourly_rate * lesson.duration}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                  <TableFooter>
                    <StyledTableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>Total:</StyledTableCell>
                      <StyledTableCell></StyledTableCell>

                      <StyledTableCell align="center">
                        {paymentGetType.payload.lessons.reduce(
                          (a, b) => a + b.duration,
                          0
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        ${paymentGetType.payload.payment_amount.toFixed(2)}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </DialogContent>
          </>
        )}

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TutorPayments;
