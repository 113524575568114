import {
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  duration,
  FormControlLabel,
  Checkbox,
  Divider,
  Box,
  Autocomplete,
  CircularProgress
} from '@mui/material';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { RegisterLead, LeadSubmitTypes } from 'src/actions/LeadActions';
import { Lead, InterestedIn,interestedInValues } from 'src/models/lead';
import { validateEmail } from 'src/tools/validation';
import { timezones } from 'src/tools/dateTools';
import { useNavigate } from 'react-router';

interface errorInterface {
  isError: boolean;
  message: string;
}

const AddLeadButton = () => {
  const [saveLeadData, setSaveLeadData] = useState<LeadSubmitTypes>();

  const [errors, setErrors] = useState<{
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    origin: string;
    timezone: string;
    interestedIn: string;
    notes: string;
  }>({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    origin: '',
    timezone: '',
    interestedIn: '',
    notes: ''
  });

  const [open, setOpen] = useState(false);

  //Form Data
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    origin: '',
    notes: ''
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [selectedInterestedIn, setSelectedInterestedIn] =
    useState(null);

  const handleTimezoneChange = (event, newValue) => {
    setSelectedTimezone(newValue);
  };

  const handleInterestedInChange = (event, newValue) => {
    setSelectedInterestedIn(newValue);
  };

  //On Submit of the form
  const onSubmit = () => {
    if (!handleValidation()) {
      return;
    }

    const leadData: Lead = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email.toLowerCase(),
      origin: formData.origin,
      timezone: selectedTimezone == null ? '' : selectedTimezone.value,
      interested_in: selectedInterestedIn.value,
      phone: formData.phone,
      status: 'active',
      region: 'aud'
    };

    dispatch<any>(RegisterLead(leadData, formData.notes, setSaveLeadData));
  };

  const updateErrorState = (field, message) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: message
    }));
  };

  type InterestedInOption = {
    label: string;
    value: string;
  };

  const interestedInOptions: InterestedInOption[] = interestedInValues.map((option) => ({
    label: option,
    value: option
  }));

  //Validate form
  const handleValidation = () => {
    let formIsValid = true;

    //First Name
    if (formData.first_name === '') {
      formIsValid = false;
      updateErrorState('firstName', "Field can't be empty");
    } else if (!formData.first_name.match(/^[a-zA-Z\-\s’']+$/)) {
      formIsValid = false;
      updateErrorState('firstName', 'Only letters');
    } else {
      updateErrorState('firstName', '');
    }
    //Email

    if (formData.email === '') {
      formIsValid = false;
      updateErrorState('email', "Field can't be empty");
    } else if (!validateEmail(formData.email)) {
      formIsValid = false;
      updateErrorState('email', 'Email is not valid');
    } else {
      updateErrorState('email', '');
    }

    // Origin
    if(formData.origin === '') {
      formIsValid = false;
      updateErrorState('origin', "Field can't be empty");
    } else {
      updateErrorState('origin', '');
    }
    
    if(selectedInterestedIn == undefined) {
      formIsValid = false;
      updateErrorState('interestedIn', "Field can't be empty");
    } else {
      updateErrorState('interestedIn', '');
    }

    return formIsValid;
  };

  useEffect(() => {
    if (saveLeadData?.type === 'Success') {
      window.location.reload();
    } else if (saveLeadData?.type === 'Failed') {
    }
  }, [saveLeadData]);

  // This function is called when the input changes
  const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogTitle>Add Lead</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <TextField
                autoFocus
                sx={{ flex: 1 }}
                margin="dense"
                id="first_name"
                label="First Name"
                variant="outlined"
                name="first_name"
                required={true}
                error={errors.firstName.length > 0}
                helperText={errors.firstName.length > 0 ? errors.firstName : ''}
                value={formData.first_name}
                onChange={onChanged}
              />

              <TextField
                sx={{ flex: 1 }}
                margin="dense"
                id="last_name"
                label="Last Name"
                variant="outlined"
                name="last_name"
                error={errors.lastName.length > 0}
                helperText={errors.lastName.length > 0 ? errors.lastName : ''}
                value={formData.last_name}
                onChange={onChanged}
              />
            </Box>

            <Box sx={{ display: 'flex', gap: '10px' }}>
              <TextField
                sx={{ flex: 1 }}
                margin="dense"
                id="origin"
                label="Origin"
                variant="outlined"
                name="origin"
                required={true}
                error={errors.origin.length > 0}
                helperText={errors.origin.length > 0 ? errors.origin : ''}
                value={formData.origin}
                onChange={onChanged}
              />

              <Autocomplete
                id="interestedIn"
                sx={{ flex: 1, paddingTop: 1 }}
                options={Object.values(interestedInOptions)}
                getOptionLabel={(option) => option.label}
                value={selectedInterestedIn}
                onChange={handleInterestedInChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required={true}
                    label="Topic interested interested"
                    error={errors.interestedIn.length > 0}
                    helperText={
                      errors.interestedIn.length > 0 ? errors.interestedIn : ''
                    }
                  />
                )}
              />
            </Box>
            <TextField
              margin="dense"
              id="email"
              label="Email"
              variant="outlined"
              type="email"
              name="email"
              required={true}
              error={errors.email.length > 0}
              helperText={errors.email.length > 0 ? errors.email : ''}
              value={formData.email}
              onChange={onChanged}
            />

            <TextField
              margin="dense"
              id="phone"
              label="Phone number"
              variant="outlined"
              type="tel"
              name="phone"
              error={errors.phone.length > 0}
              helperText={errors.phone.length > 0 ? errors.phone : ''}
              value={formData.phone}
              onChange={onChanged}
            />

            <Autocomplete
              id="timezone"
              options={timezones}
              getOptionLabel={(option) => option.label}
              value={selectedTimezone}
              onChange={handleTimezoneChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Timezone"
                  error={errors.timezone.length > 0}
                  helperText={errors.timezone.length > 0 ? errors.timezone : ''}
                />
              )}
            />

            <TextField
              margin="dense"
              id="notes"
              label="Notes"
              variant="outlined"
              type="text"
              name="notes"
              multiline={true}
              fullWidth={true}
              minRows={2}
              error={errors.notes.length > 0}
              helperText={errors.notes.length > 0 ? errors.notes : ''}
              value={formData.notes}
              onChange={onChanged}
            />
          </Box>

          <Typography
            variant="caption"
            marginTop={1}
            gutterBottom
            color="error"
            sx={{
              display:
                saveLeadData && saveLeadData.type === 'Failed'
                  ? 'block'
                  : 'none'
            }}
          >
            {saveLeadData && saveLeadData.type === 'Failed'
              ? saveLeadData.message
              : ''}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={saveLeadData?.type == 'Submitting'}
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Box sx={{ ml: 1, position: 'relative' }}>
            <Button
              type="submit"
              disabled={saveLeadData?.type == 'Submitting'}
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              onClick={onSubmit}
            >
              Add
            </Button>
            {saveLeadData?.type == 'Submitting' && (
              <CircularProgress
                size={24}
                sx={{
                  color: 'white',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            )}
          </Box>
        </DialogActions>
      </Dialog>

      <Button
        sx={{ height: 50, mt: { xs: 2, md: 0 } }}
        variant="contained"
        size="small"
        startIcon={<AddTwoToneIcon fontSize="small" />}
        onClick={handleClickOpen}
      >
        Lead
      </Button>
    </>
  );
};

export default AddLeadButton;
