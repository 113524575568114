// React
import { Box, Container, LinearProgress, Typography } from '@mui/material';
import { useState, useEffect, FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';

// Components
import Footer from 'src/components/Footer';
import StudentsTable from 'src/content/dashboards/AllStudents/StudentsTable';

// Actions
import { GetTutor, TutorGetTypes } from 'src/actions/TutorActions';
import { GetLocalUser } from 'src/actions/AuthActions';

interface TableProps {
  tutorID?: string;
}

const DashboardMyStudents: FC<TableProps> = ({ tutorID: tutorID }) => {

  const [tutorData, setTutorData] = useState<TutorGetTypes>();
  const dispatch = useDispatch();

  const user = GetLocalUser();

  //Initial Load
  useEffect(() => {
    dispatch<any>(GetTutor(tutorID?tutorID:user._id, setTutorData));
  }, []);

  return (
    <>
      <Helmet>
        <title>My Students</title>
      </Helmet>

      <Container maxWidth="lg">

        {tutorData?.type === "Success" ? (
          <StudentsTable referenceUser={GetLocalUser()} studentGroups={tutorData.payload.students} />):(

            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>

        )}

      </Container>
      <Footer />
    </>
  );
};

export default DashboardMyStudents;


