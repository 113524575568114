import {
  Box,
  Container,
  CircularProgress,
  LinearProgress
} from '@mui/material';

import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { GetLocalUser } from 'src/actions/AuthActions';
import Footer from 'src/components/Footer';
import { GetAllPayments, PaymentsGetTypes } from 'src/actions/PaymentActions';
import { GetDaysInYear, GetFirstDayOfFinancialYear } from 'src/tools/dateTools';
import ExpensesTable from './ExpensesTable';

export const ExpenseTypes = [
  'domain names',
  'web hosting',
  'ads',
  'software',
  'hardware',
  'entertainment',
  'other'
];

export type ExpenseType =
  | 'all'
  | 'domain names'
  | 'web hosting'
  | 'ads'
  | 'software'
  | 'hardware'
  | 'entertainment'
  | 'other';

export const Expenses = () => {
  const [paymentsData, setPaymentsData] = useState<PaymentsGetTypes>();
  const dispatch = useDispatch();

  let user = GetLocalUser();

  //Initial Load
  useEffect(() => {
    //If there is a user id in the url params
    if (user != null) {
      dispatch<any>(
        GetAllPayments(
          GetFirstDayOfFinancialYear(),
          GetDaysInYear(),
          setPaymentsData
        )
      );
    }
  }, []);


  return (
    <>
      <Helmet>
        <title>Invoicing</title>
      </Helmet>
      <Box pt={2} />

      <Container maxWidth="lg">
        <h1>Expenses</h1>
        {paymentsData?.type == 'Success' ? (
          <ExpensesTable payments={paymentsData.payload} />
        ) : (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default Expenses;
