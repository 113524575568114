import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Box,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  TextField,
  Typography,
  Card,
  CircularProgress,
  Link,
  FormLabel,
  Grid
} from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  ContactReceivedPageTitle,
  AboutPageCopy,
  SchoolsPageCopy
} from 'src/content/copy/FrontendCopy';

const Schools = () => {
  return (
    <main style={{ backgroundColor: '#020143' }}>
      <CssBaseline />
      <Box
        style={{
          backgroundImage: 'url(/landing/contact-background.jpg)',
          backgroundPositionX: 'center',
          backgroundPositionY: 'top'
        }}
        sx={{
          bgcolor: '#050510',
          pt: 14,
          pb: 6
        }}
      >
        <Card
          sx={{
            // backgroundColor: colors.white,
            minWidth: 100,
            backgroundColor: '#050510',
            paddingTop: 2,
            paddingBottom: 2,
            marginLeft: { xs: 2, sm: 10, md: 14 },
            marginRight: { xs: 2, sm: 2, md: 10 },
            paddingLeft: { xs: 2, sm: 7.5, md: 7.5 },
            display: 'flex',
            flexDirection: 'column'
          }}
        >
        <Container component="main" maxWidth="md">
          <Typography variant="h1" paddingBottom={2} align="center">
            {SchoolsPageCopy.title}
          </Typography>

          <Container component="main" maxWidth="sm">
            {SchoolsPageCopy.copy.map((text, index) => (
              <Typography key={index} paddingBottom={3} variant="body1">
                {text}
              </Typography>
            ))}
          </Container>

          {/* <Button color="primary" variant="contained" href="/contact">
            {FAQContactButton}
          </Button> */}
        </Container>
        </Card>
      </Box>
    </main>
  );
};

export default Schools;
