import { Box, Container, LinearProgress, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { GetLocalUser } from 'src/actions/AuthActions';

import Footer from 'src/components/Footer';

import { GetAllTutors, TutorsGetTypes } from 'src/actions/TutorActions';
import AllStudentsTable from '../AllStudents/AllStudentsTable';
import AllTutorsTable from '../Tutors/AllTutorsTable';

const DashboardAllTutors = () => {
  const [tutorsData, setTutorData] = useState<TutorsGetTypes>();
  const dispatch = useDispatch();
  let user = GetLocalUser();

  //Initial Load
  useEffect(() => {
    dispatch<any>(GetAllTutors(setTutorData));
  }, []);

  return (
    <>
      <Helmet>
        <title>Students</title>
      </Helmet>
      <Box pt={2} />

      <Container maxWidth="lg">
        <h2>Tutors</h2>
        {tutorsData?.type == 'Loading' && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}

        {tutorsData?.type == 'Failed' && (
          <Box sx={{ width: '100%' }}>
            <Typography color="error">{tutorsData.message} </Typography>
          </Box>
        )}

        {tutorsData?.type == 'Success' && (
          <>
            <AllTutorsTable tutors={tutorsData.payload} />
          </>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default DashboardAllTutors;
