import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  Autocomplete,
  MenuItem,
  Select
} from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useEffect, useState } from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useDispatch } from 'react-redux';
import { Student } from 'src/models/student';
import {
  PaymentForCredits,
  CreateExpense,
  PaymentSubmitTypes
} from 'src/actions/PaymentActions';
import { GetLocalUser } from 'src/actions/AuthActions';
import { DatePicker, DesktopDatePicker } from '@mui/lab';
import {
  GetAllStudents,
  StudentSubmitTypes,
  StudentsGetTypes
} from 'src/actions/StudentActions';
import { roundToNearestQuarter } from 'src/tools/validation';
import { capitalizeWords } from 'src/tools/stringTools';
import { ExpenseTypes } from './Expenses';
import { configDotenv } from 'dotenv';
import SuspenseLoader from 'src/components/SuspenseLoader';

const color = '#ffffff';

interface studentInterface {
  label: string;
  value: string;
}

const MakeExpenseButton = () => {
  const [paymentSubmitType, setPaymentSubmitType] =
    useState<PaymentSubmitTypes>();

  const [date, setDate] = useState<Date>(new Date());
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const expenseOptions = ExpenseTypes.map((expense) => ({
    id: expense,
    name: capitalizeWords(expense)
  }));

  //Form Data
  const [formData, setFormData] = useState({
    note: '',
    receipt: '',
    amountPaid: 0,
    expenseType: 'Select Expense'
  });

  const [errors, setErrors] = useState<{
    date: string;
    note: string;
    receipt: string;
    amountPaid: string;
    expenseType: string;
  }>({
    date: '',
    note: '',
    receipt: '',
    amountPaid: '',
    expenseType: ''
  });

  const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const onChangedDate = (event) => {
    setDate(event.value);
  };

  const onTypeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value == 'Select Expense') {
      return;
    }

    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  //Initial Load
  useEffect(() => {}, []);

  //On Submit of the form
  const onSubmit = () => {
    if (!handleValidation()) {
      return;
    }

    dispatch<any>(
      CreateExpense(
        GetLocalUser()._id,
        date,
        formData.amountPaid,
        formData.receipt,
        formData.note,
        formData.expenseType,
        setPaymentSubmitType
      )
    );
  };

  //Validate form
  const handleValidation = () => {
    let formIsValid = true;
    updateErrorState('expenseType', '');
    updateErrorState('amountPaid', '');
    updateErrorState('receipt', '');
    updateErrorState('date', '');

    if (formData.expenseType == 'Select Expense') {
      formIsValid = false;
      updateErrorState('expenseType', 'Select an expense Type');
    }

    if (formData.amountPaid <= 0) {
      formIsValid = false;
      updateErrorState('amountPaid', 'Enter a actual value');
    }

    if (formData.receipt.length < 5) {
      formIsValid = false;
      updateErrorState('receipt', 'Enter valid Receipt Number');
    }

    if (date > new Date()) {
      formIsValid = false;
      updateErrorState('date', "Date Can't be in the future");
    }

    return formIsValid;
  };

  useEffect(() => {
    if (paymentSubmitType && paymentSubmitType.type == 'Success') {
      window.location.reload();
    }
  }, [paymentSubmitType]);

  const updateErrorState = (field, message) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: message
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);

    const defaultValues = {
      date: new Date(),
      note: '',
      receipt: '',
      amountPaid: 0,
      expenseType: 'Select Expense',
      credits: 1
    };

    setFormData(defaultValues);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <Dialog fullWidth={true} open={open} onClose={handleClose}>
        <DialogTitle>Add Expense</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Select
                value={formData.expenseType}
                onChange={onTypeChanged}
                label="Status"
                id="expenseType"
                name="expenseType"
                autoWidth
                error={errors.expenseType.length > 0}
              >
                <MenuItem key="default" value="Select Expense">
                  Select Expense
                </MenuItem>

                {expenseOptions.map((expenseOption) => (
                  <MenuItem key={expenseOption.id} value={expenseOption.id}>
                    {expenseOption.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                label="Payment Date"
                onChange={setDate}
                value={date}
                PaperProps={{
                  sx: {
                    svg: { color: 'white' },
                    span: { color: 'white' }
                  }
                }}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      style={{ marginRight: '10px' }}
                      sx={{
                        svg: { color }
                      }}
                      margin="dense"
                      id="date"
                      error={errors.date.length > 0}
                      helperText={errors.date.length > 0 ? errors.date : ''}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={4} paddingRight={0.5}>
              <TextField
                margin="dense"
                id="amountPaid"
                name="amountPaid"
                value={formData.amountPaid}
                error={errors.amountPaid.length > 0}
                helperText={
                  errors.amountPaid.length > 0 ? errors.amountPaid : ''
                }
                onChange={onChanged}
                label="Payment"
                type="number"
                fullWidth={true}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="receipt"
                required={true}
                name="receipt"
                label="Receipt Number"
                type="text"
                fullWidth={true}
                onChange={onChanged}
                value={formData.receipt}
                error={errors.receipt.length > 0}
                helperText={errors.receipt.length > 0 ? errors.receipt : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="note"
                label="Notes"
                type="text"
                name="note"
                multiline={true}
                onChange={onChanged}
                value={formData.note}
                error={errors.note.length > 0}
                helperText={errors.note.length > 0 ? errors.note : ''}
                fullWidth={true}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {paymentSubmitType?.type != 'Submitting' ? (
            <>
              {' '}
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={onSubmit}>Add</Button>
            </>
          ) : (
            <SuspenseLoader />
          )}
        </DialogActions>
      </Dialog>

      <Button
        sx={{ mt: { xs: 2, md: 0 } }}
        variant="contained"
        size="small"
        startIcon={<PaidIcon fontSize="small" />}
        onClick={handleClickOpen}
      >
        Add Expense
      </Button>
    </>
  );
};

export default MakeExpenseButton;
