import { Tutor } from "./tutor";

export type LeadStatus = 'active' | 'inactive';

export const interestedInValues = [
  'kids programming',
  'teen programming',
  'adult programming',
  'kids art',
  'teen art',
  'adult art',
  'tertiary programming support',
  'kids science',
  'kids maths',
  'other'
] as const;


export const lastContactedThresholds = [

  { key: 'never', value: 'Never contacted' },
  { key: '6', value: '> 6 months' },
  { key: '3', value: '> 3 months' },
  { key: '1', value: '> Month' },
  { key: 'all', value: 'All' },
] as const;

export type InterestedIn = typeof interestedInValues[number];

export interface Lead {
  _id?: string;
  first_name: string;
  last_name?: string;
  email?: string;
  phone?: string;
  region: string;
  timezone: string;
  origin: string;
  createdAt?: Date;
  lastContacted?: Date;
  interested_in: InterestedIn;
  status: LeadStatus;
  notes?:[{
    date: string;
    tutor: Tutor;
    note: string;
  }]
}