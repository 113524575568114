import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import Landing from 'src/content/pages/Landing'

import SuspenseLoader from 'src/components/SuspenseLoader';
import { ArtDetails, DesignDetails,STEMDetails, ProgrammingDetails, SoloDetails } from './content/copy/FrontendCopy';
import DashboardPayroll from './content/dashboards/Payroll/Payroll';
import DashboardReceivables from './content/dashboards/Receivables/Receivables';
import Expenses from './content/dashboards/Expenses/Expenses';
import DashboardMyStudents from './content/dashboards/MyStudent/MyStudents';
import DashboardAllStudents from './content/dashboards/AllStudents/AllStudents';
import DashboardAllTutors from './content/dashboards/Tutors/AllTutors';
import DashboardReports from './content/dashboards/Reports/Reports';
import ContactFormReceived from './content/pages/Landing/ContactReceived';
import Faq from './content/pages/Landing/FAQ';
import Schools from './content/pages/Landing/Schools';
import TutorProfile from './content/dashboards/Tutor/Tutor';
import DashboardAllLeads from './content/dashboards/AllLeads/AllLeads';
import EmailEditor from './content/dashboards/EmailEditor/EmailEditor';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

  const object = { key: 'value' };


// Pages
const Home = Loader(lazy(() => import('src/content/pages/Landing/Home')));
const About = Loader(lazy(() => import('src/content/pages/Landing/About')));
const Stream = Loader(lazy(() => import('src/content/pages/Landing/Stream')));
const AgeGroups = Loader(lazy(() => import('src/content/pages/Landing/AgeGroups')));
const PrivacyPolicy = Loader(lazy(() => import('src/content/pages/Landing/PrivacyPolicy')));
const Credits = Loader(lazy(() => import('src/content/pages/Landing/Credits')));
const Contact = Loader(lazy(() => import('src/content/pages/Landing/Contact')));
const Resources = Loader(lazy(() => import('src/content/pages/Landing/Resources')));
const Unsubscribe = Loader(lazy(() => import('src/content/pages/Unsubscribe/Unsubscribe')));

//Test Page

const Login = Loader(lazy(() => import('src/content/pages/Login')));
const StripeContainer = Loader(
  lazy(() => import('src/content/pages/Payments'))
);
// Dashboards

const MainDashboard = Loader(
  lazy(() => import('src/content/dashboards/Summary/Summary'))
);

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);

const StudentProfile = Loader(
  lazy(() => import('src/content/dashboards/Student/Student'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Settings'))
);

const Calendar = Loader(
  lazy(() => import('src/content/dashboards/Calendar/Calendar'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <Landing />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy />
      },
      {
        path: '/contact',
        element: <Contact />
      },
      {
        path: '/unsubscribe',
        element: <Unsubscribe />
      },
      {
        path: '/contact_received',
        element: <ContactFormReceived />
      },
      {
        path: '/credits',
        element: <Credits />
      },
      {
        path: '/about',
        element: <About />
      },
      {
        path: '/faq',
        element: <Faq />
      },
      {
        path: '/schools',
        element: <Schools />
      },
      {
        path: '/art',
        element: <Stream copy={ArtDetails} />
      },
      {
        path: '/programming',
        element: <Stream copy={ProgrammingDetails} />
      },
      {
        path: '/stem',
        element: <Stream copy={STEMDetails} />
      },
      {
        path: '/design',
        element: <Stream copy={DesignDetails} />
      },
      {
        path: '/solo',
        element: <Stream copy={SoloDetails} />
      },
      {
        path: '/age_groups',
        element: <AgeGroups />
      },
      {
        path: '/resources',
        element: <Resources />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'logout',
        element: <Login />
      },
      {
        path: 'payment',
        element: <StripeContainer />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="main" replace />
      },
      {
        path: 'main',
        element: <MainDashboard />
      },
      {
        path: 'calendar',
        element: <Calendar />
      },
      {
        path: 'payroll',
        element: <DashboardPayroll />
      },
      {
        path: 'receivables',
        element: <DashboardReceivables />
      },
      {
        path: 'expenses',
        element: <Expenses />
      },
      {
        path: 'students',
        element: <DashboardMyStudents />
      },
      {
        path: 'all-students',
        element: <DashboardAllStudents />
      },
      {
        path: 'leads',
        element: <DashboardAllLeads />
      },
      {
        path: 'all-tutors',
        element: <DashboardAllTutors />
      },
      {
        path: 'reports',
        element: <DashboardReports />
      },
      {
        path: 'email-editor',
        element: <EmailEditor />
      }
      
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'student',
        element: <StudentProfile />
      },
      {
        path: 'tutor',
        element: <TutorProfile />
      },
      
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
