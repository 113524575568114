import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Tab,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { GetLocalUser } from 'src/actions/AuthActions';
import {
  LessonsGetTypes,
  LessonInterface,
  GetTutorLessons,
  GetAllLessons
} from 'src/actions/LessonActions';
import Footer from 'src/components/Footer';
import { GetAllStudents, StudentsGetTypes } from 'src/actions/StudentActions';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { DatePicker, TabContext, TabList, TabPanel } from '@mui/lab';
import { Lesson } from 'src/models/lesson';
import WeeklyLessonHours from './WeeklyLessonHours';
import { GetAllPayments, PaymentsGetTypes } from 'src/actions/PaymentActions';
import {
  GetFirstDayOfFinancialYear,
  GetDaysInYear,
  getWeekNumber
} from 'src/tools/dateTools';
import { Payment } from 'src/models/payment';
import WeeklyPayments from './WeeklyPayments';
import { GetAllTutors, TutorsGetTypes } from 'src/actions/TutorActions';
import WeeklyTutorHours from './WeeklyTutorHours';
import WeeklyUniqueStudents from './WeeklyActiveStudents';
import WeeklyNewStudents from './WeeklyNewStudents';
import { setYear } from 'date-fns';

const DashboardReports = () => {
  const [lessonsData, setLessonsData] = useState<LessonsGetTypes>();
  const [paymentsData, setPaymentsData] = useState<PaymentsGetTypes>();
  const [tutorsData, setTutorsData] = useState<TutorsGetTypes>();
  const [studentsData, setStudentsData] = useState<StudentsGetTypes>();

  const [fromDate, setFromDate] = useState<Date>(GetFirstDayOfFinancialYear());
  const [fyPayments, setFyPayments] = useState<Payment[]>([]);
  // const [hoursGraphData, setHoursGraphData] = useState<hoursStatData>();
  // const [paymentsGraphData, setPaymentsGraphData] = useState<paymentsStatData>();
  //const [studentsData, setStudentData] = useState<StudentsGetTypes>();
  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  let user = GetLocalUser();

  //Initial Load
  useEffect(() => {
    let year = new Date().getFullYear();
    dispatch<any>(
      GetAllLessons(
        GetFirstDayOfFinancialYear(),
        GetDaysInYear(),
        setLessonsData
      )
    );
    dispatch<any>(
      GetAllPayments(
        GetFirstDayOfFinancialYear(),
        GetDaysInYear(),
        setPaymentsData
      )
    );
    dispatch<any>(GetAllTutors(setTutorsData));
    dispatch<any>(GetAllStudents(setStudentsData));
  }, []);

  useEffect(() => {
    filterDates();
  }, [paymentsData]);

  const filterDates = () => {
    if (paymentsData?.type != 'Success') return;

    const startOfYear = GetFirstDayOfFinancialYear(fromDate);
    const endOfYear = new Date(startOfYear.getFullYear() + 1, startOfYear.getMonth(), startOfYear.getDate())

    const filteredPayments = paymentsData.payload.filter((payment) => {
      const paymentDate = new Date(payment.payment_date);
      return paymentDate >= startOfYear && paymentDate < endOfYear;
    });

    // console.log(filteredPayments);
    setFyPayments(filteredPayments);
  };

  // //Initial Load
  // useEffect(() => {
  //   if (lessonsData?.type === "Success") {
  //     setHoursGraphData(GetTotalLessonHours(lessonsData.payload));
  //   }
  // }, [lessonsData]);

  // //Initial Load
  // useEffect(() => {
  //   if (paymentsData?.type === "Success") {
  //     let graphData = GetWeeklyIncome(paymentsData.payload);
  //     setPaymentsGraphData(graphData);
  //   }
  // }, [paymentsData]);

  const ytdIncomeTotal = (): number => {

      return fyPayments.filter(
          (item) =>
            item.category == 'credit_payment' ||
            item.category == 'lesson_payment'
        )
        .reduce((a, b) => a + b.payment_amount, 0);

  };

  const ytdOutgoingTotal = (): number => {
      return fyPayments
        .filter(
          (item) => item.category == 'refund' || item.category == 'expense'
        )
        .reduce((a, b) => a + b.payment_amount, 0);
  };

  const ytdTutorPaid = (): number => {
      return fyPayments
        .filter((item) => item.category == 'tutor_paid')
        .reduce((a, b) => a + b.payment_amount, 0);
  };

  const ytdBenPaid = (): number => {

      return fyPayments
        .filter(
          (item) =>
            item.category == 'tutor_paid' && item.to_tutor.last_name != 'Naulls'
        )
        .reduce((a, b) => a + b.payment_amount, 0);
  };

  const yteNet = () => {
    return ytdIncomeTotal() - ytdOutgoingTotal() - ytdTutorPaid();
  };

  const theme = useTheme();

  useEffect(() => {
    filterDates();
  }, [fromDate]);

  const setYear = (date: Date) => {
    setFromDate(date);
  };


  const createIncomePaymentCsv = () => {

    const rows = fyPayments.filter((p) => (p.category == 'lesson_payment' || p.category == 'credit_payment') && p.receipt_number != 'Credits Used').sort((a, b) => (a.payment_date > b.payment_date ? 1 : -1)).flatMap((p) => [[p.category, p.payment_date,p.student, p.payment_amount,p.lessons.length, p.receipt_number, p.note]]);
    // prepend a header row
    rows.unshift(['Category', 'Date', 'Student id', 'Amount','Lesson Count','Receipt','Notes']);

    const csvContent =
      'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'Payments.csv');
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  const GetTotalLessonHours = (lessons: Lesson[], cumulative?: boolean) => {
    const updatedWeekData = lessons.reduce((weekMap, lesson) => {
      const lessonDate = new Date(lesson.date);
      const weekNumber = getWeekNumber(lessonDate);

      if (!weekMap.has(weekNumber)) {
        weekMap.set(weekNumber, 0);
      }

      weekMap.set(weekNumber, weekMap.get(weekNumber) + lesson.duration);

      return weekMap;
    }, new Map());

    const sortedWeekArray = Array.from(updatedWeekData.entries()).sort(
      (a, b) => a[0] - b[0]
    );

    const sortedWeekNumbers = sortedWeekArray.map(
      ([weekNumber, _]) => weekNumber
    );
    const sortedTotalHours = sortedWeekArray.map(
      ([_, totalDuration]) => totalDuration
    );

    return {
      title: 'Lesson Hours per Week',
      dataLabel: 'Hours',
      value: 'this',
      labels: sortedWeekNumbers,
      values: sortedTotalHours
    };
  };

  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>

      <Container maxWidth="lg">
        <h1>Reports</h1>

        <Grid container spacing={1}>
          <Grid item md={3} sm={12} xs={12}>
            <Card sx={{ margin: '10px' }}  >
              <CardContent>
                <CardHeader title="YTD Summary" />
                <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Link
              component="button"
              variant="body2"
              onClick={() => setYear(new Date(fromDate.getFullYear() - 1, fromDate.getMonth(), fromDate.getDate()))}
            >
              Previous
            </Link>

            <Typography
              pl={3}
              pr={3}
              component="span"
              variant="body2"
              sx={{
                display: 'inline-block',
                textAlign: 'center'
              }}
            >
              {fromDate.getFullYear() + ' - ' + (fromDate.getFullYear() + 1)}
            </Typography>

            <Link
              component="button"
              variant="body2"
              onClick={() => setYear(new Date(fromDate.getFullYear() + 1, fromDate.getMonth(), fromDate.getDate()))}
            >
              Next
            </Link>
          </Box>
                <Box>
                  <Tooltip title="Download CSV">
                    <IconButton
                      sx={{
                        '&:hover': {
                          background: theme.colors.primary.lighter
                        }
                      }}
                      onClick={createIncomePaymentCsv}
                      color="inherit"
                      size="small"
                    >
                      <SummarizeIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Divider />
                <Box marginTop={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      Income:
                    </Grid>
                    <Grid item xs={6}>
                      $
                      {ytdIncomeTotal()
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </Grid>
                    <Grid item xs={6}>
                      Tutor Pay:
                    </Grid>
                    <Grid item xs={6}>
                      $
                      {ytdTutorPaid()
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </Grid>

                    {/* <Grid item xs={6}>
                      Ben Pay:
                    </Grid>
                    <Grid item xs={6}>
                      ${ytdBenPaid().toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </Grid> */}

                    <Grid item xs={6}>
                      Outgoing:
                    </Grid>
                    <Grid item xs={6}>
                      $
                      {ytdOutgoingTotal()
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </Grid>
                    <Grid item xs={6}>
                      Net:
                    </Grid>
                    <Grid item xs={6}>
                      $
                      {yteNet()
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={9} sm={12}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  sx={{ marginLeft: 3 }}
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Summary" value="1" />
                  <Tab label="Tutor Hours" value="2" />
                  <Tab label="Students" value="3" />
                </TabList>
              </Box>

              <TabPanel value="1">
                {
                  <Box>
                    <WeeklyPayments payments={fyPayments} />
                  </Box>
                }
                {lessonsData?.type == 'Success' && (
                  <Box marginTop={2}>
                    <WeeklyLessonHours lessons={lessonsData.payload} />
                  </Box>
                )}
              </TabPanel>
              <TabPanel value="2">
                {lessonsData?.type == 'Success' &&
                  tutorsData?.type == 'Success' && (
                    <Box>
                      <WeeklyTutorHours
                        tutors={tutorsData.payload}
                        lessons={lessonsData.payload}
                        stacked={true}
                      />
                    </Box>
                  )}
                {lessonsData?.type == 'Success' &&
                  tutorsData?.type == 'Success' && (
                    <Box marginTop={2}>
                      <WeeklyTutorHours
                        tutors={tutorsData.payload}
                        lessons={lessonsData.payload}
                        stacked={false}
                      />
                    </Box>
                  )}
              </TabPanel>
              <TabPanel value="3">
                {lessonsData?.type == 'Success' && (
                  <Box>
                    <WeeklyUniqueStudents lessons={lessonsData.payload} />
                  </Box>
                )}
                {studentsData?.type == 'Success' && (
                  <Box marginTop={2}>
                    <WeeklyNewStudents students={studentsData.payload} />
                  </Box>
                )}
              </TabPanel>
            </TabContext>

            {paymentsData?.type == 'Loading' ||
              lessonsData?.type == 'Loading' ||
              (studentsData?.type == 'Loading' && (
                <Box paddingTop={2} paddingLeft={2} sx={{ width: '100%' }}>
                  <LinearProgress />
                </Box>
              ))}
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </>
  );
};

export default DashboardReports;
