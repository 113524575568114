import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Box,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  TextField,
  Typography,
  Card,
  CircularProgress,
  Link,
  FormLabel
} from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  ContactPageFormSubmit,
  ContactPageSubmittedForm,
  ContactPageSubmittedFormFailed,
  ContactPageSummary,
  ContactPageTitle,
  ContactPageFormHome,
  ContactReceivedPageSummary,
  ContactReceivedPageTitle,
} from 'src/content/copy/FrontendCopy';

const ContactFormReceived = () => {
  return (
    <main style={{ backgroundColor: '#020143' }}>
      <CssBaseline />
      <Box
        style={{
          backgroundImage: 'url(/landing/contact-background.jpg)',
          backgroundPositionX: 'center',
          backgroundPositionY: 'top'
        }}
        sx={{
          bgcolor: '#050510',
          pt: 14,
          pb: 6
        }}
      >
        <Container component="main" maxWidth="md">
          <Typography variant="h1" paddingBottom={2} align="center">
            {ContactReceivedPageTitle}
          </Typography>

          {/* <Typography maxWidth="md" variant="subtitle1">
            {ContactReceivedPageSummary}
          </Typography> */}

          <Container maxWidth="sm">
            <Card
              sx={{
                padding: 3,
                marginTop: 3,
                marginBottom: 6,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#000000DD'
              }}
            >
               <>
                  <Typography variant="h4" paddingTop={1}>
                    {ContactPageSubmittedForm}
                  </Typography>

                  <Button
                    size="small"
                    href="/"
                    variant="contained"
                    style={{
                      color: 'white',
                      marginTop: 20,
                      borderColor: 'white',
                      borderWidth: 2
                    }}
                  >
                    {ContactPageFormHome}
                  </Button>
                </>
            </Card>
          </Container>
        </Container>
      </Box>
    </main>
  );
};

export default ContactFormReceived;
