import { FC, ChangeEvent, useState } from 'react';
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  Link,
  TextField,
  Tooltip
} from '@mui/material';

import Label from 'src/components/Label';
import { StudentAccountStatus, Student } from 'src/models/student';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { StudentGroup,Tutor } from 'src/models/tutor';
import { getStudentFullName } from 'src/tools/stringTools';
import { GetLocalUser } from 'src/actions/AuthActions';
import {checkTimezoneChanges,getTimezoneHourDifference} from 'src/tools/dateTools'

interface TableProps {
  studentGroups?: StudentGroup[];
  referenceUser;
}

interface Filters {
  status?: StudentAccountStatus;
}

const getStatusLabel = (userStatus: StudentAccountStatus): JSX.Element => {
  const map = {
    inactive: {
      text: 'Inactive',
      color: 'error'
    },
    active: {
      text: 'Active',
      color: 'success'
    }
  };

  const { text, color }: any = map[userStatus];

  return <Label color={color}>{text}</Label>;
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#1b243e",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const applyFilters = (students: StudentGroup[], filters: Filters, search: string): StudentGroup[] => {

  if (!students) {
    return null;
  }

  return students.filter((studentGroup) => {
    let matches = true;

    if (search) {
      return (studentGroup.student.first_name.toLowerCase().includes(search) || studentGroup.student.last_name.toLowerCase().includes(search) || studentGroup.student.user_id.toString().startsWith(search));
    }

    if (filters.status && studentGroup.status !== filters.status) {
      matches = false;
    }

    return matches;
  });
};

const applyPagination = (
  students: StudentGroup[],
  page: number,
  limit: number
): StudentGroup[] => {

  if (!students) {
    return [];
  }

  return students.sort((a,b)=> a.student.first_name >b.student.first_name ?1:-1).slice(page * limit, page * limit + limit);
};

const StudentsTable: FC<TableProps> = ({ referenceUser: referenceUser,studentGroups: studentGroups}) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(15);
  const [userSearch, setUserSearch] = useState<string>();
  const [filters, setFilters] = useState<Filters>({
    status: 'active'
  });
  
  const statusOptions = [
    {
      id: 'all',
      name: 'All'
    },
    {
      id: 'active',
      name: 'Active'
    },
    {
      id: 'inactive',
      name: 'Inactive'
    }
  ];

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredStudents = applyFilters(studentGroups, filters, userSearch);
  const paginatedStudents = applyPagination(filteredStudents, page, limit);

  const handleIDSearch = (event) => {
    if(event.target.value.length > 0){
      setPage(0);
    }
    setUserSearch(event.target.value);
  }

  const getTimeDifference = (student: Student) => {
    var warningNeeded : boolean = checkTimezoneChanges(referenceUser.timezone, student.timezone);
    var timeDifference : number = getTimezoneHourDifference(student.timezone,referenceUser.timezone)

    var colour = timeDifference == 0?'#FFFFFF33':'white';
    var displayString = timeDifference == 0?"-":(timeDifference < 0 ? "- " + Math.abs(timeDifference):"+ " + timeDifference );

    if(warningNeeded){
      return (
      <Tooltip arrow title={student.timezone} placement="left-start">
        <Label color='error'>{displayString}</Label>
      </Tooltip>
      )
    }
    
    return (
      <Tooltip arrow title={student.timezone} placement="top">
        <Typography variant="h5" style={{fontSize: 18, textAlign: 'center', color: colour}}>{ displayString} </Typography>
      </Tooltip>
    )
  }

  return (
    <Card >
      <CardHeader
        action={
          <Box sx={{ display: 'flex' }}>
            <Box width={150} marginRight={2}>
              <TextField id="outlined-basic" label="Search" variant="outlined" onChange={handleIDSearch} />
            </Box>
            <Box width={150} marginRight={2}>
              <FormControl fullWidth variant="outlined">

                <InputLabel>Status</InputLabel>
                <Select
                  value={filters.status || 'all'}
                  onChange={handleStatusChange}
                  label="Status"
                  autoWidth
                >
                  {statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        }
        title="My Managed Students"
      />

      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell><Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                gutterBottom
                noWrap
              >Student ID</Typography></TableCell>
              <TableCell><Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                gutterBottom
                noWrap
              >NAME</Typography></TableCell>
              <TableCell><Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                gutterBottom
                noWrap
              >EMAIL</Typography></TableCell>
                            <TableCell><Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                gutterBottom
                noWrap
              >Time Difference</Typography></TableCell>
              <TableCell><Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                gutterBottom
                noWrap
              >STATUS</Typography></TableCell>
              <TableCell ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedStudents.map((studentGroup, index) => {
              return (
                <StyledTableRow
                  key={index}
                >
                  <TableCell>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={
                        '/management/student?user_id=' +
                        studentGroup.student._id
                      }
                      style={{ color: 'white',textDecoration: 'none' }}
                    >
                      {studentGroup.student.user_id}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={
                        '/management/student?user_id=' +
                        studentGroup.student._id
                      }
                      style={{ color: 'white',textDecoration: 'none' }}
                    >
                      {getStudentFullName(studentGroup.student)}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {studentGroup.student.email ?
                        <Link
                        style={{
                          textDecoration: 'none',
                          cursor: 'pointer',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                        onClick={() =>
                          openInNewTab(
                            'https://mail.google.com/mail/u/0/?tf=cm&fs=1&to=' +
                            studentGroup.student.email +
                            '&hl=en'
                          )
                        }
                        > {studentGroup.student.email}

                        </Link>
                        :
                        <Link
                        style={{
                          textDecoration: 'none',
                          cursor: 'pointer',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                        onClick={() =>
                          openInNewTab(
                            'https://mail.google.com/mail/u/0/?tf=cm&fs=1&to=' +
                            studentGroup.student.parents[0].email +
                            '&hl=en'
                          )
                        }
                        ><EscalatorWarningIcon /> {studentGroup.student.parents[0].email}

                        </Link>
                      }

                    </Typography>
                  </TableCell>
                  <TableCell >
                    
                      {getTimeDifference(studentGroup.student)}
                      
                  </TableCell>
                  <TableCell >
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {getStatusLabel(studentGroup.status)}
                    </Typography>
                  </TableCell>
                  <TableCell >
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {studentGroups != null && filteredStudents && studentGroups.length < 10 ? <></> :
        <Box p={2}>
          <TablePagination
            component="div"
            count={filteredStudents ? filteredStudents.length : 0}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 30]}
          />
        </Box>
      }

    </Card>
  );
};

export default StudentsTable;
