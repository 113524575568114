import { Dispatch, SetStateAction } from "react";

export interface Default {
  type: typeof DEFAULT;
}

export interface Loading {
  type: typeof LOADING;
}

export interface Fail {
  type: typeof FAILED
  message: string;
}

export interface Submitting {
  type: typeof SUBMITTING;
}

export interface Success {
  type: typeof SUCCESS;
}

export type Dispatcher<S> =  Dispatch<SetStateAction<S | undefined>>;

export const LOADING = "Loading";
export const FAILED = "Failed";
export const SUCCESS = "Success";
export const SUBMITTING = "Submitting";
export const DEFAULT = "Default";
import { API_URL } from '../settings';

//const API_URL = 'http://localhost:5001/api/lessons/';
const API_URL_FULL = API_URL+'api/lessons/';

interface Itoken {
  token: string;
}

// Get token from localStorage
export const GetToken = (): string | null => {
  let authString: Itoken | null;
  let token = localStorage.getItem("user");

  if (!token || token === "") {
    throw new Error("Unauthorised");
  }else{
    authString = JSON.parse(token);
    return authString?.token + "";
  }
};

export const GetID = (): string | null =>{
  let authString: Itoken | null;
  let id = localStorage.getItem("id");

  if (!id || id === "") {
    throw new Error("Unauthorised");
  }else{
    return id + "";
  }
}
