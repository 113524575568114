import React, { Fragment } from 'react';
import {
  Text,
  Page,
  Document,
  StyleSheet,
  View,
  Image,
  Link,
  Font
} from '@react-pdf/renderer';
import { Lesson } from 'src/models/lesson';
import linq from 'linq';
import { margin } from '@mui/system';
import { Student } from 'src/models/student';
import { capitalizeWords } from 'src/tools/stringTools';

const styles = StyleSheet.create({

  page: {

  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '10%',
    color: '#555555',
    backgroundSize: 'cover'
  },
  colorBlock: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '10%',
    backgroundColor: '#999999', // Replace with your desired color
  },
  content: {
    marginTop: '10%'
  },
  lineEven: {
    backgroundColor: '#ffffff',
    height: 40,
    display: 'flex',
    alignItems: 'center'
  },
  lineOdd: {
    backgroundColor: '#f2f2f2',
    height: 40,
    display: 'flex',
    alignItems: 'center'
  },
  section: {
    marginBottom: 10,
    padding: 30,
    top: 50,
  },
  topSection: {
    marginBottom: 10,
    paddingTop: 50,
    paddingLeft: 60,
    top: 50,
  },
  topRightSection: {
    position: 'absolute',
    top: 100,
    right: 60,
    width: '50%',
    alignItems: 'flex-end'
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    fontWeight: 'bold'
  },
  text: {
    fontSize: 10,
    paddingTop: 5,
  },
  table: {
    display: 'flex',
    width: '95%',
    paddingTop: 10,
    paddingLeft: 30,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    fontSize: 12
  },
  tableColHeader: {
    width: '20%',
    alignItems: 'center'
  },
  tableColHeaderRight: {
    width: '20%',
    alignItems: 'flex-end'
  },
  tableHeader: {
    backgroundColor: '#e0e0e0',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px',
    fontWeight: 'extrabold',
    fontSize: 12,
    height: 20,
  },
  tableCol: {
    width: '20%',
    alignItems: 'center'
  },
  tableColNumber: {
    width: '20%',
    alignItems: 'flex-end',
    paddingRight: '12'
  },
  total: {
    fontSize: 14,
    fontWeight: 'extrabold',
    textAlign: 'center'
  },
  totalText: {
    fontSize: 14,
    fontWeight: 'extrabold',
    textAlign: 'right',
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 90,
    right: 90,
    fontSize: 12,
  },
  padTopText5: {
    marginTop: 2.5,
  },
  padTopText10: {
    marginTop: 7,
  },
  paymentDetails: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row', // Display columns side by side
    justifyContent: 'space-between', // Evenly distribute columns
  },
  paymentColumn: {
    flex: 1, // Equal width columns
  },
  paymentOptionTitleText: {
    marginBottom: 5,
    ontSize: 14,
  },

  paymentRefences: {
    fontSize: 12,
    borderBottom: '1px solid #ccc',
    paddingBottom: 10,
    fontWeight: 'extrabold',
    marginBottom: 10,
  },
  paymentRefencesText: {
    fontSize: 14,
    width: '100%',
    alignItems: 'center',
    marginTop: 10,
    padding: 10,
    fontWeight: 'extrabold',
    backgroundColor: '#e0e0e0',
    textAlign: 'center'
  },

  issueInfo: {
    fontSize: 12,
    
    borderTop: '1px solid #ccc',
    paddingTop: 10,
  },

  logoImage: {
    paddingTop: '10px',
    paddingLeft: '30px',
    width: '200px'
  }

});

const formatDate = (string: Date) => {
  return new Date(string).toLocaleDateString('en-AU', {
    year: '2-digit',
    month: 'short',
    day: '2-digit'
  });
}

export type tableProps = {
  lessons: Lesson[];
  student: Student;
};

const InvoiceTemplate = ({ lessons, student }: tableProps): JSX.Element => (

  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.colorBlock}>
      <Image style={styles.logoImage} src="\logo-white.png" />
        </View>
      <View style={styles.backgroundImage} />
      <View style={styles.topSection}>
        <Text style={styles.header}>Invoice</Text>
        <Text style={styles.text}>Student: {student.first_name} {student.last_name}</Text>
        <Text style={styles.text}>Student No.: {student.user_id}</Text>
        {student.parents && student.parents.length > 0? 
          <>
            <Text style={styles.text}>To: {student.parents[0].first_name} {student.parents[0].last_name}</Text>
            <Text style={styles.text}>Email.: {student.parents[0].email}</Text>
          </>
        : 
          <Text style={styles.text}>Email: {student.email}</Text>
        }
      </View>
      <View style={styles.topRightSection}>
        <Text style={styles.text}>Date: {formatDate(new Date())}</Text>
        <Text style={styles.text}>Aptetut Tutoring</Text>
        <Text style={styles.text}>ABN: 48579092790</Text>

        {student.additional_invoice_details &&student.additional_invoice_details !== ""  ? student.additional_invoice_details.split('\n').map((line, index) => (
          <Text key={index} style={styles.text}>{line}</Text>
        )) : null}

      </View>
      <View style={styles.section}>

        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <View style={styles.tableColHeader}>
              <Text>DATE</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>TUTOR</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>RATE</Text>
            </View>
            <View style={styles.tableColHeaderRight}>
              <Text>HOURS</Text>
            </View>
            <View style={styles.tableColHeaderRight}>
              <Text>TOTAL</Text>
            </View>
          </View>
          {lessons.map((lesson, index) => (
            <View
              key={index}
              style={[
                styles.tableRow,
                index % 2 === 0 ? styles.lineEven : styles.lineOdd
              ]}
            >
              <View style={styles.tableCol}>
                <Text>{formatDate(lesson.date)}</Text>
              </View>
              <View style={styles.tableColNumber}>
                <Text>{lesson.tutor.first_name}</Text>
              </View>
              <View style={styles.tableColNumber}>
                <Text>${lesson.hourly_rate} p/h</Text>
              </View>
              <View style={styles.tableColNumber}>
                <Text>{lesson.duration}</Text>
              </View>
              <View style={styles.tableColNumber}>
                <Text>${lesson.duration * lesson.hourly_rate}</Text>
              </View>
            </View>
          ))}

          <View style={[styles.tableRow, styles.lineEven]}>
            <View style={styles.tableCol}>
            </View>
            <View style={styles.tableCol}>
            </View>
            <View style={styles.tableColNumber}>
              <Text style={styles.totalText}>Total:</Text>
            </View>
            <View style={styles.tableColNumber}>
              <Text style={styles.total}>
                {lessons.reduce((accumulator, currentItem) => {
                  return accumulator + currentItem.duration;
                }, 0)}
              </Text>
            </View>
            <View style={styles.tableColNumber}>
              <Text style={styles.total}>$
              {lessons.reduce((accumulator, currentItem) => {
                  return accumulator + currentItem.duration * currentItem.hourly_rate;
                }, 0)}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <View style={styles.paymentRefences}>
          <Text>For all payments please ensure that you use the reference:</Text>
          <Text style={styles.paymentRefencesText}>{student.user_id} - {student.first_name}</Text>
        </View>
        <View style={styles.paymentDetails}>
          <View style={styles.paymentColumn}>
            <Text style={styles.paymentOptionTitleText}>Bank Transfer:</Text>
            <Text style={styles.padTopText5}>BSB: 01 3943</Text>
            <Text style={styles.padTopText5}>Account: 612 802 775</Text>
            <Text style={styles.padTopText5}>Name: Aptetut</Text>
            <Text style={styles.padTopText5}>Bank: Airwallex</Text>
          </View>
          <View style={styles.paymentColumn}>
            <Text style={styles.paymentOptionTitleText}>Online Payments:</Text>
            <Link src="https://www.airwallex.com/pay/hk/link/f1c98db32417">Payment Portal</Link>
            <Text></Text>
          </View>
        </View>
        <View style={styles.issueInfo}>
          <Text>If you have any questions or encounter any issues with this invoice, please contact our support team:</Text>
          <Text style={styles.padTopText10}>Email: info@aptetut.com</Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default InvoiceTemplate;
