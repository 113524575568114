import axios from 'axios';

import {
  Dispatcher,
  FAILED,
  SUBMITTING,
  SUCCESS,
  LOADING,
  GetToken,
  Submitting,
  Default,
  Loading,
  Fail,
  Success
} from './LoadingActions';

import { Student } from '../models/student';
import { string } from 'prop-types';


//const API_URL = 'http://localhost:5001/api/users/';

import { API_URL } from '../settings';

const API_URL_FULL = API_URL+'api/users/';

export type StudentsGetTypes = Loading | Fail | GetStudentsSuccess;
export type StudentGetTypes = Loading | Fail | GetStudentSuccess;
export type StudentSubmitTypes =
  | Default
  | Submitting
  | Fail
  | GetStudentSuccess;

export interface GetStudentsSuccess {
  type: typeof SUCCESS;
  payload: Student[];
}

export interface GetStudentSuccess {
  type: typeof SUCCESS;
  payload: Student;
}

//Get all students
export const GetAllStudents =
  (setData: Dispatcher<StudentsGetTypes>) => async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: LOADING
      });

      const res = await axios.get(API_URL_FULL + 'get-all-students',config);

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

//Get student from ID
export const GetStudents =
  (tutor_id: string, setData: Dispatcher<StudentsGetTypes>) => async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: LOADING
      });

      const res = await axios.post(API_URL_FULL + 'get-all-students',{}, config);

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

//Get student from ID
export const GetStudent =
  (studentID: string, setData: Dispatcher<StudentGetTypes>) => async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: LOADING
      });

      const res = await axios.post(
        API_URL_FULL + 'student',
        { id: studentID },
        config
      );

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

//Add a new student
export const RegisterStudent =
  (studentData: Student, setData: Dispatcher<StudentSubmitTypes>) =>
  async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: SUBMITTING
      });

      const res = await axios.post(
        API_URL_FULL + 'register-student',
        studentData,
        config
      );

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log(error.response.data);
        }

        setData({
          type: FAILED,
          message: error.message
        });
        return;
      }
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

//Add Parent
export const AddParent =
  (
    student_id: string,
    first_name: string,
    last_name: string,
    email: string,
    phone: string,
    setData: Dispatcher<StudentSubmitTypes>
  ) =>
  async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      const postData = {
        student_id: student_id,
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone
      };

      setData({
        type: SUBMITTING
      });
      const res = await axios.post(API_URL_FULL + 'add-parent', postData, config);

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

//Add Parent
export const AddCredit =
  (
    student_id: string,
    credit: number,
    actioned_by: string,
    note: string,
    setData: Dispatcher<StudentSubmitTypes>
  ) =>
  async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      const postData = {
        student_id: student_id,
        credit: credit,
        actioned_by: actioned_by,
        note: note
      };

      setData({
        type: SUBMITTING
      });
      const res = await axios.post(API_URL_FULL + 'add-credit', postData, config);

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

  //Add Note
export const AddNote =
(
  student_id: string,
  actioned_by: string,
  note: string,
  setData: Dispatcher<StudentSubmitTypes>
) =>
async () => {
  try {
    const token = GetToken();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const postData = {
      student_id: student_id,
      actioned_by: actioned_by,
      note: note
    };

    setData({
      type: SUBMITTING
    });
    const res = await axios.post(API_URL_FULL + 'add-note', postData, config);

    setData({
      type: SUCCESS,
      payload: res.data
    });
  } catch (error) {
    setData({
      type: FAILED,
      message: 'Error'
    });
  }
};

//Update student details
export const UpdateStudentInvoiceDetails =
  (studentData: Student, setData: Dispatcher<StudentSubmitTypes>) =>
  async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: SUBMITTING
      });

      const postData = {
        student_id: studentData._id,
        details: studentData.additional_invoice_details,
        is_ndis: studentData.ndis_student
      };

      const res = await axios.post(
        API_URL_FULL + 'update-student-invoice-details',
        postData,
        config
      );

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

//Update student details
export const UpdateStudentDetails =
  (studentId: string, phone: string, email: string,invoice_email : string, timezone: string, setData: Dispatcher<StudentSubmitTypes>) =>
  async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: SUBMITTING
      });

      const postData = {
        id: studentId,
        phone: phone,
        email: email,
        invoice_email: invoice_email,
        timezone: timezone
      };


      const res = await axios.post(
        API_URL_FULL + 'update-student-details',
        postData,
        config
      );

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };


//Update student details
export const ArchiveStudent =
  (student_id: string,tutor_id: string,status: string, setData: Dispatcher<StudentSubmitTypes>) => async () => {
    try {
      const token = GetToken();

      if (!token || token === '') {
        setData({
          type: FAILED,
          message: 'Unauthorised'
        });
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      const postData = {
        student_id: student_id,
        tutor_id: tutor_id,
        status: status,
      };

      setData({
        type: SUBMITTING
      });
      const res = await axios.post(
        API_URL_FULL + 'update-student-status',
        postData,
        config
      );

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };
