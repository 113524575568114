import { formatDateWeekdayDayMonth } from 'src/tools/dateTools';
import { DatePicker, DesktopDatePicker, TimePicker } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  TextField,
  List,
  ListItem,
  Checkbox,
  ListItemButton,
  ListItemText,
  DialogActions,
  Button,
  DialogContentText,
  useMediaQuery,
  useTheme,
  CircularProgress
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { InfoCardProps } from '../Student/InfoCard';
import { Lesson } from 'src/models/lesson';
import { Dispatcher } from 'src/actions/LoadingActions';
import {
  UpdateLessonStatus,
  LessonGetTypes,
  LessonSubmitTypes,
  UpdateLesson
} from 'src/actions/LessonActions';
import { useDispatch } from 'react-redux';
import { IsManagerOrAdmin } from 'src/tools/validation';

export type EditLessonDialogueProps = {
  lesson: Lesson;
  isOpen: boolean;
  onClose: Dispatcher<boolean>;
  isAdmin: boolean;
};

export const EditLessonDialogue: FC<EditLessonDialogueProps> = ({
  lesson: lesson,
  isOpen: isOpen,
  onClose: onClose
}) => {
  const [lessonDate, setLessonDate] = useState<Date>(new Date());

  const [updateLesson, setUpdateLesson] = useState<LessonGetTypes>();
  const color = '#ffffff';
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  // Can edit the lesson
  const canEdit = (): boolean => {
    if (lesson?.invoiced_date || lesson?.paid_date || lesson?.tutor_paid_date) {
      return false;
    }
    return true;
  };

  //Form Data
  const [formData, setFormData] = useState({
    duration: 1,
    student_hourly_rate: 1,
    tutor_hourly_rate: 1,
    notes: ''
  });

  const [errors, setErrors] = useState<{
    date: string;
    duration: string;
    tutor_hourly_rate: string;
    student_hourly_rate: string;
    notes: string;
  }>({
    date: '',
    duration: '',
    student_hourly_rate: '',
    tutor_hourly_rate: '',
    notes: ''
  });

  useEffect(() => {
    if (isOpen) {
      const newValues = {
        duration: lesson.duration,
        student_hourly_rate: lesson.hourly_rate,
        tutor_hourly_rate: lesson.tutor_hourly_rate,
        notes: lesson.notes
      };

      setLessonDate(lesson.date);

      setFormData(newValues);
    }
  }, [isOpen]);

  const onTimeChange = (date) => {
    if (!canEdit()) {
      return;
    }
    setLessonDate(date);
  };

  const updateErrorState = (field, message) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: message
    }));
  };

  const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const handleConfirmCancelLesson = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (updateLesson?.type == 'Success') {
      window.location.reload();
    }
  }, [updateLesson]);

  const handleAgree = () => {
    dispatch<any>(UpdateLessonStatus(lesson._id, 'cancelled', setUpdateLesson));
    setOpen(false);
  };

  const handleDisagree = () => {
    setOpen(false);
  };

  function handleCloseDialogue(): void {
    onClose(false);
  }

  function handleSubmitDialogue(): void {
    dispatch<any>(
      UpdateLesson(
        lesson._id,
        lessonDate,
        formData.duration,
        formData.tutor_hourly_rate,
        formData.student_hourly_rate,
        formData.notes,
        setUpdateLesson
      )
    );
  }

  function handleOnComplete(): void {
    dispatch<any>(UpdateLessonStatus(lesson._id, 'completed', setUpdateLesson));
  }

  return (
    <>
      {lesson ? (
        <Dialog fullWidth open={isOpen} onClose={handleCloseDialogue}>
          {lesson.student.user_id && (
            <DialogTitle>
              {lesson.student.user_id} - {lesson.student.first_name}{' '}
              {lesson.student.last_name}
              <Button size="small" onClick={handleCloseDialogue}>
                X
              </Button>
            </DialogTitle>
          )}

          <DialogContent>
            <Grid container spacing={0}>
              <Grid item marginBottom={2} xs={12}>
                <Typography variant="h3">
                  Tutor: {lesson.tutor.first_name} {lesson.tutor.last_name}
                </Typography>
              </Grid>
              <Grid item xs={6} paddingRight={1}>
                <DatePicker
                  label="Lesson Date"
                  onChange={onTimeChange}
                  value={lessonDate}
                  PaperProps={{
                    sx: {
                      svg: { color: { color } },
                      span: { color: 'white' }
                    }
                  }}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        disabled={!canEdit()}
                        fullWidth={true}
                        style={{ marginRight: '10px' }}
                        sx={{
                          svg: { color }
                        }}
                        margin="dense"
                        id="date"
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TimePicker
                  label="Time"
                  value={lessonDate}
                  onChange={onTimeChange}
                  PopperProps={{
                    sx: {
                      '.MuiPaper-root': {
                        padding: 2,
                        //backgroundColor: '#3b627f',
                        color: { color },
                        svg: { color }
                      }
                    }
                  }}
                  renderInput={(props) => (
                    <TextField
                      fullWidth={true}
                      margin="dense"
                      id="date"
                      name="date"
                      {...props}
                      disabled={!canEdit()}
                      sx={{ marginRight: '10px', svg: { color } }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} paddingRight={1}>
                <TextField
                  margin="dense"
                  id="duration"
                  label="Duration"
                  type="number"
                  disabled={!canEdit()}
                  name="duration"
                  inputProps={{
                    maxLength: 6,
                    step: '0.25'
                  }}
                  onChange={onChanged}
                  value={formData.duration}
                  error={errors.duration.length > 0}
                  helperText={errors.duration.length > 0 ? errors.duration : ''}
                  fullWidth={true}
                />
              </Grid>

              {IsManagerOrAdmin() ? (
                <>
                  <Grid item xs={4} paddingRight={1}>
                    <TextField
                      margin="dense"
                      id="tutor_hourly_rate"
                      label="Tutor Hourly Rate"
                      type="number"
                      name="tutor_hourly_rate"
                      onChange={onChanged}
                      disabled={lesson?.tutor_paid_date ? true : false}
                      value={formData.tutor_hourly_rate}
                      error={errors.tutor_hourly_rate.length > 0}
                      helperText={
                        errors.tutor_hourly_rate.length > 0
                          ? errors.tutor_hourly_rate
                          : ''
                      }
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      margin="dense"
                      id="hourly_rate"
                      label="Student Hourly Rate"
                      type="number"
                      name="hourly_rate"
                      disabled={
                        lesson?.paid_date || lesson?.invoiced_date
                          ? true
                          : false
                      }
                      onChange={onChanged}
                      value={formData.student_hourly_rate}
                      error={errors.student_hourly_rate.length > 0}
                      helperText={
                        errors.student_hourly_rate.length > 0
                          ? errors.student_hourly_rate
                          : ''
                      }
                      fullWidth={true}
                    />
                  </Grid>
                </>
              ) : (
                <></>
              )}

              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  id="notes"
                  label="Notes"
                  type="text"
                  name="notes"
                  disabled={!canEdit()}
                  multiline={true}
                  onChange={onChanged}
                  value={formData.notes}
                  error={errors.notes.length > 0}
                  helperText={errors.notes.length > 0 ? errors.notes : ''}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ marginRight: 3 }}>
            {updateLesson?.type == 'Loading' ||
            updateLesson?.type == 'Success' ? (
              <CircularProgress
                size={24}
                sx={{
                  color: 'white',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            ) : (
              <>
                {canEdit() && (
                  <Button
                    size="small"
                    color="error"
                    variant="contained"
                    onClick={handleConfirmCancelLesson}
                  >
                    Cancel Lesson
                  </Button>
                )}

                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitDialogue}
                >
                  Update Lesson
                </Button>

                {lesson.status == 'booked' && (
                  <Button
                    size="small"
                    variant="contained"
                    color="success"
                    onClick={handleOnComplete}
                  >
                    Complete Lesson
                  </Button>
                )}
              </>
            )}
          </DialogActions>
          <br />
        </Dialog>
      ) : (
        <></>
      )}

      <Dialog
        open={open}
        onClose={handleDisagree}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Are you sure you want to cancel this lesson?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>This can not be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDisagree}>
            Back
          </Button>
          <Button onClick={handleAgree} autoFocus>
            Yes, Cancel the lesson
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
