import { FC, ChangeEvent, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  Link,
  TextField
} from '@mui/material';

import Label from 'src/components/Label';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Tutor, TutorAccountStatus } from '@/src/models/tutor';
import AddTutorButton from './AddTutorButton';

interface TableProps {
  className?: string;
  tutors: Tutor[];
}

interface Filters {
  status?: TutorAccountStatus;
}

const getStatusLabel = (userStatus: TutorAccountStatus): JSX.Element => {
  const map = {
    inactive: {
      text: 'Inactive',
      color: 'error'
    },
    active: {
      text: 'Active',
      color: 'success'
    }
  };

  const { text, color }: any = map[userStatus];

  return <Label color={color}>{text}</Label>;
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#555555",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const applyFilters = (tutors: Tutor[], filters: Filters, search: string): Tutor[] => {
  return tutors.filter((tutor) => {
    let matches = true;

    if (search) {
      return (tutor.first_name.toLowerCase().includes(search) || tutor.last_name.toLowerCase().includes(search));
    }

    if (filters.status && tutor.status !== filters.status) {
      matches = false;
    }

    return matches;
  });
};

const applyPagination = (
  tutors: Tutor[],
  page: number,
  limit: number
): Tutor[] => {
  return tutors.slice(page * limit, page * limit + limit);
};

const AllTutorsTable: FC<TableProps> = ({ tutors: tutors }) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [userSearch, setUserSearch] = useState<string>();
  const [filters, setFilters] = useState<Filters>({
    status: 'active'
  });

  const statusOptions = [
    {
      id: 'all',
      name: 'All'
    },
    {
      id: 'active',
      name: 'Active'
    },
    {
      id: 'inactive',
      name: 'Inactive'
    }
  ];

  const navigate = useNavigate();

  const goToProfile = (
    event: ChangeEvent<HTMLInputElement>,
    userId: string
  ): void => {
    navigate('/management/profile/details?user_id=' + userId);
  };

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredTutors = applyFilters(tutors, filters, userSearch);
  const paginatedTutors = applyPagination(filteredTutors, page, limit);
  const theme = useTheme();

  const handleIDSearch = (event) => {
    if (event.target.value) {
      //  setFilters('all')
    }
    setUserSearch(event.target.value);
  }

  return (
    <Card>
      <CardHeader
        action={
          <Box sx={{ display: 'flex' }}>
            <Box marginRight={2}>
              <AddTutorButton title="Add Tutor" />
            </Box>
            <Box width={150} marginRight={2}>
              <TextField id="outlined-basic" label="Search" variant="outlined" onChange={handleIDSearch} />
            </Box>
            <Box width={150} marginRight={2}>
              <FormControl fullWidth variant="outlined">

                <InputLabel>Status</InputLabel>
                <Select
                  value={filters.status || 'all'}
                  onChange={handleStatusChange}
                  label="Status"
                  autoWidth
                >
                  {statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        }
        title="All Tutors"
      />

      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell><Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                gutterBottom
                noWrap
              >NAME</Typography></TableCell>
              <TableCell><Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                gutterBottom
                noWrap
              >EMAIL</Typography></TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >STATUS</Typography>
              </TableCell>
              <TableCell><Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                gutterBottom
                noWrap
              ></Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedTutors.map((tutors, index) => {
              return (
                <StyledTableRow
                  key={index}
                >
                  <TableCell>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={
                        '/management/tutor?user_id=' +
                        tutors._id
                      }
                      style={{color: 'white', textDecoration: 'none' }}
                    >
                      {tutors.first_name + ' ' + tutors.last_name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      <Link
                        href={'mailto:' + tutors.email}
                        style={{ textDecoration: 'none' }}
                      > {tutors.email}

                      </Link>

                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {getStatusLabel(tutors.status)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">

                    <Tooltip title="Edit Account" arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: 'white'
                        }}
                        color="inherit"
                        size="small"
                        href={
                          `/management/profile/settings?account_type=student&user_id=` +
                          tutors._id
                        }
                      >
                        <EditTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {tutors.length < 10 ? <></> :
        <Box p={2}>
          <TablePagination
            component="div"
            count={filteredTutors.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[10, 25, 30]}
          />
        </Box>
      }

    </Card>
  );
};

AllTutorsTable.propTypes = {
  tutors: PropTypes.array.isRequired
};

AllTutorsTable.defaultProps = {
  tutors: []
};

export default AllTutorsTable;
