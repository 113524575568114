import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Box,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  TextField,
  Typography,
  Card,
  CircularProgress,
  Link,
  FormLabel,
  Grid
} from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  ContactReceivedPageTitle,
  FAQandA,
  FAQTitle,
  FAQSummary,
  AboutPageCopy,
  FAQContactButton
} from 'src/content/copy/FrontendCopy';

const Faq = () => {
  return (
    <main style={{ backgroundColor: '#020143' }}>
      <CssBaseline />
      <Box
        style={{
          backgroundImage: 'url(/landing/contact-background.jpg)',
          backgroundPositionX: 'center',
          backgroundPositionY: 'top'
        }}
        sx={{
          bgcolor: '#050510',
          pt: 14,
          pb: 6
        }}
      >
        <Container component="main" maxWidth="md">
          <Typography variant="h1" paddingBottom={2} align="center">
            {FAQTitle}
          </Typography>

          <Container
            sx={{
              marginBottom: 3,
              margin: 3
            }}
          >
            <Typography maxWidth="md" variant="subtitle1">
              {FAQSummary}
            </Typography>
          </Container>

          {FAQandA.map((faq, index) => (
            <Card
              key={index}
              sx={{
                backgroundColor: '#000000DD',
                paddingRight: { xs: 1, sm: 2, md: 4 },
                fontStyle: 'italic',
                paddingLeft: { xs: 1, sm: 2, md: 4 },
                marginBottom: 3,
                display: 'flex',
                flexDirection: 'column',
                fontWeight: 'light'
              }}
            >
              <Typography variant="h3" paddingTop={1.1} paddingBottom={2}>
                {faq.question}
              </Typography>
              <Typography variant="h4" paddingLeft={2} paddingBottom={1}>
                {faq.answer}
              </Typography>
            </Card>
          ))}

          <Button color="primary" variant="contained" href="/contact">
            {FAQContactButton}
          </Button>
        </Container>
      </Box>
    </main>
  );
};

export default Faq;
