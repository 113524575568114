import { Box, Container, Grid, Card, LinearProgress } from '@mui/material';
import events from 'events';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { GetLocalUser } from 'src/actions/AuthActions';
import {
  LessonsGetTypes,
  LessonInterface,
  GetStudentLessons,
  GetTutorLessons
} from 'src/actions/LessonActions';
import {
  GetTutorLessonGenerators,
  LessonGeneratorsGetTypes
} from 'src/actions/LessonGeneratorActions';
import Footer from 'src/components/Footer';
import { Lesson } from 'src/models/lesson';

import MiniStat from '../MiniStat';
import { GetAllStudents, StudentsGetTypes } from 'src/actions/StudentActions';
import StudentsTable from '@/src/content/dashboards/AllStudents/StudentsTable';
import TutorUnpaidLessons from 'src/content/dashboards/Summary/TutorUnpaidLessonsTable';
import NewInvoice from '../../applications/Reports/NewInvoiceTemplate';
import UnpaidTutorsTable from './UnpaidTutorsTable';

const DashboardPayroll = () => {
  const [lessonData, setLessonData] = useState<LessonsGetTypes>();
  // const [studentsData, setStudentData] = useState<StudentsGetTypes>();
  const dispatch = useDispatch();

  let user = GetLocalUser();

  //Initial Load
  useEffect(() => {
    //If there is a user id in the url params
    if (user != null) {
      dispatch<any>(GetTutorLessons(setLessonData, null, 'tutor-unpaid'));
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Payroll</title>
      </Helmet>

      <Box pt={2} />

      <Container maxWidth="lg">
        <h1>Payroll</h1>

        {/* <NewInvoice/> */}

        {lessonData && lessonData.type == 'Success' ? (
          <UnpaidTutorsTable lessons={lessonData.payload} />
        ) : (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default DashboardPayroll;
