import { FC, ChangeEvent, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  Button,
  Link,
  TextField
} from '@mui/material';

import Label from 'src/components/Label';
import { Student, StudentAccountStatus } from 'src/models/student';
import BoyIcon from '@mui/icons-material/Boy';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import Person2Icon from '@mui/icons-material/Person';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import { useNavigate } from 'react-router-dom';
import AddStudentButton from './AddStudentButton';
import { styled } from '@mui/material/styles';
import { getStudentIDAndFullName } from 'src/tools/stringTools';

interface TableProps {
  className?: string;
  students: Student[];
}

interface Filters {
  status?: StudentAccountStatus;
}

const openInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
};

const getStatusLabel = (userStatus: StudentAccountStatus): JSX.Element => {
  const map = {
    inactive: {
      text: 'Inactive',
      color: 'error'
    },
    active: {
      text: 'Active',
      color: 'success'
    }
  };

  const { text, color }: any = map[userStatus];

  return <Label color={color}>{text}</Label>;
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#555555",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const applyFilters = (students: Student[], filters: Filters, search: string): Student[] => {
  return students.filter((student) => {
    let matches = true;

    if (search) {
      return (student.first_name.toLowerCase().includes(search.toLowerCase()) || student.last_name.toLowerCase().includes(search.toLowerCase()) || student.user_id.toString().startsWith(search));
    }

    if (filters.status && student.status !== filters.status) {
      matches = false;
    }

    return matches;
  });
};

const applyPagination = (
  students: Student[],
  page: number,
  limit: number
): Student[] => {
  return students.slice(page * limit, page * limit + limit);
};

const AllStudentsTable: FC<TableProps> = ({ students: students }) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(15);
  const [userSearch, setUserSearch] = useState<string>();
  const [filters, setFilters] = useState<Filters>({
    status: 'active'
  });

  const statusOptions = [
    {
      id: 'all',
      name: 'All'
    },
    {
      id: 'active',
      name: 'Active'
    },
    {
      id: 'inactive',
      name: 'Inactive'
    }
  ];

  const navigate = useNavigate();

  const goToProfile = (
    event: ChangeEvent<HTMLInputElement>,
    userId: string
  ): void => {
    navigate('/management/profile/details?user_id=' + userId);
  };

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredStudents = applyFilters(students, filters, userSearch).sort((a,b) => a.first_name>b.first_name?1:-1);
  const paginatedStudents = applyPagination(filteredStudents, page, limit);
  const theme = useTheme();

  const handleIDSearch = (event) => {

    if(event.target.value.length > 0){
      setPage(0);
    }

    setUserSearch(event.target.value);
  }

  return (
    <Card>
      <CardHeader
        action={
          <Box sx={{ display: 'flex' }}>
            <Box marginRight={2}>
              <AddStudentButton tall={true} />
            </Box>
            <Box width={150} marginRight={2}>
              <TextField id="outlined-basic" label="Search" variant="outlined" onChange={handleIDSearch} />
            </Box>
            <Box width={150} marginRight={2}>
              <FormControl fullWidth variant="outlined">

                <InputLabel>Status</InputLabel>
                <Select
                  value={filters.status || 'all'}
                  onChange={handleStatusChange}
                  label="Status"
                  autoWidth
                >
                  {statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        }
        title="All Students"
      />

      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell><Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                gutterBottom
                noWrap
              >Student</Typography></TableCell>
              <TableCell><Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                gutterBottom
                noWrap
              >EMAIL</Typography></TableCell>
              <TableCell align="right"><Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                gutterBottom
                noWrap
              >STATUS</Typography></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedStudents.map((student, index) => {
              return (
                <StyledTableRow
                  key={index}
                >
                  <TableCell>

                  </TableCell>
                  <TableCell>
                    <Link
                    color='white'
                      href={
                        '/management/student?user_id=' +
                        student._id
                      }
                      style={{ textDecoration: 'none' }}
                    >
                      {getStudentIDAndFullName(student)}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {student.email ?
                        <Link
                        style={{
                          textDecoration: 'none',
                          cursor: 'pointer',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                        onClick={() =>
                          openInNewTab(
                            'https://mail.google.com/mail/u/0/?tf=cm&fs=1&to=' +
                            student.email +
                            '&hl=en'
                          )
                        }
                        > {student.email}

                        </Link>
                        :
                        <Link
                        style={{
                          textDecoration: 'none',
                          cursor: 'pointer',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                        onClick={() =>
                          openInNewTab(
                            'https://mail.google.com/mail/u/0/?tf=cm&fs=1&to=' +
                            student.parents[0].email +
                            '&hl=en'
                          )
                        }
                        ><EscalatorWarningIcon /> {student.parents[0].email}

                        </Link>
                      }
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {getStatusLabel(student.status)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Edit Account" arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: "white"
                        }}
                        color="inherit"
                        size="small"
                        href={
                          `/management/student?user_id=` +
                          student._id
                        }
                      >
                        <EditTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {students.length < 5 ? <></> :
        <Box p={2}>
          <TablePagination
            component="div"
            count={filteredStudents.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 30]}
          />
        </Box>
      }
    </Card>
  );
};

AllStudentsTable.propTypes = {
  students: PropTypes.array.isRequired
};

AllStudentsTable.defaultProps = {
  students: []
};

export default AllStudentsTable;
