import axios from 'axios';
import { ContactEmailInterface } from 'src/models/emails';
import {
  Dispatcher,
  GetToken,
  SUBMITTING,
  SUCCESS,
  FAILED,
  Loading,
  Fail,
  Submitting,
  Success,
  LOADING
} from './LoadingActions';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { API_URL } from '../settings';
import { Tutor } from '../models/tutor';
import { EmailTemplate } from '../models/emailTemplate';
import { SetLessonsInvoiced } from './LessonActions';

const API_URL_FULL = API_URL + 'api/send-email/';


export type EmailTemplatesGetTypes = Loading | Fail | GetEmailTemplateSuccess;
export type EmailTemplateGetTypes = Loading | Fail | GetEmailTemplatesSuccess;
export type EmailSubmitTypes = Submitting | Fail | GetEmailTemplatesSuccess;


export interface GetEmailTemplateSuccess {
  type: typeof SUCCESS;
  payload: EmailTemplate[];
}

export interface GetEmailTemplatesSuccess {
  type: typeof SUCCESS;
  payload: EmailTemplate;
}


export const ContactUs =
  (lessonData: ContactEmailInterface, setData: Dispatcher<EmailSubmitTypes>) =>
  async () => {
    try {
      var templateParams = {
        from_name: lessonData.name,
        email: lessonData.email,
        phone: lessonData.phone,
        referred: lessonData.referred,
        child_name: lessonData.childName,
        child_age: lessonData.childAge,
        enquiring_about: lessonData.enquiringOption,
        details: lessonData.details
      };

      setData({
        type: SUBMITTING
      });

      emailjs
        .send(
          'service_aptetut',
          'template_contact',
          templateParams,
          'DbbE_PcGKioZg3ANV'
        )
        .then(
          (result) => {
            console.log(result.text);
            setData({
              type: 'Success',
              payload: null
            });
          },
          (error) => {
            console.log(error.text);
            setData({
              type: FAILED,
              message: error.message
            });
            return;
          }
        );
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

// Send payroll email
export const SendPayrollEmail =
  (user: Tutor, table: String, date: String, total: String) => async () => {
    try {
      // Get positing data
      const postData = {
        to:
          user.personal_email && user.personal_email != null
            ? user.personal_email
            : user.email,
        name: user.first_name,
        date: date,
        table: table,
        total: total
      };

      await axios.post(API_URL_FULL + 'payslip', postData);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log(error.response.data);
        }
      }
    }
  };

// Send invoice email
export const SendInvoiceEmail =
  (postData, setData: Dispatcher<EmailSubmitTypes>) => async () => {
    try {
      setData({
        type: SUBMITTING
      });

      const res = await axios.post(API_URL_FULL + 'invoice', postData);

      setData({
        type: SUCCESS,
        payload: res.data
      });

    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log(error.response.data);
        }
      }
    }
  };


// Send invoice emails
export const SendInvoiceEmails =
(postDataArray, setData: Dispatcher<EmailSubmitTypes>, setInvoiceSent: Dispatcher<string> ) => async () => {
  try {
    setData({
      type: SUBMITTING
    });

    const token = GetToken();
      
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    for (const element of postDataArray) {
      const res = await axios.post(API_URL_FULL + 'invoice', element);

      const postData = {
        student_id: element.studentId,
        date: new Date(),
        lessons: element.lessonIds
      };

      const res2 =await axios.post(API_URL + 'api/lessons/set-lessons-invoiced', postData, config);

      setInvoiceSent(element.studentId);
    }
    

    setData({
      type: SUCCESS,
      payload: null
    });

  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        console.log(error.response.data);
      }
    }
    setData({
      type: FAILED,
      message: 'Error'
    });
  }
};

//Add a new lesson
export const SendContactEmail =
  (lessonData: ContactEmailInterface, setData: Dispatcher<EmailSubmitTypes>) =>
  async () => {
    try {
      setData({
        type: SUBMITTING
      });

      const res = await axios.post(API_URL_FULL, lessonData);

      setData({
        type: SUCCESS,
        payload: res.data
      });

    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log(error.response.data);
        }

        setData({
          type: FAILED,
          message: error.message
        });
        return;
      }
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };


//Get all Email Templates
export const GetAllEmailTemplates =
  (setData: Dispatcher<EmailTemplatesGetTypes>) => async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: LOADING
      });

      const res = await axios.post(API_URL_FULL + 'get-all-email-templates',config);

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

  // Delete Email Templates
export const DeleteEmailTemplate =
(id : string, setData: Dispatcher<EmailTemplatesGetTypes>) => async () => {
  try {
    const token = GetToken();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    setData({
      type: LOADING
    });

    const res = await axios.post(API_URL_FULL + 'delete-email-template',id,config);

    setData({
      type: SUCCESS,
      payload: res.data
    });
  } catch (error) {
    setData({
      type: FAILED,
      message: 'Error'
    });
  }
};

// Update an Email Template
export const ChangeEmailTemplateStatus =
  (
    templateName : string,
    status : boolean,
    setData: Dispatcher<EmailSubmitTypes>
  ) =>
  async () => {
    try {
      // Get posting token
      const token = GetToken();

      // Create posting config
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      // Set the data status to submitting
      setData({
        type: SUBMITTING
      });

      // Create posting data
      const postData = {
        templateName: templateName,
        status: status,
      };

      const res = await axios.post(API_URL_FULL + 'set-email-template-status', postData, config);

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {

      setData({
        type: FAILED,
        message: error.response.data
      });
    }
  };


// Save an Email Template
export const SaveEmailTemplate =
  (
    name,
    json,
    html,
    setData: Dispatcher<EmailSubmitTypes>
  ) =>
  async () => {
    try {
      // Get posting token
      const token = GetToken();

      // Create posting config
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      // Set the data status to submitting
      setData({
        type: SUBMITTING
      });

      // Create posting data
      const postData = {
        name: name,
        json: json,
        html: html,
        active: false
      };

      const res =await axios.post(API_URL_FULL + 'save-email-template', postData, config);

      setData({
        type: SUCCESS,
        payload: res.data,
      });
    } catch (error) {

      setData({
        type: FAILED,
        message: error.response.data
      });
    }
  };
