import { Student } from 'src/models/student';
import { Tutor } from '../models/tutor';
//Capitalise words in a string
export const capitalizeWords = (str) => {
  return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
};

export const getStudentIDAndFullName = (student: Student) => {
  if (student.last_name == null || student.last_name == "" ) {
    return student.user_id + ": " + student.first_name + " [" + student.parents[0].last_name + "]";
  } else {
    return student.user_id + ": " + student.first_name + " " + student.last_name;
  }
}

export const getStudentFullName = (student: Student) => {
  if (student.last_name == null || student.last_name == "") {
    return student.first_name + " [" + student.parents[0].last_name + "]";
  } else {
    return student.first_name + " " + student.last_name;
  }
}

export const GetTutorFullName = (tutor: Tutor) => {
  return tutor.first_name + " " + tutor.last_name
}