import { useContext } from 'react';

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CategoryIcon from '@mui/icons-material/Category';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import Groups2Icon from '@mui/icons-material/Groups';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ArchiveIcon from '@mui/icons-material/Archive';
import Groups2TwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentsTwoToneIcon from '@mui/icons-material/PaymentsTwoTone';
import BugReportIcon from '@mui/icons-material/BugReport';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import BeachAccessTwoToneIcon from '@mui/icons-material/BeachAccessTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import FilterVintageTwoToneIcon from '@mui/icons-material/FilterVintageTwoTone';
import HowToVoteTwoToneIcon from '@mui/icons-material/HowToVoteTwoTone';
import LocalPharmacyTwoToneIcon from '@mui/icons-material/LocalPharmacyTwoTone';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import TrafficTwoToneIcon from '@mui/icons-material/TrafficTwoTone';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import ChromeReaderModeTwoToneIcon from '@mui/icons-material/ChromeReaderModeTwoTone';
import WorkspacePremiumTwoToneIcon from '@mui/icons-material/WorkspacePremiumTwoTone';
import CameraFrontTwoToneIcon from '@mui/icons-material/CameraFrontTwoTone';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TimelineIcon from '@mui/icons-material/Timeline';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';

import GroupIcon from '@mui/icons-material/Group';
import FaceIcon from '@mui/icons-material/Face';
import { GroupTwoTone } from '@mui/icons-material';
import { AccountPermissions, LoggedInUser } from 'src/models/loggedInUser';
import { bool } from 'aws-sdk/clients/signer';
import { GetAccountId, GetLocalUser } from 'src/actions/AuthActions';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
    'transform',
    'opacity'
  ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const SidebarMenu = (user: LoggedInUser) => {
  const { closeSidebar } = useContext(SidebarContext);

  const MenuItem = (
    title: string,
    url: string,
    internalURL: boolean,
    icon: React.ReactNode,
    permissions: AccountPermissions[]
  ) => {

    return (
      <>
        {user.permissions && permissions.some((r) => user.permissions.includes(r)) ? (
          <ListItem component="div">
            {internalURL ?
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={url}
                startIcon={icon}
              >
                {title}
              </Button>
              : <>
                <Button
                  disableRipple
                  onClick={closeSidebar}
                  startIcon={icon}
                  target="_blank"
                  href={url}
                >
                  {title}
                </Button>
              </>}
          </ListItem>
        ) : (
          <></>
        )}
      </>
    );
  };

  function showCategory(permissions: AccountPermissions[]) {
    return user.permissions && permissions.some((r) => user.permissions.includes(r));
  }

  const DevMenu = () => {
    return (
      <>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Components
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              {MenuItem(
                'Buttons',
                '/components/buttons',
                true,
                <BallotTwoToneIcon />,
                ['admin', 'tutor']
              )}
              {MenuItem(
                'Modals',
                '/components/modals',
                true,
                <BeachAccessTwoToneIcon />,
                ['admin', 'tutor']
              )}
              {MenuItem(
                'Accordions',
                '/components/accordions', true,
                <EmojiEventsTwoToneIcon />,
                ['admin', 'tutor']
              )}
              {MenuItem(
                'Tabs',
                '/components/tabs',
                true,
                <FilterVintageTwoToneIcon />,
                ['admin', 'tutor']
              )}
              {MenuItem(
                'Badges',
                '/components/badges',
                true,
                <HowToVoteTwoToneIcon />,
                ['admin', 'tutor']
              )}
              {MenuItem(
                'Tooltips',
                '/components/tooltips',
                true,
                <LocalPharmacyTwoToneIcon />,
                ['admin', 'tutor']
              )}
              {MenuItem(
                'Avatars',
                '/components/avatars',
                true,
                <RedeemTwoToneIcon />,
                ['admin', 'tutor']
              )}
              {MenuItem('Cards', '/components/cards', true, <SettingsTwoToneIcon />, [

                'admin',
                'tutor'
              ])}
              {MenuItem('Forms', '/components/forms', true, <TrafficTwoToneIcon />, [
                'admin',
                'tutor'
              ])}
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Extra Pages
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/status/404"
                  startIcon={<CheckBoxTwoToneIcon />}
                >
                  Error 404
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/status/500"
                  startIcon={<CameraFrontTwoToneIcon />}
                >
                  Error 500
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/status/coming-soon"
                  startIcon={<ChromeReaderModeTwoToneIcon />}
                >
                  Coming Soon
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/status/maintenance"
                  startIcon={<WorkspacePremiumTwoToneIcon />}
                >
                  Maintenance
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </>
    );
  };

  return (
    <>
      <MenuWrapper>


        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Dashboards
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              {MenuItem(
                'Summary',
                '/dashboards/main/', true,
                <DashboardIcon />,
                ['admin', 'tutor']
              )}
              {MenuItem(
                'Calendar',
                '/dashboards/calendar/', true,
                <CalendarMonthIcon />,
                ['admin', 'tutor']
              )}
              {MenuItem(
                'My Students',
                '/dashboards/students/', true,
                <Groups2Icon />,
                ['admin', 'tutor']
              )}
              {MenuItem(
                'My Profile',
                '/management/tutor?user_id=' + GetAccountId(), true,
                <AccountBoxRoundedIcon />,
                ['tutor']
              )}
            </List>
          </SubMenuWrapper>
        </List>

        {showCategory(['manager', 'admin']) && <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Accounting
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              {MenuItem(
                'Receivable',
                '/dashboards/receivables/', true,
                <LocalAtmIcon />,
                ['admin', 'manager']
              )}
              {MenuItem(
                'Payroll',
                '/dashboards/payroll/', true,
                <AccountBalanceIcon />,
                ['admin', 'manager']
              )}
              {MenuItem(
                'Expenses',
                '/dashboards/expenses/', true,
                <RequestQuoteIcon />,
                ['admin', 'manager']
              )}
              {MenuItem(
                'Reports',
                '/dashboards/reports/', true,
                <TimelineIcon />,
                ['admin', 'manager']
              )}
            </List>
          </SubMenuWrapper>
        </List>}


        {showCategory(['admin']) && <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Management
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              {MenuItem('Tutors', '/dashboards/all-tutors/', true, <FaceIcon />, [
                'admin'
              ])}
              {MenuItem(
                'Students',
                '/dashboards/all-students/', true,
                <Groups2TwoToneIcon />,
                ['admin']
              )}
              {MenuItem(
                'Leads',
                '/dashboards/leads/', true,
                <GroupAddIcon />,
                ['admin']
              )}              
              {MenuItem(
                'Email Editor',
                '/dashboards/email-editor/', true,
                <ViewQuiltIcon />,
                ['admin']
              )}
            </List>
          </SubMenuWrapper>
        </List>}

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Links
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              {MenuItem(
                'Wiki',
                'https://sites.google.com/aptetut.com/aptetutwiki?pli=1', false,
                <MenuBookIcon />,
                ['tutor']
              )}
              {MenuItem(
                'References',
                'https://docs.google.com/spreadsheets/d/1X_4-b02CS9vZMVtjPy1B4Uv-rP42jBq8mJckPqK7LNY/edit#gid=2009451414', false,
                <CategoryIcon />,
                ['tutor']
              )}
              {MenuItem(
                'Issues',
                'https://app.hacknplan.com/p/190888/kanban?categoryId=0&boardId=522034', false,
                <BugReportIcon />,
                ['tutor']
              )}
            </List>
          </SubMenuWrapper>
        </List>

        {/* {user.permissions && user.permissions.includes('admin') ? <DevMenu /> : <></>} */}
      </MenuWrapper>
    </>
  );
};

export default SidebarMenu;
