import { FC, ChangeEvent, useState } from 'react';
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  Link,
  TextField,
  IconButton,
  Tooltip,
  Button
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Label from 'src/components/Label';
import { StudentAccountStatus } from 'src/models/student';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { StudentGroup } from 'src/models/tutor';
import { Payment } from 'src/models/payment';
import {
  formatDateDayMonthYear,
  formatDateWeekdayDayMonth,
  formatDateDayMonth
} from 'src/tools/dateTools';
import MakeExpenseButton from './MakeExpenseButton';
import { ExpenseType, ExpenseTypes } from './Expenses';
import { capitalizeWords } from 'src/tools/stringTools';

interface TableProps {
  payments?: Payment[];
}

interface Filters {
  expense?: string;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#1b243e'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const ExpensesTable: FC<TableProps> = ({ payments: payments }) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [userSearch, setUserSearch] = useState<string>();
  const [filters, setFilters] = useState<Filters>({
    expense: 'all'
  });

  const theme = useTheme();

  const expenseOptions = ExpenseTypes.map((expense) => ({
    id: expense,
    name: capitalizeWords(expense)
  }));
  const filteredPayments = payments
    .filter((item) => item.category == 'expense' || item.category == 'refund')
    .sort((a, b) => (a.payment_date < b.payment_date ? 1 : -1));

  const handleExpenseChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const createCsv = () => {
    const rows = filteredPayments
      .sort((a, b) => (a.payment_date < b.payment_date ? 1 : -1))
      .flatMap((p) => [
        [
          p._id,
          new Date(p.payment_date).toISOString(),
          p.payment_amount,
          p.category,
          p.receipt_number,
          p.note,
          p.type
        ]
      ]);

    // prepend a header row
    rows.unshift([
      'ID',
      'Date',
      'Amount',
      'Category',
      'Receipt',
      'Notes',
      'Expense Type'
    ]);

    const csvContent =
      'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'Expenses.csv');
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  const handleIDSearch = (event) => {
    if (event.target.value.length > 0) {
      setPage(0);
    }
    setUserSearch(event.target.value);
  };

  return (
    <Card>
      <CardHeader
        action={
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', paddingRight: 2 }}>
              <Button onClick={createCsv} variant="contained" color="primary">
                Download CSV
              </Button>
            </Box>
            <Box marginRight={1}>
              <MakeExpenseButton />
            </Box>
            <Box width={150} marginRight={2}>
              <TextField
                id="outlined-basic"
                label="Search"
                variant="outlined"
                onChange={handleIDSearch}
              />
            </Box>
            <Box width={150} marginRight={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Expense Type</InputLabel>
                <Select
                  value={filters.expense || 'all'}
                  onChange={handleExpenseChange}
                  label="Status"
                  autoWidth
                >
                  <MenuItem key="all" value="all">
                    All
                  </MenuItem>
                  {expenseOptions.map((expenseOption) => (
                    <MenuItem key={expenseOption.id} value={expenseOption.id}>
                      {expenseOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        }
        title="Expenses"
      />

      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  DATE
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  TYPE
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  NOTES
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  AMOUNT
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPayments.map((payment, index) => {
              return (
                <StyledTableRow key={index}>
                  <TableCell>
                    <Tooltip title={'id: ' + payment._id} arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: 'grey'
                        }}
                        color="inherit"
                        size="small"
                      >
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {formatDateWeekdayDayMonth(payment.payment_date)}
                  </TableCell>
                  <TableCell>{payment.type}</TableCell>
                  <TableCell>{payment.note}</TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      ${payment.payment_amount}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ExpensesTable;
