import { FC, useEffect, useState } from 'react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  Tooltip,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  useTheme,
  Paper,
  tableCellClasses,
  Box,
  Collapse,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Checkbox,
  ListItemButton,
  ListItemText,
  Grid,
  TableFooter,
  CardHeader,
  CardContent,
  Divider,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/lab';

// Icons
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import PaidSharpIcon from '@mui/icons-material/PaidSharp';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import OutboxIcon from '@mui/icons-material/Outbox';
import SendIcon from '@mui/icons-material/Send';

// Actions
import { GetLocalUser } from 'src/actions/AuthActions';
import {
  LessonSubmitTypes,
  MakeLessonPayment
} from 'src/actions/LessonActions';

// Models
import { Student } from 'src/models/student';
import { Lesson } from 'src/models/lesson';

// Tools
import {
  daysSinceDate,
  formatDateDayMonthYear,
  formatDateWeekdayDayMonth
} from 'src/tools/dateTools';
import { capitalizeWords } from 'src/tools/stringTools';
import { ColoredCircle } from 'src/tools/formatting';
import { getStudentIDAndFullName } from 'src/tools/stringTools';

// Buttons and Dialogues
import { DownloadInvoiceButton } from '../../applications/Reports/StudentInvoiceButton';
import PurchaseCreditsButton from './PurchaseCreditsButton';
import { EditLessonDialogue } from './EditLessonDialogue';
import { SendInvoiceDialogue } from './SendInvoiceDialogue';
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableRowSub
} from 'src/theme/stylings';

// Interfaces
interface TableProps {
  allLessons: Lesson[];
}

export interface LessonGroup {
  student: Student;
  name: string;
  last_time_invoiced: Date | null;
  total_hours: number;
  total_invoiced: number;
  lessonList: Lesson[];
}

// Main Table
const UnpaidLessonsTable: FC<TableProps> = ({ allLessons: allLessons }) => {
  //Edit Lesson stuff
  const [openEditLessonDialogue, setOpenEditLessonDialogue] = useState(false);
  const [showSendInvoiceEmail, setShowSendInvoiceEmail] = useState<boolean>(false);
  const [editLessonLesson, setEditLessonLesson] = useState<Lesson>();

  const selectLesson = (lesson: Lesson) => {
    setOpenEditLessonDialogue(true);
    setEditLessonLesson(lesson);
  };

  const [sentInvoiceEmail, setSentInvoiceEmail] = useState<string>();
  const [openRows, setOpenRows] = useState([]);
  const [saveLessonData, setSaveLessonData] = useState<LessonSubmitTypes>();
  const [selectedLessonGroup, setSelectedLessonGroup] = useState<LessonGroup>();
  const [openDialogue, setOpenDialogue] = useState(false);
  const [lessonsChecked, setLessonChecked] = useState([]);
  const [studentLessons, setStudentLessons] = useState('');
  const [userSearch, setUserSearch] = useState<string>();

  // Turn lesson list into lesson groups for the table
  const convertToLessonGroups = (lessons : Lesson[]) : LessonGroup[] => lessons.reduce(
    (result: LessonGroup[], lesson: Lesson) => {

      if(lesson.status == 'completed' && !lesson.paid_date){
        const existingLessonGroup = result.find(
          (lessonGroup) => lessonGroup.student._id == lesson.student._id
        );
  
        if (existingLessonGroup) {
          existingLessonGroup.lessonList.push(lesson);
          existingLessonGroup.total_hours += lesson.duration;
          existingLessonGroup.total_invoiced +=
            lesson.duration * lesson.hourly_rate;
  
        } else {
          result.push({
            student: lesson.student,
            name: lesson.student.first_name + ' ' + lesson.student.last_name,
            last_time_invoiced: lesson.student.last_invoiced,
            total_hours: lesson.duration,
            total_invoiced: lesson.duration * lesson.hourly_rate,
            lessonList: [lesson]
          });
        }
      }
      return result;
    },
    []
  );

  const [lessonGroups, setLessonGroups] = useState<LessonGroup[]>(convertToLessonGroups(allLessons));
  const [autoSendSelectedLessonGroups, setAutoSendSelectedLessonGroups] = useState<LessonGroup[]>([]);

  const [autoSend, setAutoSend] = useState(false);
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const [creditsUsed, setCreditsUsed] = useState(0);
  const [paymentNote, setPaymentNote] = useState('');
  const [paymentReceiptNumber, setPaymentReceiptNumber] = useState('');
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<{
    date: string;
    note: string;
    receiptNumber: string;
    amountPaid: string;
  }>({
    date: '',
    note: '',
    receiptNumber: '',
    amountPaid: ''
  });

  const sortedLessons = allLessons.filter((item) => item.status == 'completed');

  const updateErrorState = (field, message) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: message
    }));
  };

  const navigate = useNavigate();
  const color = '#ffffff';
  const theme = useTheme();

  const earliestDate = (lessons: Lesson[]): Date | null => {
    if (lessons.length === 0) {
      return null; // Return null if the array is empty
    }

    return lessons.reduce((minDate: Date, currentLesson) => {
      const currentDate: Date = new Date(currentLesson.date);
      if (!minDate || currentDate < minDate) {
        return currentDate;
      }
      return minDate;
    }, null);
  };

  const receiptNeeded = !(creditsUsed > 0 && totalPaymentAmount == 0);

  const onCreditsChange = (event) => {
    let value: number = event.target.value;
    value = Math.round(value);
    if (value < 0) {
      value = 0;
    }
    const minHours = Math.min(selectedLessonGroup.student.credits, totalHours);
    if (value > minHours) {
      value = minHours;
    }
    setCreditsUsed(value);
  };

  const onPaymentValueChange = (event) => {
    setTotalPaymentAmount(event.target.value);
  };

  const onNoteChange = (event) => {
    setPaymentNote(event.target.value);
  };

  const onReceiptNumberChange = (event) => {
    setPaymentReceiptNumber(event.target.value);
  };

  //Validate form
  const handleValidation = () => {
    let formIsValid = true;
    const totalPay = calculateTotalPaymentAmount(
      selectedLessonGroup.student.credits
    );

    if (lessonsChecked.length == 0) {
      formIsValid = false;
      updateErrorState('amountPaid', 'There are no lessons checked');
    } else if (!paymentDate || paymentDate > new Date()) {
      formIsValid = false;
      updateErrorState('date', "Date can't be in the future");
    } else if (
      receiptNeeded &&
      (!paymentReceiptNumber || paymentReceiptNumber.length < 5)
    ) {
      formIsValid = false;
      updateErrorState('receiptNumber', 'Please enter a receipt number');
    } else if (totalPaymentAmount > totalPay.totalCost) {
      formIsValid = false;
      updateErrorState(
        'amountPaid',
        "You can't pay more than what is invoiced"
      );
    } else if (
      totalPaymentAmount < totalPay.totalCost &&
      paymentNote.length < 5
    ) {
      formIsValid = false;
      updateErrorState(
        'note',
        'Please enter a note why the payment is for less money than the invoiced amounts'
      );
    } else {
      updateErrorState('date', '');
      updateErrorState('receiptNumber', '');
      updateErrorState('amountPaid', '');
      updateErrorState('note', '');
    }

    return formIsValid;
  };

  // Toggle selecting lessons
  const handleToggleLesson = (value: string) => () => {
    const currentIndex = lessonsChecked.indexOf(value);
    const newChecked = [...lessonsChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setLessonChecked(newChecked);
  };

  const handleSubmitDialogue = () => {
    if (!handleValidation()) {
      return;
    }

    const postData = {
      paid_date: paymentDate,
      payment_amount: totalPaymentAmount,
      receipt_number: receiptNeeded ? paymentReceiptNumber : 'Credits Used',
      credits: creditsUsed,
      processed_by: GetLocalUser()._id,
      note: paymentNote,
      student: studentLessons,
      lessons: lessonsChecked
    };

    dispatch<any>(MakeLessonPayment(postData, setSaveLessonData));
  };

  useEffect(() => {
    if (saveLessonData?.type === 'Success') {
      window.location.reload();
    }
  }, [saveLessonData]);

  const totalHours = selectedLessonGroup
    ? sortedLessons
        .filter(
          (lesson) =>
            !lesson.paid_date &&
            lesson.student._id == selectedLessonGroup.student._id
        )
        .reduce((total, lesson) => total + lesson.duration, 0)
    : 0;

  // On and close dialogue
  const handleOpenDialogue = (lessonGroup: LessonGroup) => {
    setSelectedLessonGroup(lessonGroup);

    setLessonChecked(generateArrayOfLessonIds(lessonGroup.student._id));
    setOpenDialogue(true);

    setStudentLessons(lessonGroup.student._id);
    setCreditsUsed(
      lessonGroup.student.credits
        ? Math.min(lessonGroup.student.credits, totalHours)
        : 0
    );
    updateErrorState('date', '');
    updateErrorState('receiptNumber', '');
    updateErrorState('amountPaid', '');
    updateErrorState('note', '');
    setPaymentReceiptNumber('');
    setPaymentNote('');
    setPaymentDate(new Date());
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  // Generate initial array of lesson ids
  function generateArrayOfLessonIds(student_id: string) {
    return sortedLessons
      .filter((lesson) => !lesson.paid_date && lesson.student._id == student_id)
      .map((lesson) => lesson._id);
  }

  // Detect change of selected lessons
  useEffect(() => {
    if (!selectedLessonGroup) {
      return;
    }
    const hourCalculation = calculateTotalPaymentAmount(
      selectedLessonGroup.student.credits
    );

    setCreditsUsed(hourCalculation.usedCredits);

    setTotalPaymentAmount(hourCalculation.totalCost);
  }, [lessonsChecked]);

  function calculateTotalPaymentAmount(creditsUsed) {
    let remainingPrepaidHours = creditsUsed;

    let totalCost = lessonsChecked.reduce((total, lessonId) => {
      const checkedLesson = sortedLessons.find(
        (lesson) => lesson._id === lessonId
      );

      if (checkedLesson) {
        let lessonDuration = checkedLesson.duration;

        // Check if there are remaining prepaid hours to be used
        if (remainingPrepaidHours > 0) {
          if (remainingPrepaidHours >= lessonDuration) {
            // Subtract the entire lesson duration from prepaid hours
            remainingPrepaidHours -= lessonDuration;
            lessonDuration = 0;
          } else {
            // Subtract only the remaining prepaid hours
            lessonDuration -= remainingPrepaidHours;
            remainingPrepaidHours = 0;
          }
        }

        // Calculate the cost for the lesson after considering prepaid hours
        total += lessonDuration * checkedLesson.hourly_rate;
      }

      return total;
    }, 0);

    let usedCredits = creditsUsed - remainingPrepaidHours;

    return { totalCost, usedCredits };
  }

  const getDaysOverdue = (date) => {
    if (!date) {
      return <Typography color="grey"> - </Typography>;
    }

    const daysPast: number = daysSinceDate(date);

    if (daysPast > 30) {
      return <Typography color="error"> {daysPast} Days</Typography>;
    } else if (daysPast > 14) {
      return <Typography color="primary"> {daysPast} Days </Typography>;
    }

    return <></>;
  };

  const onInfoButtonClick = (value: String) => {
    console.log('copied: ' + value);
  };


  useEffect(() => {
    
    if(sentInvoiceEmail == null || sentInvoiceEmail == ""){
      return;
    }

    const updatedLessonGroups = lessonGroups.map((lessonGroup) => {
      if (lessonGroup.student._id == sentInvoiceEmail) {
        
        return {
          ...lessonGroup,
          last_time_invoiced: new Date(),
        };
      }
      return lessonGroup;
    });

    //setLessonGroups([]);
   setLessonGroups(updatedLessonGroups);

  }, [sentInvoiceEmail]);

  // useEffect(() => {
    
  //   console.log(lessonGroups);
  // }, [lessonGroups]);

  // Finish sending emails
  const onCloseSendInvoiceDialogue = () => {
    setAutoSendSelectedLessonGroups([]);
    setShowSendInvoiceEmail(false);
  };

  const getDaysPastDisplay = (date : Date) => {
    if (!date) {
      return <Typography color="grey"> - </Typography>;
    }

    const daysPast: number = daysSinceDate(date);

    let outputString = daysPast + ' Days';

    if (daysPast > 7) {
      outputString = '> Week';
    } else if (daysPast > 31) {
      outputString = '> Month';
    }

    if (daysPast > 5) {
      return <Typography color="primary"> {outputString} </Typography>;
    } else {
      return <Typography color="grey"> {outputString} </Typography>;
    }
  };

  // Calculate total hours from selected lessons
  function calculateTotalHours() {
    return lessonsChecked.reduce((total, lessonId) => {
      const checkedLesson: Lesson = sortedLessons.find(
        (lesson) => lesson._id === lessonId
      );
      return total + (checkedLesson ? checkedLesson.duration : 0);
    }, 0);
  }

  // Sort the lessons by date order
  const sortedLessonList = selectedLessonGroup
    ? selectedLessonGroup.lessonList.slice().sort((a, b) => {
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;
        return 0;
      })
    : [];

  // On clicking a row to open it
  const openRowClick = (rowID: string) => {
    if (openRows.includes(rowID)) {
      setOpenRows((prevArray) => prevArray.filter((item) => item !== rowID));
      return;
    }
    setOpenRows((prevArray) => [...prevArray, rowID]);
  };

  // Auto send
  const onToggleAutoSend = (lessonGroup : LessonGroup) => {
    if(autoSendSelectedLessonGroups.find((item) => item.student._id === lessonGroup.student._id)){
      setAutoSendSelectedLessonGroups((prevArray) =>
        prevArray.filter((item) => item.student._id !== lessonGroup.student._id)
      );
    }else{
      setAutoSendSelectedLessonGroups((prevArray) => [...prevArray, lessonGroup]);
    }
  }

  const setSingleAutoSend = (lessonGroup : LessonGroup) => {
    setAutoSendSelectedLessonGroups([lessonGroup]);
    setShowSendInvoiceEmail(true);
  }

  const setEnableAutoSend = () => {
    if(autoSend){
      setAutoSend(false);
      setAutoSendSelectedLessonGroups([]);
    }else{
      setAutoSend(true);
      setAutoSendSelectedLessonGroups(lessonGroups);
    }
  };

  const showSendDialogue = () => {

    if(autoSendSelectedLessonGroups.length == 0){
      return;
    }

    setShowSendInvoiceEmail(true);
  }

  //Each sub row
  const Row = (lessonGroup: LessonGroup) => {
    const credits = lessonGroup.student.credits
      ? lessonGroup.student.credits
      : 0;

    return (
      <React.Fragment>
        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
            <IconButton
              aria-label="expand row"
              size="small"
              color="primary"
              onClick={() => openRowClick(lessonGroup.student._id)}
            >
              {openRows.includes(lessonGroup.student._id) ? (
                <KeyboardArrowDownSharpIcon />
              ) : (
                <KeyboardArrowRightSharpIcon />
              )}
            </IconButton>
          </TableCell>
          <TableCell>
            <Link
              style={{ color: 'white', textDecoration: 'none' }}
              to={'/management/student?user_id=' + lessonGroup.student._id}
            >
              {getStudentIDAndFullName(lessonGroup.student)}
            </Link>{' '}
          </TableCell>
          <TableCell align="right">{lessonGroup.lessonList.length}</TableCell>
          <TableCell align="right">{lessonGroup.total_hours}</TableCell>
          <TableCell align="right">${lessonGroup.total_invoiced}</TableCell>
          <TableCell color="primary" align="right">
            {credits > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <ColoredCircle>{credits}</ColoredCircle>
                <ColoredCircle color="#13ac3c">$</ColoredCircle>
              </Box>
            )}
          </TableCell>
          <TableCell align="right">
            {getDaysOverdue(earliestDate(lessonGroup.lessonList))}
          </TableCell>
          <TableCell align="right">
            {getDaysPastDisplay(lessonGroup.last_time_invoiced)}
          </TableCell>
          <TableCell>
            {autoSend ? (
              <>
            <Checkbox
              checked={autoSendSelectedLessonGroups.find((item) => item.student._id === lessonGroup.student._id) != null}
              onChange={() => onToggleAutoSend(lessonGroup)}
              size="small"
              disableRipple
              inputProps={{ 'aria-label': 'controlled' }}
            />
            
              </>
            ) : (
              <>
                <DownloadInvoiceButton
                  lessons={lessonGroup.lessonList}
                  student={lessonGroup.student}
                />
                <Tooltip title={'Send Email'} arrow>
                  <IconButton
                    sx={{
                      '&:hover': {
                        background: theme.colors.primary.lighter
                      },
                      color: 'white'
                    }}
                    color="inherit"
                    size="small"
                    onClick={() => setSingleAutoSend(lessonGroup)}
                  >
                    <ForwardToInboxIcon fontSize="small" />
                  </IconButton>
                </Tooltip>

                <Tooltip title={'Receive Payment'} arrow>
                  <IconButton
                    sx={{
                      '&:hover': {
                        background: theme.colors.primary.lighter
                      },
                      color: '#40ba50'
                    }}
                    color="inherit"
                    size="small"
                    onClick={() => handleOpenDialogue(lessonGroup)}
                  >
                    <PaidSharpIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </TableCell>
        </StyledTableRow>
        <StyledTableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <Collapse
              in={openRows.includes(lessonGroup.student._id)}
              timeout="auto"
              unmountOnExit
            >
              <Box sx={{ margin: 1 }}>
                <Table size="medium" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>Date</StyledTableCell>
                      <StyledTableCell>Tutor</StyledTableCell>
                      <StyledTableCell>Lesson Type</StyledTableCell>
                      <StyledTableCell align="right">Duration</StyledTableCell>
                      <StyledTableCell align="right">
                        Hourly Rate
                      </StyledTableCell>
                      <StyledTableCell align="right">Total</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lessonGroup.lessonList.map((lesson, index) => (
                      <StyledTableRowSub key={index}>
                        <StyledTableCell>
                          <Tooltip title={'id: ' + lesson._id} arrow>
                            <IconButton
                              sx={{
                                '&:hover': {
                                  background: theme.colors.primary.lighter
                                },
                                color: 'grey'
                              }}
                              color="inherit"
                              size="small"
                              onClick={() => onInfoButtonClick(lesson._id)}
                            >
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {formatDateDayMonthYear(lesson.date)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {lesson.tutor.first_name} {lesson.tutor.last_name}
                        </StyledTableCell>
                        <StyledTableCell>
                          {capitalizeWords(lesson.type)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {lesson.duration}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          ${lesson.hourly_rate}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          ${lesson.duration * lesson.hourly_rate}
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            sx={{
                              '&:hover': {
                                background: theme.colors.primary.lighter
                              },
                              color: 'grey'
                            }}
                            color="inherit"
                            size="small"
                            onClick={() => selectLesson(lesson)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRowSub>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  };

  const filteredLessonGroups = (groups : LessonGroup[]): LessonGroup[] => {

    if(userSearch == null || userSearch?.length == 0){
      return groups;
    }

    return groups.filter((lessonGroup) => {
      // let [matches] = true;
      if (userSearch) {
        const lowerSearch = userSearch.toLocaleLowerCase();
        return (
          lessonGroup.student.first_name.toLowerCase().includes(lowerSearch) ||

          (lessonGroup.student.last_name&& lessonGroup.student.last_name.toLowerCase().includes(lowerSearch)) ||
          lessonGroup.student.user_id.toString().includes(lowerSearch) ||
          (lessonGroup.student.parents?.length >0 &&
            lessonGroup.student.parents[0].last_name
              .toLowerCase()
              .includes(lowerSearch))
        );
      }
    });
  };

  const totalListedInvoiced: number = lessonGroups.reduce(
    (accumulator, lessonGroup) => {
      return accumulator + lessonGroup.total_invoiced;
    },
    0
  );

  const totalListedHours: number = lessonGroups.reduce(
    (accumulator, lessonGroup) => {
      return accumulator + lessonGroup.total_hours;
    },
    0
  );

  const totalListedLessons: number = lessonGroups.reduce(
    (accumulator, lessonGroup) => {
      return accumulator + lessonGroup.lessonList.length;
    },
    0
  );

  const handleIDSearch = (event) => {
    setUserSearch(event.target.value);
  };

  return (
    <>
      <Card sx={{ marginTop: '10px' }}>
        <CardHeader
          title="Unpaid Lessons"
          action={
            <Box sx={{ display: 'flex' }}>
              <Box width={150} marginRight={2}>
                <TextField
                  id="outlined-basic"
                  label="Search"
                  variant="outlined"
                  onChange={handleIDSearch}
                />
              </Box>

              <Box width={150} marginRight={2}>
                <PurchaseCreditsButton label="Purchased Hour Credits" />
              </Box>
            </Box>
          }
        />

        <Divider />
        <CardContent>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <StyledTableCell />
                  <StyledTableCell>Student</StyledTableCell>
                  <StyledTableCell align="right">Lessons</StyledTableCell>
                  <StyledTableCell align="right">Hours</StyledTableCell>
                  <StyledTableCell align="right">Total</StyledTableCell>
                  <StyledTableCell align="right">Credits</StyledTableCell>
                  <StyledTableCell align="right">Overdue</StyledTableCell>
                  <StyledTableCell align="right">Last Invoice</StyledTableCell>
                  <StyledTableCell>
                    <Tooltip title={autoSend?'Disable Auto Send':'Enable Auto Send'} arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: !autoSend ? '#f3ac3c' : 'grey'
                        }}
                        color="inherit"
                        size="small"
                        onClick={setEnableAutoSend}
                      >
                        <OutboxIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    {autoSend &&
                    <Tooltip title={'Send mass invoices'} arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: autoSendSelectedLessonGroups.length > 0? '#f3ac3c': 'grey'
                        }}
                        color="inherit"
                        size="small"
                        onClick={showSendDialogue}
                      >
                        <SendIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    }
                  </StyledTableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {filteredLessonGroups(lessonGroups).map((row, index) => (
                  <Row
                    key={index}
                    student={row.student}
                    name={row.name}
                    last_time_invoiced={row.last_time_invoiced}
                    total_hours={row.total_hours}
                    total_invoiced={row.total_invoiced}
                    lessonList={row.lessonList}
                  />
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <StyledTableCell />
                  <StyledTableCell align="right">Totals:</StyledTableCell>
                  <StyledTableCell align="right">
                    {totalListedLessons}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {totalListedHours}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    ${totalListedInvoiced}
                  </StyledTableCell>
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <SendInvoiceDialogue
        showDialogue={showSendInvoiceEmail}
        lessonGroups={autoSendSelectedLessonGroups}
        onClose={onCloseSendInvoiceDialogue}
        onSendEmail={setSentInvoiceEmail}
      />

      <EditLessonDialogue
        isOpen={openEditLessonDialogue}
        lesson={editLessonLesson}
        onClose={setOpenEditLessonDialogue}
        isAdmin={true}
      />

      <Dialog fullWidth open={openDialogue} onClose={handleCloseDialogue}>
        <DialogTitle>Receive Payment</DialogTitle>
        <DialogContent>
          {selectedLessonGroup ? (
            <>
              <Grid container spacing={0}>
                <Grid item xs={8} container alignItems="center">
                  <Typography variant="h3">
                    {getStudentIDAndFullName(selectedLessonGroup.student)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <DatePicker
                    label="Lesson Date"
                    onChange={setPaymentDate}
                    value={paymentDate}
                    PaperProps={{
                      sx: {
                        svg: { color: 'white' },
                        span: { color: 'white' }
                      }
                    }}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          style={{ marginRight: '10px' }}
                          sx={{
                            svg: { color }
                          }}
                          margin="dense"
                          id="date"
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <List>
                    {sortedLessonList.map((lesson, index) => (
                      <ListItem
                        key={index}
                        onClick={handleToggleLesson(lesson._id)}
                        secondaryAction={
                          <Checkbox
                            edge="end"
                            onChange={handleToggleLesson(lesson._id)}
                            checked={lessonsChecked.includes(lesson._id)}
                          />
                        }
                        disablePadding
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? '#3e4d70' : '#2b3857' // Alternate background colors
                        }}
                      >
                        <ListItemButton>
                          <ListItemText
                            id="0"
                            primary={formatDateWeekdayDayMonth(lesson.date)}
                            secondary={
                              lesson.duration +
                              ' hours - total: $' +
                              lesson.duration * lesson.hourly_rate
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item xs={3} container alignItems="center">
                  {creditsUsed ? (
                    <Typography variant="h3" align="right" color="primary">
                      Credits Used: {creditsUsed}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={3} container alignItems="center">
                  <Typography variant="h3" align="right">
                    Total Hours: {calculateTotalHours()}
                  </Typography>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4} paddingRight={0.5}>
                  <TextField
                    margin="dense"
                    id="amount"
                    name="amount"
                    value={totalPaymentAmount}
                    error={errors.amountPaid.length > 0}
                    helperText={
                      errors.amountPaid.length > 0 ? errors.amountPaid : ''
                    }
                    onChange={onPaymentValueChange}
                    label="Payment"
                    type="number"
                    fullWidth={true}
                    required={true}
                  />
                </Grid>

                {receiptNeeded ? (
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="receipt"
                      required={true}
                      name="receipt"
                      label="Receipt Number"
                      type="text"
                      fullWidth={true}
                      onChange={onReceiptNumberChange}
                      value={paymentReceiptNumber}
                      error={errors.receiptNumber.length > 0}
                      helperText={
                        errors.receiptNumber.length > 0
                          ? errors.receiptNumber
                          : ''
                      }
                    />
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    id="notes"
                    label="Notes"
                    type="text"
                    name="notes"
                    multiline={true}
                    onChange={onNoteChange}
                    value={paymentNote}
                    error={errors.note.length > 0}
                    helperText={errors.note.length > 0 ? errors.note : ''}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </DialogContent>
        {saveLessonData?.type == 'Submitting' ||
        saveLessonData?.type == 'Success' ? (
          <CircularProgress
            size={24}
            sx={{
              color: 'white',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px'
            }}
          />
        ) : (
          <DialogActions>
            <Button onClick={handleCloseDialogue}>Cancel</Button>
            <Button onClick={handleSubmitDialogue}>Confirm</Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default UnpaidLessonsTable;
