import {
  Box,
  Typography,
  Card,
  Grid,
  Button,
  CardContent,
  TextField,
  Autocomplete,
  CircularProgress
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';
import SchoolIcon from '@mui/icons-material/School';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { Tutor } from 'src/models/tutor';
import { UpdatePassword } from './UpdatePassword';
import { timezones } from 'src/tools/dateTools';
import { IsManagerOrAdmin } from 'src/tools/validation';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import {
  TutorSubmitTypes,
  UpdateTutorBasicDetails
} from 'src/actions/TutorActions';
import { useDispatch } from 'react-redux';

export type InfoCardProps = {
  tutor: Tutor;
};

const TutorInfoCard: FC<InfoCardProps> = ({ tutor: tutor }) => {
  //const [displayTutor,setDisplayTutor]= useState<Tutor>(tutor);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [tutorSubmitData, setTutorSubmitData] = useState<TutorSubmitTypes>();

  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const dispatch = useDispatch();

  //Form Data
  const [formData, setFormData] = useState({
    phone: tutor.phone,
    email: tutor.email,
    personalEmail: tutor.personal_email,
    workingWithChildrenNo: tutor.working_with_children_no
  });

  const [errors, setErrors] = useState<{
    phone: string;
    email: string;
    personalEmail: string;
    workingWithChildrenID: string;
  }>({
    phone: '',
    email: '',
    personalEmail: '',
    workingWithChildrenID: ''
  });

  useEffect(() => {
    if (tutorSubmitData?.type == 'Success') {
      setEditMode(false);
      window.location.reload();
    }
  }, [tutorSubmitData]);

  const onEditModePress = () => {
    setFormData(setDefaultFormData);
    setSelectedTimezone(tutor.timezone);
    setEditMode(true);
  };

  const onCancelEditModePress = () => {
    setEditMode(false);
  };

  const onConfirmButtonPress = () => {
    dispatch<any>(
      UpdateTutorBasicDetails(
        tutor._id,
        formData.phone,
        formData.email,
        formData.personalEmail,
        selectedTimezone.value,
        formData.workingWithChildrenNo,
        setTutorSubmitData
      )
    );
  };

  const handleTimezoneChange = (event, newValue) => {
    console.log(newValue);
    setSelectedTimezone(newValue);
  };

  const setDefaultFormData = () => {
    return {
      phone: tutor.phone,
      email: tutor.email,
      personalEmail: tutor.personal_email,
      workingWithChildrenNo: tutor.working_with_children_no
    };
  };

  // This function is called when the input changes
  const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  return (
    <>
      <Card>
        <Card sx={{ margin: '10px', padding: '10px' }}>
          <Typography variant="h3">
            {tutor.first_name} {tutor.last_name}
          </Typography>
        </Card>
        <Card sx={{ margin: '10px' }}>
          <CardContent>
            <Box>
              {editMode ? (
                <>
                  <TextField
                    sx={{ flex: 1 }}
                    margin="dense"
                    id="phone"
                    label="Phone"
                    variant="outlined"
                    name="phone"
                    fullWidth={true}
                    required={true}
                    error={errors.phone.length > 0}
                    helperText={
                      errors.phone.length > 0 ? errors.phone.length : ''
                    }
                    value={formData.phone}
                    onChange={onChanged}
                  />
                </>
              ) : (
                <Typography
                  variant="subtitle1"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <PhoneIcon sx={{ marginRight: '8px' }} />
                  {tutor.phone && tutor.phone}
                </Typography>
              )}

              {editMode ? (
                <>
                  {IsManagerOrAdmin() && (
                    <>
                      <br />
                      <TextField
                        sx={{ flex: 1 }}
                        margin="dense"
                        id="email"
                        fullWidth={true}
                        label="Email"
                        variant="outlined"
                        name="email"
                        required={true}
                        error={errors.email.length > 0}
                        helperText={
                          errors.email.length > 0 ? errors.email.length : ''
                        }
                        value={formData.email}
                        onChange={onChanged}
                      />
                    </>
                  )}
                </>
              ) : (
                <Typography
                  variant="subtitle1"
                  sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                >
                  <EmailIcon sx={{ marginRight: '8px' }} />
                  {tutor.email && tutor.email}
                </Typography>
              )}

              {editMode ? (
                <>
                  <br />
                  <TextField
                    sx={{ flex: 1 }}
                    margin="dense"
                    id="personalEmail"
                    fullWidth={true}
                    label="Personal Email"
                    variant="outlined"
                    name="personalEmail"
                    required={true}
                    error={errors.personalEmail.length > 0}
                    helperText={
                      errors.personalEmail.length > 0
                        ? errors.personalEmail.length
                        : ''
                    }
                    value={formData.personalEmail}
                    onChange={onChanged}
                  />
                </>
              ) : (
                <>
                  <Typography
                    variant="subtitle1"
                    sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                  >
                    <ContactMailIcon sx={{ marginRight: '8px' }} />
                    {tutor.personal_email && tutor.personal_email}
                  </Typography>
                </>
              )}

              {/* Time Zone */}
              {editMode ? (
                <>
                  <Box sx={{ mt: 1 }}>
                    <Autocomplete
                      id="timezone"
                      options={timezones}
                      //getOptionLabel={(option) => option.label}
                      value={selectedTimezone}
                      onChange={handleTimezoneChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required={true}
                          label="Timezone"
                        />
                      )}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Typography
                    variant="subtitle1"
                    sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                  >
                    <LanguageIcon sx={{ marginRight: '8px' }} />
                    {tutor.timezone}
                  </Typography>
                </>
              )}

              {/* Working with children license */}
              {editMode ? (
                <>
                  {IsManagerOrAdmin() && (
                    <TextField
                      sx={{ flex: 1 }}
                      margin="dense"
                      id="workingWithChildrenNo"
                      fullWidth={true}
                      label="Working with Children ID"
                      variant="outlined"
                      name="workingWithChildrenNo"
                      error={errors.email.length > 0}
                      helperText={
                        errors.workingWithChildrenID.length > 0
                          ? errors.email.length
                          : ''
                      }
                      value={formData.workingWithChildrenNo}
                      onChange={onChanged}
                    />
                  )}
                </>
              ) : (
                <>
                  {tutor.working_with_children_no && (
                    <Typography
                      variant="subtitle1"
                      sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                    >
                      <SchoolIcon sx={{ marginRight: '8px' }} />
                      {tutor.working_with_children_no &&
                        tutor.working_with_children_no}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </CardContent>
        </Card>
        <Box padding={1}>
          {!editMode ? (
            <Grid container>
              <Grid item xs={4}></Grid>
              <Grid item xs={3}>
                <Button
                  color="info"
                  size="small"
                  onClick={onEditModePress}
                  startIcon={<EditIcon />}
                >
                  Edit
                </Button>
              </Grid>
              <Grid item xs={3}>
                <UpdatePassword canClose={true} tutorId={tutor._id} />
              </Grid>
            </Grid>
          ) : (
            <>
              {tutorSubmitData?.type == 'Submitting' ? (
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                  }}
                >
                  <CircularProgress size={24} sx={{ color: 'white' }} />
                </Box>
              ) : (
                <Grid item container xs={12} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      color="error"
                      size="small"
                      onClick={onCancelEditModePress}
                      startIcon={<CancelIcon />}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="success"
                      size="small"
                      onClick={onConfirmButtonPress}
                      startIcon={<ThumbUpAltIcon />}
                    >
                      Confirm
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Box>
      </Card>
    </>
  );
};

export default TutorInfoCard;
