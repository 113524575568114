import { Payment } from 'src/models/payment';
import { FC } from 'react';
import { Lesson } from 'src/models/lesson';
import { Tutor } from 'src/models/tutor';
import Chart from 'react-apexcharts';
import { Box, Card, CardContent, Container, useTheme } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import React from 'react';
import { GetFirstDayOfFinancialYear, getWeekNumber } from 'src/tools/dateTools';
import { it } from 'date-fns/locale';
import { Student } from 'src/models/student';

interface TableProps {
  students: Student[];
}

const WeeklyNewStudents: FC<TableProps> = ({ students: students }) => {
  const theme = useTheme();

  function generateWeekNumbers(X: number): string[] {
    const result: string[] = [];
    for (let i = 1; i <= X; i++) {
      result.push(i.toString());
    }
    return result;
  }

  const chartOptions: ApexOptions = {
    chart: {
      background: 'transparent',
      foreColor: '#fff',
      toolbar: {
        show: false
      },

      zoom: {
        enabled: false
      }
    },
    fill: {
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.1,
        inverseColors: false,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [0, 100]
      }
    },
    colors: [theme.colors.primary.main, 'white', 'blue'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      colors: [theme.colors.primary.main, 'white', 'blue'],
      width: 3
    },
    legend: {
      show: true,
      onItemHover: {
        highlightDataSeries: true
      },
    },
    labels: generateWeekNumbers(getWeekNumber(new Date())),

    xaxis: {
      labels: {
        show: true,
        style: {
          colors: '#ffffff',
        },
      },

      axisBorder: {
        show: true
      },
      axisTicks: {
        show: true
      }
    },
    yaxis: {
      min: 0,
      labels: {
        style: {
          colors: '#ffffff',
        },
      },
      show: true,
      tickAmount: 5
    },
    tooltip: {
      theme: 'dark',
      marker: {
        show: true,
      },
    },

  };

  function initializeDictionary(maxKey: number): Record<number, string[]> {
    const dictionary: Record<number, string[]> = {};

    for (let i = 1; i <= maxKey; i++) {
      dictionary[i] = [];
    }

    return dictionary;
  }


  const GetWeeklyUniqueStudents = (filteredStudents: Student[]): [number, number][] => {
    const currentWeek = getWeekNumber(new Date());
    console.log(GetFirstDayOfFinancialYear())

    let weekDict: Record<number, string[]> = initializeDictionary(currentWeek);
  
    filteredStudents.forEach((student) => {
      const createdDate = new Date(student.createdAt);
      const weekNumber = getWeekNumber(createdDate);

      if (weekNumber in weekDict) {
        if(!weekDict[weekNumber].includes(student._id)) {
          weekDict[weekNumber].push(student._id);
        }
      }
    });
  
    console.log(weekDict)
    
    // Convert to an array and sort by week number
    const sortedWeekArray = Object.entries(weekDict).map(([weekNumber, students]) => [
      parseInt(weekNumber, 10),
      students.length,
    ]).sort((a, b) => a[0] - b[0]) as [number, number][];
  
    return sortedWeekArray;
  };
  

  const chartData = [
    {
      name: "Active Students",
      data: GetWeeklyUniqueStudents(students.filter(item => new Date(item.createdAt) > GetFirstDayOfFinancialYear() ))
    }
  ];

  return (
    <>
      <Card>
        <CardContent >
          <Box >
            <h2>New Students</h2>

              <Chart
                options={chartOptions}
                series={chartData}
                type="area"
                height={300}
              />
          </Box>

        </CardContent>
      </Card>
    </>
  );
};


export default WeeklyNewStudents;
