import axios from 'axios';
import { Tutor } from 'src/models/tutor';

import {
  Dispatcher,
  FAILED,
  SUBMITTING,
  SUCCESS,
  LOADING,
  GetToken,
  Submitting,
  Default,
  Loading,
  Fail,
  Success
} from './LoadingActions';
import { Student } from '../models/student';
import { API_URL } from '../settings';

const API_URL_FULL = API_URL + 'api/users/';

//const API_URL = process.env.API_SERVER + "/api/users/";
//const API_URL = 'http://localhost:5001/api/users/';

export type TutorsGetTypes = Loading | Fail | GetTutorsSuccess;
export type TutorGetTypes = Loading | Fail | GetTutorSuccess;
export type TutorSubmitTypes = Default | Submitting | Fail | Success;

export interface GetTutorsSuccess {
  type: typeof SUCCESS;
  payload: Tutor[];
}

export interface GetTutorSuccess {
  type: typeof SUCCESS;
  payload: Tutor;
}

//Get all tutors
export const ResetPassword =
  (
    tutorId: string,
    newPassword: string,
    oldPassword: string,
    setData: Dispatcher<TutorGetTypes>
  ) =>
  async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: LOADING
      });

      const data = {
        id: tutorId,
        new_password: newPassword,
        old_password: oldPassword
      };

      const res = await axios.post(
        API_URL_FULL + 'change-password',
        data,
        config
      );

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

//Get all tutors
export const GetAllTutors =
  (setData: Dispatcher<TutorsGetTypes>) => async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: LOADING
      });

      const res = await axios.post(API_URL_FULL + 'get-all-tutors', {}, config);

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

//Get all tutor that belong to a certian student
export const GetTutor =
  (tutorID: string, setData: Dispatcher<TutorGetTypes>) => async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: LOADING
      });

      const res = await axios.post(
        API_URL_FULL + 'tutor',
        { id: tutorID },
        config
      );

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

//Update tutor availability
export const UpdateTutorAvailability =
  (
    tutorId: String,
    availabilityArray: String[],
    setData: Dispatcher<TutorSubmitTypes>
  ) =>
  async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      if (availabilityArray.length != 112) {
        setData({
          type: FAILED,
          message: 'Not enough days in array'
        });
      }

      const data = {
        id: tutorId,
        availability: availabilityArray
      };

      setData({
        type: SUBMITTING
      });

      await axios.post(
        API_URL_FULL + 'update-tutor-availability',
        data,
        config
      );

      setData({
        type: SUCCESS
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

//Update tutor details
export const UpdateTutorBasicDetails =
  (
    id: string,
    phone: string,
    email: string,
    personal_email: string,
    timezone: string,
    workingWithChildrenNo: string,
    setData: Dispatcher<TutorSubmitTypes>
  ) =>
  async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

    
      const postData = {
        id: id,
        phone: phone,
        email: email,
        personal_email: personal_email,
        timezone: timezone,
        working_with_children_no: workingWithChildrenNo,

      };

      console.log(postData)

      setData({
        type: SUBMITTING
      });

      await axios.post(API_URL_FULL + 'update-tutor-basics', postData, config);

      setData({
        type: SUCCESS
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

//Add a new tutor
export const RegisterTutor =
  (tutorData: Tutor, setData: Dispatcher<TutorSubmitTypes>) => async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: SUBMITTING
      });

      await axios.post(API_URL_FULL + 'register-tutor', tutorData, config);

      setData({
        type: SUCCESS
      });
    } catch (error) {
      console.log(error);

      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log(error.response.data);
        }

        setData({
          type: FAILED,
          message: error.message
        });
        return;
      }
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

//Add a new student to a tutor
export const AddStudentToTutor =
  (
    tutorId: string,
    studentId: string,
    student_hourly_rate: number,
    tutor_hourly_rate: number,
    setData: Dispatcher<TutorSubmitTypes>
  ) =>
  async () => {
    try {
      const token = GetToken();

      const data = {
        id: tutorId,
        studentId: studentId,
        student_hourly_rate: student_hourly_rate,
        tutor_hourly_rate: tutor_hourly_rate
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: SUBMITTING
      });

      await axios.post(API_URL_FULL + 'add-student-to-tutor', data, config);

      setData({
        type: SUCCESS
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setData({
          type: FAILED,
          message: error.message
        });
        return;
      }
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

//Remove a student from a tutor
export const RemoveStudentFromTutor =
  (tutorId: string, studentId: string, setData: Dispatcher<TutorSubmitTypes>) =>
  async () => {
    try {
      const token = GetToken();

      const data = {
        id: tutorId,
        studentId: studentId
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: SUBMITTING
      });

      await axios.post(
        API_URL_FULL + 'remove-student-from-tutor',
        data,
        config
      );

      setData({
        type: SUCCESS
      });
    } catch (error) {
      console.log(error);

      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log(error.response.data);
        }

        setData({
          type: FAILED,
          message: error.message
        });
        return;
      }
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };
