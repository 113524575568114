
import { Box, Container, LinearProgress, Typography} from '@mui/material';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { GetLocalUser } from 'src/actions/AuthActions';
import {
  LessonsGetTypes,
  LessonInterface,
  GetTutorLessons
} from 'src/actions/LessonActions';

import Footer from 'src/components/Footer';

import AllLeadsTable from './AllLeadsTable';
import { GetAllLeads, LeadsGetTypes } from 'src/actions/LeadActions';
import { EmailTemplatesGetTypes, GetAllEmailTemplates } from 'src/actions/EmailActions';

const DashboardAllLeads = () => {
  //const [lessonData, setLessonData] = useState<LessonsGetTypes>();
  const [leadsData, setLeadsData] = useState<LeadsGetTypes>();
  const [getTemplatesData, setGetTemplatesData] = useState<EmailTemplatesGetTypes>();
  const dispatch = useDispatch();

  let user = GetLocalUser();

  //Initial Load
  useEffect(() => {
    //If there is a user id in the url params
    if (user != null) {
      //Get lesson data
      const passedParams: LessonInterface = {
        student_id: user._id,
        statusesIncludes: ['booked', 'completed', 'paid']
      };

      dispatch<any>(GetAllLeads(setLeadsData));
      dispatch<any>(GetAllEmailTemplates(setGetTemplatesData));
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Leads</title>
      </Helmet>
      <Box pt={2} />

      <Container maxWidth="lg">
      <h2>Leads</h2>


      {leadsData?.type == 'Loading' && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}

        {leadsData?.type == 'Failed' && (
          <Box sx={{ width: '100%' }}>
            <Typography color="error">{leadsData.message} </Typography>
          </Box>
        )}

        {leadsData?.type == "Success" &&(
          <>
            <AllLeadsTable leads={leadsData.payload} emailTemplates={getTemplatesData} />
          </>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default DashboardAllLeads;
