
import { TableCell, tableCellClasses, TableRow } from "@mui/material";
import { styled } from '@mui/material/styles';

// Styling
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '4px 2px'
  }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(4n+1)': {
    backgroundColor: '#182440'
  },
  '&:nth-of-type(4n+2)': {
    backgroundColor: '#4b4b4b'
  },
  '&:nth-of-type(4n+3)': {
    backgroundColor: '#202b45'
  },
  '&:nth-of-type(4n+4)': {
    backgroundColor: '#4b4b4b'
  },

  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

export const StyledTableRowSub = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#182440'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#263352'
  },

  '&:last-child td, &:last-child th': {
    border: 0
  }
}));