import {
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  duration,
  FormControlLabel,
  Checkbox,
  Divider,
  Box,
  Autocomplete
} from '@mui/material';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  RegisterStudent,
  StudentSubmitTypes
} from 'src/actions/StudentActions';
import { Student } from 'src/models/student';
import { validateEmail } from 'src/tools/validation';
import { timezones } from 'src/tools/dateTools';
import { useNavigate } from 'react-router';
import { Lead } from 'src/models/lead';

interface TableProps {
  lead?: Lead;
  parentLead?: Lead;
  tall?: boolean;
  buttonName?: string;
}

const AddStudentButton = ({ lead, parentLead, tall,buttonName }: TableProps) => {
  const [saveStudentData, setSaveStudentData] = useState<StudentSubmitTypes>();
  const [isChild, setIsChild] = useState(false);
  const [freeFirstLesson, setFreeFirstLesson] = useState(true);

  const [errors, setErrors] = useState<{
    email: string;
    firstName: string;
    lastName: string;
    hourlyRate: string;
    phone: string;
    parentFirstName: string;
    parentLastName: string;
    parentEmail: string;
    parentPhone: string;
    timezone: string;
  }>({
    email: '',
    firstName: '',
    lastName: '',
    hourlyRate: '',
    phone: '',
    parentFirstName: '',
    parentLastName: '',
    parentEmail: '',
    parentPhone: '',
    timezone: ''
  });

  const [open, setOpen] = useState(false);

  //Form Data
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    hourly_rate: 55,
    parent_first_name: '',
    parent_last_name: '',
    parent_email: '',
    parent_phone: ''
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTimezone, setSelectedTimezone] = useState(null);

  // When Dialogue opens
  useEffect(() => {
    //If there is a lead id passed in fill the form with values
    if (lead != null) {
      setFormData({
        ...formData,
        first_name: lead.first_name,
        last_name: lead.last_name,
        email: lead.email,
        phone: lead.phone
      });

      const timezone = timezones.find((tz) => tz.value === lead.timezone);
      setSelectedTimezone(timezone);
    }else if(parentLead != null){
      setIsChild(true);
      setFormData({
        ...formData,
        parent_first_name: parentLead.first_name,
        parent_last_name: parentLead.last_name,
        parent_email: parentLead.email,
        parent_phone: parentLead.phone
      });
    }
  }, [open]);

  const handleTimezoneChange = (event, newValue) => {
    console.log(newValue);
    setSelectedTimezone(newValue);
  };

  //On Submit of the form
  const onSubmit = () => {
    if (!handleValidation()) {
      return;
    }

    if (!isChild) {
      const studentData: Student = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email.toLowerCase(),
        hourly_rate: formData.hourly_rate,
        phone: formData.phone,
        ndis_student: false,
        credits: freeFirstLesson ? 1 : 0,
        timezone: selectedTimezone.value,
        status: 'active',
        region: 'aud'
      };

      dispatch<any>(RegisterStudent(studentData, setSaveStudentData));
    } else {
      const studentData: Student = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email.toLowerCase(),
        hourly_rate: formData.hourly_rate,
        credits: freeFirstLesson ? 1 : 0,
        ndis_student: false,
        timezone: selectedTimezone.value,
        status: 'active',
        region: 'aud',
        parents: [
          {
            first_name: formData.parent_first_name,
            last_name: formData.parent_last_name,
            email: formData.parent_email,
            phone: formData.parent_phone
          }
        ]
      };

      dispatch<any>(RegisterStudent(studentData, setSaveStudentData));
    }
  };

  const updateErrorState = (field, message) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: message
    }));
  };

  //Validate form
  const handleValidation = () => {
    let formIsValid = true;

    //First Name
    if (formData.first_name === '') {
      formIsValid = false;
      updateErrorState('firstName', "Field can't be empty");
    } else if (!formData.first_name.match(/^[a-zA-Z-\s]+$/)) {
      formIsValid = false;
      updateErrorState('firstName', 'Only letters');
    } else {
      updateErrorState('firstName', '');
    }

    //Last Name
    if (!isChild) {
      if (formData.last_name === '') {
        formIsValid = false;
        updateErrorState('lastName', "Field can't be empty");
      } else if (!formData.last_name.match(/^[a-zA-Z-\s]+$/)) {
        formIsValid = false;
        updateErrorState('lastName', 'Only letters');
      } else {
        updateErrorState('lastName', '');
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        last_name: ''
      }));
      updateErrorState('lastName', '');
    }

    //Hourly Rate
    if (isNaN(formData.hourly_rate) || formData.hourly_rate <= 0) {
      formIsValid = false;
      updateErrorState('hourlyRate', 'Invalid Value');
    } else {
      updateErrorState('hourlyRate', '');
    }

    //Time zone
    if (!timezones.includes(selectedTimezone)) {
      formIsValid = false;
      updateErrorState('timezone', 'Invalid Time Zone');
    } else {
      updateErrorState('timezone', '');
    }

    //Email
    if (!isChild) {
      if (formData.email === '') {
        formIsValid = false;
        updateErrorState('email', "Field can't be empty");
      } else if (!validateEmail(formData.email)) {
        formIsValid = false;
        updateErrorState('email', 'Email is not valid');
      } else {
        updateErrorState('email', '');
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        email: ''
      }));
      updateErrorState('email', '');
    }

    if (isChild) {
      //Parent First Name
      if (formData.parent_first_name === '') {
        formIsValid = false;
        updateErrorState('parentFirstName', "Field can't be empty");
      } else if (!formData.parent_first_name.match(/^[a-zA-Z-\s]+$/)) {
        formIsValid = false;
        updateErrorState('parentFirstName', 'Only letters');
      } else {
        updateErrorState('parentFirstName', '');
      }

      //Parent Last Name
      if (formData.parent_last_name === '') {
        formIsValid = false;
        updateErrorState('parentLastName', "Field can't be empty");
      } else if (!formData.parent_last_name.match(/^[a-zA-Z-\s]+$/)) {
        formIsValid = false;
        updateErrorState('parentLastName', 'Only letters');
      } else {
        updateErrorState('parentLastName', '');
      }

      //Email
      if (formData.parent_email === '') {
        formIsValid = false;
        updateErrorState('parentEmail', "Field can't be empty");
      } else if (!validateEmail(formData.parent_email)) {
        formIsValid = false;
        updateErrorState('parentEmail', 'Email is not valid');
      } else {
        updateErrorState('parentEmail', '');
      }
    } else {
      setFormData((prevState) => {
        console.log('Previous state:', prevState);
        const newState = {
          ...prevState,
          parent_last_name: '',
          parent_first_name: '',
          parent_email: '',
          parent_phone: ''
        };
        return newState;
      });
      updateErrorState('parentLastName', '');
      updateErrorState('parentFirstName', '');
      updateErrorState('parentEmail', '');
      updateErrorState('parentPhone', '');
    }

    return formIsValid;
  };

  useEffect(() => {
    if (saveStudentData?.type === 'Success') {
      navigate('/management/student?user_id=' + saveStudentData.payload._id);
    } else if (saveStudentData?.type === 'Failed') {
    }
  }, [saveStudentData]);

  // This function is called when the input changes
  const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogTitle>Add Student</DialogTitle>
        <DialogContent>
          <FormControlLabel
            label="Is Child"
            control={
              <Checkbox
                checked={isChild}
                id="is_child"
                name="is_child"
                onChange={(event) => setIsChild(event.target.checked)}
              />
            }
          />

          <FormControlLabel
            label="First Lesson Free"
            control={
              <Checkbox
                checked={freeFirstLesson}
                id="free_first_lesson"
                name="free_first_lesson"
                onChange={(event) => setFreeFirstLesson(event.target.checked)}
              />
            }
          />

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <TextField
                autoFocus
                sx={{ flex: 1 }}
                margin="dense"
                id="first_name"
                label="First Name"
                variant="outlined"
                name="first_name"
                required={true}
                error={errors.firstName.length > 0}
                helperText={errors.firstName.length > 0 ? errors.firstName : ''}
                value={formData.first_name}
                onChange={onChanged}
              />

              {isChild ? (
                <></>
              ) : (
                <>
                  <TextField
                    sx={{ flex: 1 }}
                    margin="dense"
                    id="last_name"
                    label="Last Name"
                    variant="outlined"
                    name="last_name"
                    required={!isChild}
                    disabled={formData.last_name == 'Unknown'}
                    error={errors.lastName.length > 0}
                    helperText={
                      errors.lastName.length > 0 ? errors.lastName : ''
                    }
                    value={formData.last_name}
                    onChange={onChanged}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="unknown_last_name"
                        name="unknown_last_name"
                        color="primary"
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            last_name: event.target.checked ? 'Unknown' : ''
                          })
                        }
                      />
                    }
                    label="Unknown Last Name"
                  />
                </>
              )}
            </Box>

            <TextField
              margin="dense"
              id="email"
              label="Email"
              variant="outlined"
              type="email"
              name="email"
              required={!isChild}
              error={errors.email.length > 0}
              helperText={errors.email.length > 0 ? errors.email : ''}
              value={formData.email}
              onChange={onChanged}
            />

            {isChild ? (
              <></>
            ) : (
              <TextField
                margin="dense"
                id="phone"
                label="Phone number"
                variant="outlined"
                type="tel"
                name="phone"
                error={errors.phone.length > 0}
                helperText={errors.phone.length > 0 ? errors.phone : ''}
                value={formData.phone}
                onChange={onChanged}
              />
            )}

            <TextField
              margin="dense"
              id="hourly_rate"
              label="Hourly Rate"
              variant="outlined"
              type="number"
              name="hourly_rate"
              required={true}
              error={errors.hourlyRate.length > 0}
              helperText={errors.hourlyRate.length > 0 ? errors.hourlyRate : ''}
              value={formData.hourly_rate}
              onChange={onChanged}
            />

            <Autocomplete
              id="timezone"
              options={timezones}
              getOptionLabel={(option) => option.label}
              value={selectedTimezone}
              onChange={handleTimezoneChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={true}
                  label="Timezone"
                  error={errors.timezone.length > 0}
                  helperText={errors.timezone.length > 0 ? errors.timezone : ''}
                />
              )}
            />

            {isChild ? (
              <>
                <Divider />

                <Typography variant="overline">Parent Account</Typography>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                  <TextField
                    autoFocus
                    sx={{ flex: 1 }}
                    margin="dense"
                    id="parent_first_name"
                    label="First Name"
                    variant="outlined"
                    name="parent_first_name"
                    required={isChild}
                    error={errors.parentFirstName.length > 0}
                    helperText={
                      errors.parentFirstName.length > 0
                        ? errors.parentFirstName
                        : ''
                    }
                    value={formData.parent_first_name}
                    onChange={onChanged}
                  />

                  <TextField
                    sx={{ flex: 1 }}
                    margin="dense"
                    id="parent_last_name"
                    label="Last Name"
                    variant="outlined"
                    name="parent_last_name"
                    disabled={formData.parent_last_name == 'Unknown'}
                    required={isChild}
                    error={errors.parentLastName.length > 0}
                    helperText={
                      errors.parentLastName.length > 0
                        ? errors.parentLastName
                        : ''
                    }
                    value={formData.parent_last_name}
                    onChange={onChanged}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        id="unknown_last_name"
                        name="unknown_last_name"
                        color="primary"
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            parent_last_name: event.target.checked ? 'Unknown' : ''
                          })
                        }
                      />
                    }
                    label="Unknown Last Name"
                  />
                </Box>

                <TextField
                  margin="dense"
                  id="parent_email"
                  sx={{ flex: 1 }}
                  label="Parent Email"
                  variant="outlined"
                  name="parent_email"
                  required={isChild}
                  error={errors.parentEmail.length > 0}
                  helperText={
                    errors.parentEmail.length > 0 ? errors.parentEmail : ''
                  }
                  value={formData.parent_email}
                  onChange={onChanged}
                />

                <TextField
                  margin="dense"
                  id="parent_phone"
                  sx={{ flex: 1 }}
                  label="Parent Phone Number"
                  variant="outlined"
                  name="parent_phone"
                  error={errors.parentPhone.length > 0}
                  helperText={
                    errors.parentPhone.length > 0 ? errors.parentPhone : ''
                  }
                  value={formData.parent_phone}
                  onChange={onChanged}
                />
              </>
            ) : (
              <></>
            )}
          </Box>

          <Typography
            variant="caption"
            marginTop={1}
            gutterBottom
            color="error"
            sx={{
              display:
                saveStudentData && saveStudentData.type === 'Failed'
                  ? 'block'
                  : 'none'
            }}
          >
            {saveStudentData && saveStudentData.type === 'Failed'
              ? saveStudentData.message
              : ''}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onSubmit}>Add</Button>
        </DialogActions>
      </Dialog>

      <Button
        sx={{ height: tall ? 50 : 40, mt: { xs: 2, md: 0 } }}
        variant="contained"
        size="small"
        startIcon={<AddTwoToneIcon fontSize="small" />}
        onClick={handleClickOpen}
      >
        {buttonName?buttonName:'Student'}
      </Button>
    </>
  );
};

export default AddStudentButton;
