import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import Footer from 'src/components/Footer';
import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';
import { useRef } from 'react';
import {
  ChangeEmailTemplateStatus,
  EmailSubmitTypes,
  EmailTemplateGetTypes,
  EmailTemplatesGetTypes,
  GetAllEmailTemplates,
  SaveEmailTemplate
} from 'src/actions/EmailActions';
import { EmailTemplate } from 'src/models/emailTemplate';
import { StyledTableRow } from 'src/theme/stylings';
import InfoIcon from '@mui/icons-material/Info';
import HtmlPreviewDialog from './EmailPreview';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Template } from 'aws-sdk/clients/appsync';

export const EmailEditorPage = () => {
  const [saveTemplateData, setSaveTemplateData] = useState<EmailSubmitTypes>();
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
  const [getTemplatesData, setGetTemplatesData] =
    useState<EmailTemplatesGetTypes>();
  const [templatesData, setTemplatesData] = useState<EmailTemplate>();
  const [editorReady, setEditorReady] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [jsonEdit, setJsonEdit] = useState<boolean>(false);
  const [previewHtmlOpen, setPreviewHtmlOpen] = useState<boolean>(false);
  const [openSaveNotification, setOpenSaveNotification] =
    useState<boolean>(false);
  const [isNewTemplate, setIsNewTemplate] = useState<boolean>(false);
  const [isDuplicateTemplate, setIsDuplicateTemplate] = useState<boolean>();
  const [changeTemplateStatusData, setChangeTemplateStatusData] = useState<EmailSubmitTypes>();
  const [templateName, setTemplateName] = useState<string>('');
  const [jsonData, setJsonData] = useState<string>('');
  const [previewTemplate, setPreviewTemplate] = useState<EmailTemplate>();
  const [errors, setErrors] = useState<{
    templateName: string;
  }>({
    templateName: ''
  });

  const updateErrorState = (field, message) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: message
    }));
  };

  const dispatch = useDispatch();
  const theme = useTheme();
  const emailEditorRef = useRef<EditorRef>(null);

  // When template date loads
  useEffect(() => {
    if (getTemplatesData?.type == 'Success') {
      setEmailTemplates(getTemplatesData.payload);
    }
  }, [getTemplatesData]);

  useEffect(() => {
    dispatch<any>(GetAllEmailTemplates(setGetTemplatesData));
  }, []);

  useEffect(() => {

    if (changeTemplateStatusData?.type == 'Success') {
      setEmailTemplates(
        emailTemplates.map((template) =>
          template.name === changeTemplateStatusData.payload.name
            ? changeTemplateStatusData.payload
            : template
        )
      );
    }
  }, [changeTemplateStatusData]);

  // When template saves
  useEffect(() => {
    if (saveTemplateData?.type == 'Success') {
      setOpen(false);
      return;
    } else if (saveTemplateData?.type == 'Failed') {
      return;
    }
  }, [saveTemplateData]);

  const exportHtml = () => {
    const unlayer = emailEditorRef.current?.editor;

    return new Promise((resolve, reject) => {
      unlayer?.exportHtml((data) => {
        const { design, html } = data;
        resolve(html); // Resolve the promise with the html data
      });
    });
  };

  const showSavePopup = () => {
    setOpenSaveNotification(true);
  };

  const hideSavePopup = () => {
    setOpenSaveNotification(false);
  };

  const downloadHTML = async () => {
    const htmlData = await exportHtml();
    const blob = new Blob([htmlData as BlobPart], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'emailTemplate.html');
    link.click();
  };

  const exportJSON = () => {
    const unlayer = emailEditorRef.current?.editor;

    return new Promise((resolve, reject) => {
      unlayer.saveDesign((design) => {
        const jsonData = JSON.stringify(design);
        resolve(jsonData); // Resolve the promise with the json data
      });
    });
  };

  const downloadJSON = async () => {
    const jsonData = await exportJSON();
    const blob = new Blob([jsonData as BlobPart], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'emailTemplate.json');
    link.click();
  };

  const saveTemplate = async () => {
    try {
      const jsonData = await exportJSON(); // Wait for exportJSON to complete and get the data
      const htmlData = await exportHtml(); // Wait for exportHtml to complete and get the data

      if (isNewTemplate && templateName == '') {
        updateErrorState('templateName', 'Template name is required');
        return;
      } else if (getTemplatesData.type=='Success' && getTemplatesData.payload.some(t => t.name===templateName)) {
        updateErrorState('templateName', 'Template name is already used');
        return;
      }else if (isNewTemplate && templateName.length <= 3) {
        updateErrorState(
          'templateName',
          'Template name needs to be longer than 3 characters'
        );
        return;
      }
      updateErrorState('templateName', '');
      // Now both exportJSON and exportHtml have finished and returned their data
      dispatch<any>(
        SaveEmailTemplate(
          isNewTemplate ? templateName : templatesData.name,
          jsonData,
          htmlData,
          setSaveTemplateData
        )
      );

      showSavePopup();
    } catch (error) {
      // Handle any errors that might occur during export or dispatch
      console.error('Error saving template:', error);
    }
  };

  const onReady: EmailEditorProps['onReady'] = (unlayer) => {
    // editor is ready
    // you can load your template here;
    // the design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
    // const templateJson = { DESIGN JSON GOES HERE };
    setEditorReady(true);

    if (!isNewTemplate || isDuplicateTemplate) {
      unlayer.loadDesign(JSON.parse(templatesData.json));
    }

    // unlayer.init({
    //   id: 'editor-container', // The HTML div id of the container where the editor will be embedded.
    //   projectId: 1234,        // Your project ID.
    //   displayMode: 'email'    // The template display mode which can be 'email' or 'web'.
    // });
  };

  const onLoad: EmailEditorProps['onLoad'] = (unlayer) => {
    //console.log('onLoad', unlayer);
    // unlayer.addEventListener('design:loaded', onDesignLoad);
    // unlayer.loadDesign(sample);
  };

  const onNewButtonPress = () => {
    const newTemplate: EmailTemplate = {
      name: 'Untitled',
      json: JSON.parse('{}'),
      html: '<!DOCTYPE html><html><head><style>body{margin:0;padding:0}</style></head><body></body></html>',
      active: false
    };
    setIsNewTemplate(true);
    setTemplatesData(newTemplate);
  };

  const onLoadButtonPress = () => {
    const newTemplate: EmailTemplate = {
      name: '',
      json: jsonData,
      html: '<!DOCTYPE html><html><head><style>body{margin:0;padding:0}</style></head><body></body></html>',
      active: false
    };
    setIsNewTemplate(true);
    setTemplatesData(newTemplate);
  };

  const onLoadJSONButtonPress = () => {
    setJsonEdit(true);
  };

  const onDuplicateButtonPress = (json : string) =>{

    const newTemplate: EmailTemplate = {
      name: '',
      json: json,
      html: '<!DOCTYPE html><html><head><style>body{margin:0;padding:0}</style></head><body></body></html>',
      active: false
    };
    setIsNewTemplate(true);
    setIsDuplicateTemplate(true);
    setTemplatesData(newTemplate);
  }

  const onClickSavedTemplate = (template: EmailTemplate) => {
    setTemplatesData(template);
  };

  const onClickPreviewTemplate = (template: EmailTemplate) => {
    setPreviewTemplate(template);
    setPreviewHtmlOpen(true);
  };

  const onSaveButtonPress = () => {
    if (isNewTemplate) {
      handleClickOpen();
    } else {
      saveTemplate();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const onTemplateNameSet = (event) => {
    setTemplateName(event.target.value);
  };

  const onSetTemplateActive = (status : boolean,template : EmailTemplate) => {
    dispatch<any>(
      ChangeEmailTemplateStatus(
        template.name,
        status,
        setChangeTemplateStatusData
      )
    );
  };



  const onJsonDataChange = (event) => {
    setJsonData(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Invoicing</title>
      </Helmet>
      <Box pt={2} />

      <Container maxWidth="lg">
        <h1>Email Editor</h1>

        {templatesData?.json != null ? (
          <>
            <div id="email">
              <EmailEditor
                ref={emailEditorRef}
                onLoad={onLoad}
                onReady={onReady}
                minHeight={1000}
                options={{
                  fonts: {
                    showDefaultFonts: true,
                    customFonts: [
                      {
                        label: 'Anton',
                        value: "'Anton', sans-serif",
                        url: 'https://fonts.googleapis.com/css?family=Anton'
                      },
                      {
                        label: 'Georgia',
                        value: "Georgia, Times, 'Times New Roman', serif",
                        url: 'https://fonts.googleapis.com/css?family=Georgia'
                      },
                      {
                        label: 'Helvetica',
                        value: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                        url: 'https://fonts.googleapis.com/css?family=Helvetica+Neue:300,400,500,600,700,800|Helvetica:300,400,500,600,700,800'
                      },
                      {
                        label: 'Lucida',
                        value:
                          "'Lucida Grande', 'Lucida Sans', Geneva, Verdana, sans-serif",
                        url: 'https://fonts.googleapis.com/css?family=Lucida+Grande:400,700|Lucida+Sans:400,700|Geneva:400,700|Verdana:400,700'
                      },
                      {
                        label: 'Lato',
                        value: "'Lato', Tahoma, Verdana, sans-serif",
                        url: 'https://fonts.googleapis.com/css?family=Lato'
                      },
                      {
                        label: 'Lobster Two',
                        value: "'Lobster Two',cursive",
                        url: 'https://fonts.googleapis.com/css?family=Lobster+Two:400,700'
                      },
                      {
                        label: 'Playfair Display',
                        value: "'Playfair Display',serif",
                        url: 'https://fonts.googleapis.com/css?family=Playfair+Display:400,700'
                      },
                      {
                        label: 'Rubik',
                        value: "'Rubik',sans-serif",
                        url: 'https://fonts.googleapis.com/css?family=Rubik:400,700'
                      },
                      {
                        label: 'Source Sans Pro',
                        value: "'Source Sans Pro',sans-serif",
                        url: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700'
                      },
                      {
                        label: 'Open Sans',
                        value: "'Open Sans',sans-serif",
                        url: 'https://fonts.googleapis.com/css?family=Open+Sans:400,700'
                      },
                      {
                        label: 'Crimson Text',
                        value: "'Crimson Text',serif",
                        url: 'https://fonts.googleapis.com/css?family=Crimson+Text:400,700'
                      },
                      {
                        label: 'Montserrat',
                        value: "'Montserrat',sans-serif",
                        url: 'https://fonts.googleapis.com/css?family=Montserrat:400,700'
                      },
                      {
                        label: 'Old Standard TT',
                        value: "'Old Standard TT',serif",
                        url: 'https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700'
                      },
                      {
                        label: 'Lato',
                        value: "'Lato',sans-serif",
                        url: 'https://fonts.googleapis.com/css?family=Lato:400,700'
                      },
                      {
                        label: 'Raleway',
                        value: "'Raleway',sans-serif",
                        url: 'https://fonts.googleapis.com/css?family=Raleway:400,700'
                      },
                      {
                        label: 'Cabin',
                        value: "'Cabin',sans-serif",
                        url: 'https://fonts.googleapis.com/css?family=Cabin:400,700'
                      },
                      {
                        label: 'Pacifico',
                        value: "'Pacifico',cursive",
                        url: 'https://fonts.googleapis.com/css?family=Pacifico'
                      }
                    ]
                  }
                }}
              />
            </div>
            {editorReady == true && (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
              >
                <Box sx={{ display: 'flex', gap: '10px' }}>
                  <Button
                    variant="contained"
                    sx={{ flex: 0.5, margin: 1 }}
                    onClick={onSaveButtonPress}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ flex: 0.5, margin: 1 }}
                    onClick={exportJSON}
                    disabled={true}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ flex: 0.5, margin: 1 }}
                    onClick={downloadHTML}
                  >
                    Export HTML
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ flex: 0.5, margin: 1 }}
                    onClick={downloadJSON}
                  >
                    Export JSON
                  </Button>
                </Box>
              </Box>
            )}
          </>
        ) : (
          <>
            <Card>
              <CardHeader title="Saved Templates" />

              <Divider />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>

                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          Preview
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          Edit
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          Active State
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          Duplicate
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          Delete
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getTemplatesData?.type == 'Success' ? (
                      emailTemplates.map((template, index) => {
                        return (
                          <StyledTableRow key={index}>
                            <TableCell>
                              <Tooltip title={'id: ' + template._id} arrow>
                                <IconButton
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.primary.lighter
                                    },
                                    color: 'grey'
                                  }}
                                  color="inherit"
                                  size="small"
                                >
                                  <InfoIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                gutterBottom
                                style={{
                                  textDecoration: 'none',
                                  alignItems: 'center',
                                  display: 'flex'
                                }}
                                noWrap
                              >
                                {template.name}
                              </Typography>
                            </TableCell>
                            <TableCell>
                                <Tooltip title={'Preview Template'} arrow>
                                  <IconButton
                                    sx={{
                                      '&:hover': {
                                        background: theme.colors.primary.lighter
                                      }
                                    }}
                                    color="inherit"
                                    size="small"
                                    onClick={() =>
                                      onClickPreviewTemplate(template)
                                    }
                                  >
                                    <VisibilityIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <Tooltip title={'Edit Template'} arrow>
                                  <IconButton
                                    sx={{
                                      '&:hover': {
                                        background: theme.colors.primary.lighter
                                      }
                                    }}
                                    color="inherit"
                                    size="small"
                                    onClick={() =>
                                      onClickSavedTemplate(template)
                                    }
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <Tooltip title={'Set Active/Inactive'} arrow>
                                  <IconButton
                                    sx={{
                                      '&:hover': {
                                        background: theme.colors.primary.lighter
                                      }
                                    }}
                                    color="inherit"
                                    size="small"
                                    onClick={() =>
                                      onSetTemplateActive(!template.active, template)
                                    }
                                  >
                                    {template.active ? 
                                      <ThumbUpOffAltIcon color='success' fontSize="small" />:
                                      <ThumbDownOffAltIcon color='error' fontSize="small" />
                                    }
                                    
                                  </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <Tooltip title={'Duplicate Template'} arrow>
                                  <IconButton
                                    sx={{
                                      '&:hover': {
                                        background: theme.colors.primary.lighter
                                      }
                                    }}
                                    color="inherit"
                                    size="small"
                                    onClick={() =>
                                      onDuplicateButtonPress(template.json)
                                    }
                                  >
                                    <ContentCopyIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <Tooltip title={'Delete Template'} arrow>
                                  <IconButton
                                    sx={{
                                      '&:hover': {
                                        background: theme.colors.primary.lighter
                                      }
                                    }}
                                    color="inherit"
                                    size="small"
                                    onClick={() =>
                                      onClickPreviewTemplate(template)
                                    }
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                            </TableCell>
                          </StyledTableRow>
                        );
                      })
                    ) : (
                      <StyledTableRow>
                        <TableCell colSpan={2}>
                          <LinearProgress />
                        </TableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <Button
                  variant="contained"
                  sx={{ flex: 0.5, margin: 1, marginTop: 2, maxWidth: '200px' }}
                  onClick={onNewButtonPress}
                >
                  New
                </Button>
                <Button
                  variant="contained"
                  sx={{ flex: 0.5, margin: 1, marginTop: 2, maxWidth: '200px' }}
                  onClick={onLoadJSONButtonPress}
                >
                  Load JSON
                </Button>
              </Box>
              {jsonEdit && (
                <>
                  <TextField
                    multiline
                    id="jsonData"
                    label="JSON data"
                    variant="outlined"
                    fullWidth
                    value={jsonData}
                    onChange={onJsonDataChange}
                    sx={{ flex: 1 }}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      flex: 0.5,
                      margin: 1,
                      marginTop: 2,
                      maxWidth: '200px'
                    }}
                    onClick={onLoadButtonPress}
                  >
                    Load
                  </Button>
                </>
              )}
            </Box>
          </>
        )}
      </Container>
      <Footer />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>New Template Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            sx={{ flex: 1 }}
            margin="dense"
            id="template_name"
            label="Template Name"
            variant="outlined"
            name="template_name"
            required={true}
            error={errors.templateName.length > 0}
            helperText={
              errors.templateName.length > 0 ? errors.templateName : ''
            }
            value={templateName}
            onChange={onTemplateNameSet}
          />

          <Typography
            variant="caption"
            display="block"
            gutterBottom
            color="red"
            sx={{
              display: saveTemplateData?.type === 'Failed' ? 'block' : 'none'
            }}
          >
            {saveTemplateData?.type == 'Failed' ? saveTemplateData.message : ''}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth={true}
            variant="contained"
            color="error"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button fullWidth={true} variant="contained" onClick={saveTemplate}>
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {previewTemplate && (
        <HtmlPreviewDialog
          emailTemplate={previewTemplate}
          isOpen={previewHtmlOpen}
          setData={setPreviewHtmlOpen}
        />
      )}

      <Snackbar
        open={openSaveNotification}
        autoHideDuration={3000}
        onClose={hideSavePopup}
        color="primary"
        message="Template Saved"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </>
  );
};

export default EmailEditorPage;
