import { Box, Container, LinearProgress, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { GetLocalUser } from 'src/actions/AuthActions';
import {
  LessonsGetTypes,
  LessonInterface,
  GetTutorLessons
} from 'src/actions/LessonActions';

import Footer from 'src/components/Footer';

import { GetAllStudents, StudentsGetTypes } from 'src/actions/StudentActions';
import StudentsTable from '@/src/content/dashboards/AllStudents/StudentsTable';
import AllStudentsTable from './AllStudentsTable';

const DashboardAllStudents = () => {
  //const [lessonData, setLessonData] = useState<LessonsGetTypes>();
  const [studentsData, setStudentData] = useState<StudentsGetTypes>();
  const dispatch = useDispatch();

  let user = GetLocalUser();

  //Initial Load
  useEffect(() => {
    //If there is a user id in the url params
    if (user != null) {
      //Get lesson data
      const passedParams: LessonInterface = {
        student_id: user._id,
        statusesIncludes: ['booked', 'completed', 'paid']
      };

      dispatch<any>(GetAllStudents(setStudentData));
      //dispatch<any>(GetTutorLessons(setLessonData,[user._id]));
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Students</title>
      </Helmet>

      <Box pt={2} />

      <Container maxWidth="lg">
        <h2>Students</h2>
        {studentsData && studentsData.type == 'Success' && (
          <>
            <AllStudentsTable students={studentsData.payload} />
          </>
        )}

        {studentsData?.type == 'Loading' && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}

        {studentsData?.type == 'Failed' && (
          <Box sx={{ width: '100%' }}>
            <Typography color="error">{studentsData.message} </Typography>
          </Box>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default DashboardAllStudents;
