import { Dispatch } from 'redux';
import axios from 'axios';
import {
  Dispatcher,
  FAILED,
  SUBMITTING,
  SUCCESS,
  LOADING,
  GetToken,
  Submitting,
  Default,
  Fail,
  Loading,
  Success
} from './LoadingActions';
import { Lesson, LessonSubmit } from 'src/models/lesson';
import { PaymentSubmitTypes } from './PaymentActions';
import { API_URL } from '../settings';
import { GetLocalUser } from 'src/actions/AuthActions';

//const API_URL = process.env.API_SERVER + "api/lessons/";
//const API_URL = 'http://localhost:5001/api/lessons/';
const API_URL_FULL = API_URL+'api/lessons/';

export type LessonsGetTypes = Loading | Fail | GetLessonsSuccess;
export type LessonGetTypes = Loading | Fail | GetLessonSuccess;
export type LessonSubmitTypes = Submitting | Fail | GetLessonSuccess;
export type LessonStatus = 'completed' | 'cancelled' | 'booked' | 'paid';

export interface GetLessonsSuccess {
  type: typeof SUCCESS;
  payload: Lesson[];
}

export interface GetLessonSuccess {
  type: typeof SUCCESS;
  payload: Lesson;
}

export type LessonInterface = {
  student_id: string;
  fromDate?: Date;
  toDate?: Date;
  statusesIncludes: string[];
};


//Set the status of a lesson to
export const UpdateLesson =
  (
    lesson_id : string,
    date : Date,
    duration : number,
    tutor_hourly_rate : number,
    student_hourly_rate : number,
    notes : string,
    setData: Dispatcher<LessonGetTypes>
  ) =>
  async () => {
    try {
      const token = GetToken();

      const data = {
        id: GetLocalUser()._id,
        lesson_id,
        date,
        duration,
        tutor_hourly_rate,
        student_hourly_rate,
        notes,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: LOADING
      });

      const res = await axios.post(API_URL_FULL + 'update-lesson', data, config);

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

//Set the status of a lesson to
export const UpdateLessonStatus =
  (
    lesson_id: string,
    status: LessonStatus,
    setData: Dispatcher<LessonGetTypes>
  ) =>
  async () => {
    try {
      const token = GetToken();

      const data = {
        id: GetLocalUser()._id,
        lesson_id: lesson_id,
        status: status
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: LOADING
      });

      const res = await axios.post(API_URL_FULL + 'update-lesson-status', data, config);

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

//Get all student lessons
export const GetStudentLessons =
  (data: LessonInterface, setData: Dispatcher<LessonsGetTypes>) => async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: LOADING
      });

      const res = await axios.post(API_URL_FULL + 'get-student-lessons', data, config);

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

  //Get all student lessons
export const GetAllLessons =
(startingDate: Date, daySpan: number, setData: Dispatcher<LessonsGetTypes>) => async () => {
  try {
    const token = GetToken();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const postData = {
      date : startingDate,
      day_span: daySpan
    };

    setData({
      type: LOADING
    });

    const res = await axios.post(API_URL_FULL + 'get-all-lessons', postData, config);

    setData({
      type: SUCCESS,
      payload: res.data
    });
  } catch (error) {
    setData({
      type: FAILED,
      message: 'Error'
    });
  }
};

//Get all student lessons
export const GetTutorLessons =
  (
    setData: Dispatcher<LessonsGetTypes>,
    tutor_ids?: string[],
    invoice_criteria? : string,
    status: string[] = ['completed','booked'],
  ) =>
  async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      const data = {
        tutor_ids: tutor_ids,
        invoice_criteria: invoice_criteria,
        status: status
      };

      setData({
        type: LOADING
      });

      const res = await axios.post(API_URL_FULL + 'get-tutor-lessons', data, config);

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

//Add a new lesson
export const AddLesson =
  (lessonData: LessonSubmit, setData: Dispatcher<LessonSubmitTypes>) =>
  async () => {
    try {
      const token = GetToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      setData({
        type: SUBMITTING
      });

      const res = await axios.post(API_URL_FULL + 'set-lesson', lessonData, config);

      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setData({
          type: FAILED,
          message: error.message
        });
        return;
      }
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

  //Make a lesson Payment
  export const MakeLessonPayment =
    (postData, setData: Dispatcher<PaymentSubmitTypes>) => async () => {
      try {
        const token = GetToken();
  
        const config = {
          headers: {
            Authorization: `Bearer ${token}`
          }
        };
  
        setData({
          type: SUBMITTING
        });
  
        await axios.post(API_URL_FULL + 'set-lessons-paid', postData, config);
  
        setData({
          type: SUCCESS
        });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            console.log(error.response.data);
          }
  
          setData({
            type: FAILED,
            message: error.message
          });
          return;
        }
        setData({
          type: FAILED,
          message: 'Error'
        });
      }
    };

  


  //Mark Lessons as paid
export const SetLessonsPaid =
(
  lessonIDs: string[],
  paidDate: Date,
  setData: Dispatcher<LessonSubmitTypes>
) =>
async () => {
  try {
    const token = GetToken();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    setData({
      type: SUBMITTING
    });

    const postData = {
      lessonIDs,
      paidDate
    };

    const res = await axios.put(API_URL_FULL + 'set-lessons-paid', postData, config);

    setData({
      type: SUCCESS,
      payload: res.data
    });
  } catch (error) {

    if (axios.isAxiosError(error)) {
      if (error.response) {
        console.log(error.response.data);
      }

      setData({
        type: FAILED,
        message: error.message
      });
      return;
    }
    setData({
      type: FAILED,
      message: 'Error'
    });
  }
};

  //Mark Lessons as paid
  export const SetLessonsInvoiced =
  (
    lessonIDs: string[],
    invoicedDate: Date,
    student_id: string,
    setData: Dispatcher<LessonSubmitTypes>
  ) =>
  async () => {
    try {
      const token = GetToken();
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
  
      setData({
        type: SUBMITTING
      });
  
      const postData = {
        student_id: student_id,
        date: invoicedDate,
        lessons: lessonIDs
      };
  
      const res = await axios.post(API_URL_FULL + 'set-lessons-invoiced', postData, config);
  
      setData({
        type: SUCCESS,
        payload: res.data
      });
    } catch (error) {
      console.log(error);
  
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log(error.response.data);
        }
  
        setData({
          type: FAILED,
          message: error.message
        });
        return;
      }
      setData({
        type: FAILED,
        message: 'Error'
      });
    }
  };

  //Add a new lesson
export const PayTutor =
(postData, setData: Dispatcher<PaymentSubmitTypes>) => async () => {
  try {
    const token = GetToken();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    setData({
      type: SUBMITTING
    });

    await axios.post(API_URL_FULL + 'set-lessons-tutor-paid', postData, config);

    // Get positing data
    const emailPostData = {
      to: postData.tutor.email,
      name: postData.tutor.first_name
    };

    await axios.post(API_URL+'api/send-email/payslip', emailPostData, config);

    setData({
      type: SUCCESS
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        console.log(error.response.data);
      }

      setData({
        type: FAILED,
        message: error.message
      });
      return;
    }
    setData({
      type: FAILED,
      message: 'Error'
    });
  }
};