import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

function App() {
  const content = useRoutes(router);

  const firebaseConfig = {
    apiKey: 'AIzaSyDLJaJLcO4-jcoSv6GQYXZFnABJy1m0jhY',
    authDomain: 'aptetut-website-ff7f6.firebaseapp.com',
    projectId: 'aptetut-website-ff7f6',
    storageBucket: 'aptetut-website-ff7f6.appspot.com',
    messagingSenderId: '846137600477',
    appId: '1:846137600477:web:6c9c29a9cc9a92649bee34',
    measurementId: 'G-SW17R9K1PY'
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
