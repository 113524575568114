import { GetLocalUser } from "../actions/AuthActions";

//With a given string 
export const validateEmail = (email: string) => {
    let lastAtPos = email.lastIndexOf('@');
    let lastDotPos = email.lastIndexOf('.');

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        email.indexOf('@@') == -1 &&
        lastDotPos > 2 &&
        email.length - lastDotPos > 2
      )
    ) {
        return false;
    }

    return true;
}

export function roundToNearestQuarter(number) {
  const quarter = 0.25;
  return Math.round(number / quarter) * quarter;
}

export const IsManagerOrAdmin = () : boolean =>{
  const localUser = GetLocalUser();

  if(!localUser){
    return false;
  }

  return localUser.permissions.some(element => ["manager","admin"].includes(element));
}


export const IsManager = () : boolean =>{
  const localUser = GetLocalUser();

  if(!localUser){
    return false;
  }

  return localUser.permissions.some(element => ["manager"].includes(element));
}